import { gql, useReactiveVar, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';

import { userGpsVariable } from '_apollo';

const userEligibilityQuery = gql`
  query CheckUserEligibility($gps: GpsInput!) {
    checkUserEligibility(gps: $gps) {
      depositEnabled
      playEnabled
      isVerified
      withdrawEnabled
    }
  }
`;

export function useUserEligibilityQuery () {
  const userGps = useReactiveVar(userGpsVariable);
  const { isAuthenticated } = useAuth0();
  const { data, loading } = useQuery(userEligibilityQuery, {
    skip: !isAuthenticated,
    variables: {
      gps: userGps,
    },
  });
  const playEnabled = data?.checkUserEligibility?.playEnabled;

  return { loading, playEnabled };
}
