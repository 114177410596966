import Color from 'color';

export const borderRadius = 8;

const primaryHypeGamesColor = Color('hsl(270deg 90% 55%)');
const backgroundHypeGamesColor = Color('hsl(0deg 0% 5%)');

const primaryLuckLakeColor = Color('hsl(30deg 90% 55%)');
const backgroundLuckLakeColor = Color('hsl(210deg 90% 5%)');

const primaryRadPokerColor = Color('hsl(354deg 94% 55%)');
const backgroundRadPokerColor = Color('hsl(234deg 14% 5%)');

const primarycasinoFriendsColor = Color('hsl(30deg 75% 55%)');
const backgroundcasinoFriendsColor = Color('hsl(0deg 75% 5%)');

const blue = Color('hsl(240deg, 90%, 68%)');
const green = Color('hsl(120deg, 90%, 68%)');
const gold = Color('hsl(51deg 100% 50%)');
const red = Color('hsl(0deg 90% 68%)');
const yellow = Color('hsl(45deg 100% 50%)');
const white = Color('hsl(0deg 0% 100%)');

const defaultColors = {
  blue: blue.string(),
  blueL50: blue.lightness(50).string(),
  gold: gold.string(),
  green: green.string(),
  greenL50: green.lightness(50).string(),
  red: red.string(),
  redL50: red.lightness(50).string(),
  white: white.string(),
  yellow: yellow.string(),
};

const generateColors = (primary, background) => ({
  backgroundL5: background.string(),
  backgroundL5A90: background.alpha(0.9).string(),
  backgroundL12: background.lightness(12).string(),
  backgroundL14: background.lightness(14).string(),
  backgroundL20: background.lightness(20).string(),
  backgroundL25: background.lightness(25).string(),
  backgroundL30: background.lightness(30).string(),
  backgroundL60: background.lightness(60).string(),
  backgroundL80: background.lightness(80).string(),
  primary: primary.string(),
  primaryL35: primary.lightness(35).string(),
  primaryL57: primary.lightness(57).string(),
  primaryL60: primary.lightness(60).string(),
  ...defaultColors,
});

const hypeGamesColors = generateColors(primaryHypeGamesColor, backgroundHypeGamesColor);
const luckLakeColors = generateColors(primaryLuckLakeColor, backgroundLuckLakeColor);
const radPokerColors = generateColors(primaryRadPokerColor, backgroundRadPokerColor);
const casinoFriendsColors = generateColors(primarycasinoFriendsColor, backgroundcasinoFriendsColor);

export const colors = window.activeClient.code === 'hypeGames' ? {
  ...hypeGamesColors,
} : window.activeClient.code === 'luckLake' ? {
  ...luckLakeColors,
} : window.activeClient.code === 'radPoker' ? {
  ...radPokerColors,
} : window.activeClient.code === 'casinoFriends' ? {
  ...casinoFriendsColors,
} : {};

export const boxShadow = `2px 4px 8px -2px ${colors.backgroundL5}`;

export const mainContentMaxWidth = 1200;

export const mobileBreakpoint = 1000;

export const navigationBarHeight = 65;

export const navigationMenuMobileHeight = 65;

export const roundedContainer = `
  box-shadow: 0 4px 8px -2px ${colors.backgroundL5};
  background: ${colors.backgroundL12};
  border-radius: 4px;
`;

export const transitionDuration = '0.3s';

export const fadeInAnimation = ({ duration = transitionDuration, easing = 'ease-in' } = {}) => `
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: fadeIn ${duration} ${easing};
`;

export const transformAnimation = ({ duration = transitionDuration, easing = 'ease-in', transform = 'none' } = {}) => `
  @keyframes transform {
    from {
      transform: ${transform};
    }
    to {
      transform: none;
    }
  }
  animation: transform ${duration} ${easing};
`;
