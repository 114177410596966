import React from 'react';
import QRCode from 'react-qr-code';
import styled from 'styled-components';

import { colors } from '_constants';

const Container = styled.div`
  background-color: ${colors.white};
  padding: 5px;
  height: 6vh;
`;

export const TerminalQrCode = ({ className, pairingCode }) => {
  const url = `${window.location.origin}/pairTerminalSession?pairingCode=${pairingCode}`;

  return (
    <Container className={className}>
      <QRCode
        size="100%"
        value={url}
      />
    </Container>
  );
};
