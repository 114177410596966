import { gql, useMutation } from '@apollo/client';

const claimBonus = gql`
  mutation claimBonus {
    claimBonus {
      bonusEligibility {
        id,
        isEligible,
        nextEligibleTimestamp,
      },
      wallets {
        id,
        playableBalance,
        redeemableBalance,
      },
    },
  }
`;

export function useClaimBonusMutation () {
  const [mutation] = useMutation(claimBonus);

  return (variables) => mutation({ variables });
}
