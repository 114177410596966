import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import {
  borderRadius,
  boxShadow,
  colors,
  fadeInAnimation,
  transformAnimation,
  transitionDuration,
} from '_constants';
import { useClickAway } from '_hooks';
import CloseIcon from '_images/icons/close.svg';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.backgroundL5A90};
  padding: 15px;
  ${fadeInAnimation};
`;

const ModalContainer = styled.div`
  background: ${colors.backgroundL12};
  border-radius: ${borderRadius}px;
  box-shadow: ${boxShadow};
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  max-width: 650px;
  max-height: 100%;
  ${transformAnimation({ transform: 'translateY(50px)' })};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  background: ${colors.backgroundL20};
  border-bottom: 1px solid ${colors.backgroundL12};
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  transition: ${transitionDuration};

  &:hover {
    fill: ${colors.primary};
  }
`;

const Content = styled.div`
  padding: 20px;
  overflow-y: auto;
`;

export const Modal = ({
  className,
  children,
  closeModal,
  disableHeader = false,
  isDismissible = true,
  title,
}) => {
  const ref = useRef();

  useClickAway({
    ref,
    onClickAway: (event) => {
      const modalDropdownRoot = document.getElementById('modalDropdownRoot');
      if (modalDropdownRoot.contains(event.target)) return;
      if (isDismissible) closeModal();
    },
  });

  return createPortal(
    <Container>
      <ModalContainer className={className} ref={ref}>
        {disableHeader || <Header>
          <Title>{title && title}</Title>
          {isDismissible && <StyledCloseIcon fill={colors.white} onClick={closeModal} width={15} />}
        </Header>}
        <Content>{children}</Content>
      </ModalContainer>
    </Container>,
    document.getElementById('modalRoot'),
  );
};
