import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { keyframes } from 'styled-components';

import { borderRadius, boxShadow, colors, transitionDuration } from '_constants';
import CloseIcon from '_images/icons/close.svg';

const errorMessages = {
  required: 'Required',
  minLength: 'Value is too short',
  maxLength: 'Value is too long',
  pattern: 'Invalid format',
  min: 'Value is too low',
  max: 'Value is too high',
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const InputContainer = styled.div`
  background-color: ${colors.backgroundL20};
  height: 45px;
  padding: 0 15px;
  border-radius: ${borderRadius}px;
  width: 100%;
  display: flex;
  align-items: center;
  border: ${({ error }) => (error ? `1px solid ${colors.red}` : 'none')};
  box-shadow: ${boxShadow};
`;

const InputLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const InputLabel = styled.div`
  color: ${colors.backgroundL80};
  font-size: 11px;
  font-weight: 500;
  animation: ${fadeInUp} ${transitionDuration} ease-out;
`;

const StyledInput = styled.input`
  color: ${colors.white};
  font-size: 14px;
  font-weight: 500;
  flex: 1;
  width: 100%;

  &::placeholder {
    color: ${colors.backgroundL80};
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: ${colors.white};
    -webkit-box-shadow: 0 0 0px 1000px ${colors.backgroundL20} inset;
  }

  &::-webkit-inner-spin-button, 
  &::-webkit-outer-spin-button { 
    appearance: none;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  fill: ${colors.backgroundL80};
  width: 10px;
  cursor: pointer;
  transition: ${transitionDuration};

  &:hover {
    fill: ${colors.primary};
  }
`;

const ErrorMessage = styled.span`
  color: ${colors.red};
  font-size: 11px;
`;

export const FormInput = ({ name, placeholder, rules, disabled, style, onChange, ...rest }) => {
  const { register, watch, setValue, formState: { errors } } = useFormContext();
  const fieldValue = watch(name);

  const { onChange: registerOnChange, ...registerProps } = register(name, rules);

  return (
    <Container style={style}>
      <InputContainer error={errors[name]}>
        <InputLabelContainer>
          {!!fieldValue && <InputLabel>{placeholder}</InputLabel>}
          <StyledInput
            placeholder={placeholder}
            disabled={disabled}
            {...registerProps}
            {...rest}
            onChange={(e) => {
              registerOnChange(e); // onChange from register
              if (onChange) onChange(e); // onchange from props
            }}
          />
        </InputLabelContainer>
        {!!fieldValue && !disabled && <StyledCloseIcon onClick={() => setValue(name, '')} />}
      </InputContainer>
      {errors[name] && <ErrorMessage>{errors[name]?.message || errorMessages[errors[name]?.type] || 'Invalid'}</ErrorMessage>}
    </Container>
  );
};
