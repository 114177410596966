import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Button, FormDropdown, FormInput, Modal, LoadingSpinner } from '_components';
import { colors, countryOptions } from '_constants';
import { displayAlert, formatCurrency } from '_utilities';

import { useRedemptionModalQuery } from './useRedemptionModalQuery';
import { useCreatePaymentSessionMutation } from '../purchaseModal/useCreatePaymentSessionMutation';
import { useFinalizePaymentSessionMutation } from '../purchaseModal/useFinalizePaymentSessionMutation';
import { useUpdateSavedPaymentMethods } from '../purchaseModal/useUpdateSavedPaymentMethods';

const redeemStates = Object.freeze({
  failed: 'The redeem couldn\'t be processed. Please try again later.',
  pending: 'The redeem is being processed.',
  success: 'Successful redeem.',
});

const createPaymentSessionErrorMessages = Object.freeze({
  401: 'Account verification needed.',
  404: 'Redeem unavailable.',
  403: 'Redeem unavailable.',
});

const finalizePaymentErrorMessages = Object.freeze({
  404: 'Redeem unavailable.',
  422: 'Insufficient funds',
  500: redeemStates['failed'],
});

const RedeemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const BalanceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BalanceText = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const BalanceAmount = styled.div`
  font-size: 22px;
  font-weight: 700;
`;

const MaxRedeemText = styled(BalanceText)``;

const MaxRedeemAmount = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${colors.primary};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Title = styled.div`
  color: ${colors.blackL80};
  font-size: 14px;
  font-weight: 600;
`;

const InputRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
`;

export const RedemptionModal = ({
  setShowRedemptionModal,
}) => {
  const { isAuthenticated } = useAuth0();
  const form = useForm();
  const [createPaymentSession, {
    error: createPaymentSessionError,
    loading: createPaymentSessionLoading,
    sessionId,
    transactionId,
  }] = useCreatePaymentSessionMutation({ isDeposit: false });
  const [finalizePaymentSession, { loading: finalizePaymentLoading, error: finalizePaymentError }] = useFinalizePaymentSessionMutation({ isDeposit: false });
  const [updateSavedPaymentMethods, { loading: updateSavedPaymentMethodsLoading }] = useUpdateSavedPaymentMethods({ handlePaymentMethodError });
  const { wallets } = useRedemptionModalQuery();

  const isLoading = finalizePaymentLoading || updateSavedPaymentMethodsLoading || createPaymentSessionLoading;

  const sweepsCoinWallet = useMemo(() => {
    return wallets.find(({ currency }) => currency.code === 'sweepsCoins');
  }, [wallets]);

  useEffect(() => {
    if (!isAuthenticated) return;
    createPaymentSession();
  }, [createPaymentSession, isAuthenticated]);

  useEffect(() => {
    if (createPaymentSessionError?.code) {
      displayAlert({ message: createPaymentSessionErrorMessages[createPaymentSessionError?.code] || createPaymentSessionErrorMessages['404'], type: 'error' });
      setShowRedemptionModal(false);
    }
  }, [createPaymentSessionError, setShowRedemptionModal]);

  useEffect(() => {
    if (finalizePaymentError?.code) {
      displayAlert({ message: finalizePaymentErrorMessages[finalizePaymentError?.code] || finalizePaymentErrorMessages['404'], type: 'error' });
      setShowRedemptionModal(false);
    }
  }, [finalizePaymentError, setShowRedemptionModal]);

  const handleSubmit = async (formData) => {
    if (!sessionId || !transactionId) return;
    const amountInCents = Math.round(Number(formData.amount) * 100);

    // validating balance
    if (sweepsCoinWallet?.redeemableBalance < amountInCents) {
      displayAlert({ message: finalizePaymentErrorMessages['422'], type: 'error' });
      form.setError('amount', {
        type: 'manual',
        message: finalizePaymentErrorMessages['422'],
      });
      return;
    }
    const { token, type } = await updateSavedPaymentMethods({
      formData,
      isRemoving: false,
      sessionId,
      type: 'ACH',
    });

    if (!token || !type) return;

    displayAlert({ message: 'Redeem processing...', type: 'success' });

    const response = await finalizePaymentSession({
      amount: Number(formData.amount),
      isDeposit: false,
      sessionId,
      token,
      transactionId,
      type,
    });

    const { data: { finalizePaymentSession: { paymentState } } } = response;

    if (!paymentState) return;

    displayAlert({ message: redeemStates[paymentState], type: paymentState === 'pending' ? 'warning' : paymentState });

    setShowRedemptionModal(false);
  };

  function handlePaymentMethodError () {
    displayAlert({ message: 'Payment method error.', type: 'error' });
    createPaymentSession();
  }

  const handleChangeAmount = (event) => {
    const value = event.target.value;
    // Validating that the amount doesn't have more than 2 digits
    if (value && /^\d+\.\d{3,}$/.test(value)) {
      form.setValue('amount', Number(value).toFixed(2));
    }
  };

  return (
    <Modal
      closeModal={() => setShowRedemptionModal(false)}
      title="Redeem"
    >
      <RedeemContainer>
        <div>
          <BalanceContainer>
            <BalanceText>Current Balance</BalanceText>
            <BalanceAmount>{sweepsCoinWallet && `${formatCurrency(sweepsCoinWallet?.playableBalance)} ${sweepsCoinWallet?.currency?.shortName}`}</BalanceAmount>
          </BalanceContainer>
          <BalanceContainer>
            <MaxRedeemText>Redeemable Balance</MaxRedeemText>
            <MaxRedeemAmount>{sweepsCoinWallet && `${formatCurrency(sweepsCoinWallet?.redeemableBalance)} ${sweepsCoinWallet?.currency?.shortName}`}</MaxRedeemAmount>
          </BalanceContainer>
        </div>
        <FormProvider {...form}>
          <Form onSubmit={form.handleSubmit(handleSubmit)}>
            <Title>Bank Account Information</Title>
            <FormInput type="number" name="routingNumber" placeholder="Routing Number*" rules={{ required: true }} />
            <FormInput type="number" name="accountNumber" placeholder="Account Number*" rules={{ required: true }} />
            <FormInput name="nameOnAccount" placeholder="Account Holder Name*" rules={{ required: true }} />
            <FormDropdown isModalDropdown name="country" placeholder="Country*" rules={{ required: true }} options={countryOptions} />
            <FormInput name="addressLine1" placeholder="Address Line One*" rules={{ required: true }} />
            <FormInput name="state" placeholder="State*" rules={{ required: true }} />
            <InputRow>
              <FormInput name="city" placeholder="City*" rules={{ required: true }} />
              <FormInput name="zipCode" placeholder="Zip Code*" rules={{ required: true }} />
            </InputRow>
            <FormInput type="number" name="amount" onChange={handleChangeAmount} placeholder={`Amount ${sweepsCoinWallet && `(${sweepsCoinWallet?.currency?.shortName})`}*`} step="0.01" rules={{ required: true, validate: (value) => !isNaN(value) && value > 0 }} />
            {/* <Checkbox title="Save this bank account" {...register('saveAccount')} />
                  <input type="checkbox" {...register('saveAccount')}></input> */}
            <Button
              type="submit"
              disabled={ isLoading }>
              {isLoading ? <LoadingSpinner size={20}/> : 'Request Redeem'}
            </Button>
          </Form>
        </FormProvider>
      </RedeemContainer>
    </Modal>
  );
};
