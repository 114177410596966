import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const removeSavedPaymentMethod = gql`
  mutation removeSavedPaymentMethod ($sessionId: String, $token: String) {
    removeSavedPaymentMethod (sessionId: $sessionId, token: $token) {
      error {
        code,
        message,
      },
    },
  }
`;

export function useRemoveSavedPaymentMethodMutation () {
  const [mutation, { data, loading, error }] = useMutation(removeSavedPaymentMethod);

  return [
    useCallback((variables) => mutation({ variables }), [mutation]),
    { ...data?.removeSavedPaymentMethod || {}, loading, error },
  ];
}
