import { gql, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';

const navigationBarQuery = gql`
  query navigationBarQuery {
    wallets {
      id,
      playableBalance,
      currency {
        code,
        id,
        name,
        shortName,
      },
    },
  }
`;

export function useNavigationBarQuery () {
  const { isAuthenticated } = useAuth0();
  const { data, loading } = useQuery(navigationBarQuery, {
    skip: !isAuthenticated,
  });

  const wallets = data?.wallets || [];

  return {
    loading,
    wallets,
  };
}
