import { useEffect, useRef, useState } from 'react';

export function useBorderAnimations ({ activeItem }) {
  const [barStyle, setBarStyle] = useState({ left: 0, width: 0 });
  const containerRef = useRef(null);
  const itemRefs = useRef({});

  useEffect(() => {
    if (!containerRef.current || !activeItem) return;

    const containerRect = containerRef.current.getBoundingClientRect();
    const activeElement = itemRefs.current[activeItem];
    if (!activeElement) return;

    const itemRect = activeElement.getBoundingClientRect();
    const left = itemRect.left - containerRect.left;
    const width = itemRect.width;

    setBarStyle({ left, width });
  }, [activeItem]);

  return {
    barStyle,
    containerRef,
    itemRefs,
  };
}
