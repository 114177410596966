export function capitalizeFirstLetter (str) {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export const formatCurrency = (valueInCents) => {
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(valueInCents / 100);
};

// TODO: remove this
export const formatAmount = (valueInDollars) => {
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: 'USD' }).format(valueInDollars);
};

export function toCamelCase (str) {
  return str
    .split(/\s+/g)
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('');
}

export const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text.endsWith('.') ? text : text + '.';
  }

  const truncated = text.slice(0, maxLength).trim();
  const lastSpaceIndex = truncated.lastIndexOf(' ');
  const finalText = lastSpaceIndex > 0 ? truncated.slice(0, lastSpaceIndex) : truncated;

  return finalText.endsWith('.') ? finalText : finalText + '.';
};

export function splitCamelCaseAndCapitalize (str) {
  return str
    .replace(/([A-Z])/g, ' $1')
    .trim()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
