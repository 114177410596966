import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';

import { activeCurrencyVariable } from '_apollo';

const createVivoSession = gql`
  mutation createVivoSession($activeCurrency: String!, $gameId: Int) {
    createVivoGamingSession(activeCurrency: $activeCurrency, gameId: $gameId) {
      hasBalance,
      iFrameUrl,
    }
  }
`;

export function useCreateVivoSession ({ gameId, isVivoGame }) {
  const activeCurrency = useReactiveVar(activeCurrencyVariable);
  const [createVivoSessionMutation, { data }] = useMutation(createVivoSession, {
    variables: {
      activeCurrency,
      gameId,
    },
  });

  useEffect(() => {
    if (activeCurrency && gameId && isVivoGame) {
      createVivoSessionMutation({ variables: { activeCurrency } });
    }
  }, [activeCurrency, gameId, isVivoGame, createVivoSessionMutation]);

  return {
    vivoGamingUrl: data?.createVivoGamingSession?.iFrameUrl,
  };
}
