import React from 'react';
import styled from 'styled-components';

import { showPurchaseModal } from '_apollo';
import { borderRadius, colors } from '_constants';
import { formatCurrency } from '_utilities';

import { Button } from '../../../button';
import { Modal } from '../../../modal';

const StyledModal = styled(Modal)`
  max-width: 400px;
`;

const Container = styled.div`
  border-radius: ${borderRadius}px;
  background-color: ${colors.backgroundL20};
  padding: 15px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.backgroundL80};
  padding-bottom: 3px;
  margin-bottom: 20px;
`;

const HeaderText = styled.div`
  color: ${colors.backgroundL80};
  font-size: 14px;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;

  & + & {
    margin-top: 20px;
  }
`;

const CurrencyLabelGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const GoldCoinsIcon = styled.div`
  height: 25px;
  width: 25px;
  background: ${colors.yellow};
  border-radius: 100%;
  font-weight: 500;
  font-size: 16px;
  color: ${colors.backgroundL5};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SweepsCoinsIcon = styled.div`
  height: 25px;
  width: 25px;
  background: ${colors.green};
  border-radius: 100%;
  font-weight: 500;
  font-size: 16px;
  color: ${colors.backgroundL5};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CurrencyLabelTextColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const CurrencyShortName = styled.div`
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
`;

const CurrencyName = styled.div`
  font-size: 12px;
  color: ${colors.backgroundL80};
`;

const Value = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 35px;
`;

const StyledButton = styled(Button)`
  flex: 1;
`;

export const Wallet = ({
  closeModal,
  setShowRedemptionModal,
  wallets,
}) => {
  const onPurchaseClick = () => {
    showPurchaseModal({ isPurchaseModalVisible: true, amount: 10000 });
    closeModal();
  };

  const onRedeemClick = () => {
    setShowRedemptionModal(true);
    closeModal();
  };

  return (
    <StyledModal closeModal={closeModal} title="Wallet">
      <Container>
        <Header>
          <HeaderText>Your currencies</HeaderText>
          <HeaderText>Value</HeaderText>
        </Header>
        {wallets.map((wallet) => (
          <Item key={wallet.currency.code}>
            <CurrencyLabelGroup>
              {wallet.currency.code === 'goldCoins' ? (
                <GoldCoinsIcon>{wallet.currency.shortName[0]}</GoldCoinsIcon>
              ) : (
                <SweepsCoinsIcon>{wallet.currency.shortName[0]}</SweepsCoinsIcon>
              )}
              <CurrencyLabelTextColumn>
                <CurrencyShortName>{wallet.currency.shortName}</CurrencyShortName>
                <CurrencyName>{wallet.currency.name}</CurrencyName>
              </CurrencyLabelTextColumn>
            </CurrencyLabelGroup>
            <Value>{formatCurrency(wallet.playableBalance)}</Value>
          </Item>
        ))}
      </Container>
      <Buttons>
        <StyledButton onClick={onPurchaseClick}>Buy Coins</StyledButton>
        <StyledButton onClick={onRedeemClick}>Redeem</StyledButton>
      </Buttons>
    </StyledModal>
  );
};
