import { gql, useQuery } from '@apollo/client';

const popularGamesQuery = gql`
  query popularGamesQuery {
    gamesByCategory {
      name,
      games {
        externalId,
        id,
        imageUrl,
        name,
        provider {
          id,
          name,
        },
      },
    },
  }
`;

export function usePopularGamesQuery () {
  const { data } = useQuery(popularGamesQuery);

  return {
    gamesByCategory: data?.gamesByCategory || [],
  };
}
