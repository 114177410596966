import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, LoadingSpinner, PopularGames } from '_components';
import {
  boxShadow,
  colors,
  mobileBreakpoint,
} from '_constants';
import { useUserEligibilityQuery } from '_hooks';
import FullscreenIcon from '_images/icons/fullscreen.svg';
import { displayAlert } from '_utilities';

import { useGameQuery } from './useGameQuery';
import { useLaunchGame } from './useLaunchGame';

const Container = styled.div`
  @media (max-width: ${mobileBreakpoint}px) {
    padding: 5px;
  }
`;

const LoadingContainer = styled.div`
  display: grid;
  place-content: center;
  height: 65dvh;
`;

const IframeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${mobileBreakpoint}px) {
    height: 100%;
  }
`;

const StyledIframe = styled.iframe`
  height: 65dvh;
  width: 100%;
  background-color: ${colors.backgroundL5};

  ${({ isManualFullscreen }) => isManualFullscreen && `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100dvh;
    z-index: 1;
  `}
`;

const FullscreenButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: ${colors.backgroundL30};
  box-shadow: ${boxShadow};
  margin: 10px 0 25px;
`;

export const Game = () => {
  const navigate = useNavigate();
  const iframeRef = useRef(null);
  const { loading: isEligibilityLoading, playEnabled } = useUserEligibilityQuery();

  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [isManualFullscreen, setIsManualFullscreen] = useState(false);
  const { providerName, externalId, gameId } = useParams();
  const { refetch } = useGameQuery();
  const [launchGame, {
    iFrameUrl,
    error: launchGameError,
  }] = useLaunchGame({
    externalId: externalId,
    gameId: Number(gameId),
    language: 'en-GB',
    providerName, // TODO: Remove hardcoded routes. Code column on providers?
    returnUrl: window.location.origin,
  });

  // update wallet on game exit
  useEffect(() => {
    return refetch;
  }, [refetch]);

  useEffect(() => {
    launchGame();
  }, [launchGame]);

  useEffect(() => {
    if (launchGameError?.code) {
      displayAlert({ message: launchGameError.message, type: 'error' });
      navigate('/');
    }
  }, [launchGameError, navigate]);

  // TODO: BE REFACTORED TO STAKE.US
  const onFullScreen = () => {
    if (iframeRef.current.requestFullscreen) {
      iframeRef.current.requestFullscreen();
    } else if (iframeRef.current.mozRequestFullScreen) {
      iframeRef.current.mozRequestFullScreen();
    } else if (iframeRef.current.webkitRequestFullscreen) {
      iframeRef.current.webkitRequestFullscreen();
    } else if (iframeRef.current.msRequestFullscreen) {
      iframeRef.current.msRequestFullscreen();
    } else {
      window.history.pushState({ fullscreen: true }, '');
      setIsManualFullscreen(true);
    }
  };

  useEffect(() => {
    if (playEnabled === false){
      displayAlert({ message:'Games are currently unavailable for your account.' });
      navigate('/');
    }
  }, [playEnabled, navigate]);

  useEffect(() => {
    if (!isManualFullscreen) return;
    const handlePopState = () => setIsManualFullscreen(false);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isManualFullscreen]);

  return (
    <Container>
      {isEligibilityLoading
        ? <LoadingContainer><LoadingSpinner /></LoadingContainer>
        : <IframeContainer>
          <StyledIframe
            allowFullScreen
            isManualFullscreen={isManualFullscreen}
            onLoad={() => setIsIframeLoaded(true)}
            ref={iframeRef}
            src={iFrameUrl}
            title="game"
          />
          {isIframeLoaded && <FullscreenButton onClick={onFullScreen}>
            <FullscreenIcon fill={colors.white} width={15} />
            Fullscreen
          </FullscreenButton>}
        </IframeContainer>}
      <PopularGames />
    </Container>
  );
};
