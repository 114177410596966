import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Button, LoadingSpinner } from '_components';
import { borderRadius, boxShadow, colors, mobileBreakpoint } from '_constants';
import CheckCircle from '_images/icons/checkCircle.svg';
import LogoutIcon from '_images/icons/logout.svg';

import { ProfileGameplay, ProfileBalance, ProfileHistory, CoinPackages } from './components';
import { useProfileQuery } from './useProfileQuery';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (max-width: ${mobileBreakpoint}px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 25px;
  margin-bottom: 25px;

  @media (max-width: ${mobileBreakpoint}px) {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 15px;

  @media (max-width: ${mobileBreakpoint}px) {
    width: 100%;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: ${boxShadow};
  border-radius: ${borderRadius}px;
  padding: 15px;
  background-color: ${colors.backgroundL14};
`;

const NameCard = styled(Card)`
  @media (max-width: ${mobileBreakpoint}px) {
    align-items: center;
    width: 100%;
  }
`;

const Name = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 24px;
  font-weight: 700;
`;

const Email = styled.div`
  font-size: 14px;
  margin-top: 10px;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;

  @media (max-width: ${mobileBreakpoint}px) {
    width: 100%;
  }
`;

const Tabs = styled.div`
  display: flex;
  gap: 25px;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 15px;

  @media (max-width: ${mobileBreakpoint}px) {
    justify-content: space-around;
  }
`;

const Tab = styled.div`
  display: flex;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  height: 100%;
  user-select: none;
  padding-bottom: 5px;
  transition: color 0.3s;
  font-size: 14px;
  font-weight: 600;
  
  ${({ isActive }) => isActive && `
    color: ${colors.primary};
    border-bottom: 2px solid ${colors.primary};
  `}

  &:hover {
    color: ${colors.primary};
  }
`;

const tabs = [
  { name: 'Balance', value: 'balance' },
  { name: 'History', value: 'history' },
  { name: 'My Gameplay', value: 'myGamePlay' },
];

export const Profile = () => {
  const [selectedTab, setSelectedTab] = useState({ name: 'Balance', value: 'balance' });
  const { logout, user = {} } = useAuth0();
  const { name } = user;
  const { userEmail } = useProfileQuery();

  const handleTabClick = (tab) => () => setSelectedTab(tab);

  return (
    <Container>
      <Header>
        <LeftColumn>
          <NameCard>
            <Name>
              {name && name}
              <CheckCircle fill={colors.green} height={15} />
            </Name>
            <Email>{userEmail || <LoadingSpinner size={14}/>}</Email>
          </NameCard>
          <Button onClick={logout} secondary><LogoutIcon fill={colors.white} width={15} />Logout</Button>
        </LeftColumn>
        <RightColumn>
          <Tabs>
            {tabs.map((tab) => (
              <Tab key={tab.value} isActive={selectedTab.value === tab.value} onClick={handleTabClick(tab)}>{tab.name}</Tab>
            ))}
          </Tabs>
          {selectedTab.value === 'balance' && <ProfileBalance />}
          {selectedTab.value === 'history' && <ProfileHistory />}
          {selectedTab.value === 'myGamePlay' && <ProfileGameplay />}
        </RightColumn>
      </Header>
      <CoinPackages />
    </Container>
  );
};
