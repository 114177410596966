import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import styled from 'styled-components';

import { Button, FormInput, Modal, FormDropdown } from '_components';
import { colors, countryData, countryOptions, monthOptions } from '_constants';
import { useVerifyUserMutation } from '_hooks';
import { displayAlert } from '_utilities';

const Explanation = styled.div`
  margin-bottom: 20px;
`;

const Form = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

const Section = styled.div``;

const SectionTitle = styled.div`
  font-size: 14px;
  line-height: 1;
  margin-bottom: 5px;
  font-weight: 500;
  color: ${colors.backgroundL80};
`;

const SectionInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SubmitButton = styled(Button)`
  color: black;
  font-weight: 600;
  font-size: 14px;
  background-color: ${({ disabled }) => disabled ? colors.backgroundL60 : colors.primary};
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const FooterNote = styled.div`
  color: ${colors.white};
  font-size: 12px;
  font-weight: 500;
  align-self: center;
`;

const ValidatingBackground = styled.div`
  position: absolute;
  background-color: rgba(0,0,0,0.35);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LongRegistrationFormModal = ({ setShowLongRegistrationFormModal, shortRegistrationFormData }) => {
  const { logout } = useAuth0();
  const [verifyUser, { loading }] = useVerifyUserMutation();
  const form = useForm();
  const [defaultMonthSelected, setDefaultMonthSelected] = useState({});

  useEffect(() => {
    if (!shortRegistrationFormData) return;

    const monthSelected = monthOptions.find((month) => month.value === shortRegistrationFormData?.month);
    setDefaultMonthSelected(monthSelected);

    const currentValues = form.watch();
    form.reset({ ...currentValues, ...shortRegistrationFormData });
  }, [form, shortRegistrationFormData]);

  const onSubmit = async (data) => {
    const body = {
      dob: `${data.month}/${data.day}/${data.year}`,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      citizenship: data.citizenship,
      phone: data.phone,
      address: {
        city: data.city,
        country: data.country,
        postCode: data.zipCode,
        state: data.state,
        street: data.address,
      },
    };

    const response = await verifyUser({ input: body });

    // setIsUserGambleIdVerified(true)
    if (response.data.verifyUser.isVerified) {
      setShowLongRegistrationFormModal(false);
      displayAlert({ message: 'Account verified!' });
    } else {
      setShowLongRegistrationFormModal(false);
      displayAlert({ message: 'Verification failed.', type: 'error' });
    }
  };

  const closeModal = () => {
    logout();
    setShowLongRegistrationFormModal(false);
  };

  const handlePhoneFocus = (event) => {
    const value = event.target.value;
    const countryValue = form.watch('country');

    if (value === '' && countryValue) {
      const country = countryData.find((country) => country.code === countryValue);
      if (country) {
        form.setValue('phone', country.mobileCode);
      }
    } else if (!value.startsWith('+')) {
      form.setValue('phone', '+');
    }
  };

  return (
    <Modal
      closeModal={closeModal}
      title="Account Verification"
    >
      <Explanation>We couldn't verify you. Let's try again.</Explanation>
      <FormProvider {...form}>
        {loading && <ValidatingBackground>Validating...</ValidatingBackground>}
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <Inputs>
            <InputGroup>
              <FormInput disabled={loading} name="email" placeholder="Email*" rules={{ required: true }} />
            </InputGroup>
            <InputGroup>
              <FormInput disabled={loading} name="firstName" placeholder="First Name*" rules={{ required: true, maxLength: 25 }} />
              <FormInput disabled={loading} name="lastName" placeholder="Last Name*" rules={{ required: true, maxLength: 25 }} />
            </InputGroup>
            <Section>
              <SectionTitle>Date Of Birth</SectionTitle>
              <InputGroup>
                <FormDropdown defaultOption={defaultMonthSelected} disabled={loading} isModalDropdown name="month" options={monthOptions} placeholder="Month*" rules={{ required: true }} />
                <FormInput disabled={loading} name="day" placeholder="Day*" rules={{ required: true, min: 1, max: 31 }} type="number" />
                <FormInput disabled={loading} name="year" placeholder="Year*" rules={{ required: true }} type="number" />
              </InputGroup>
            </Section>
            <Section>
              <SectionTitle>Additional Information</SectionTitle>
              <SectionInputs>
                <InputGroup>
                  <FormDropdown
                    defaultOption={countryOptions[0]}
                    disabled={loading}
                    isModalDropdown
                    name="country"
                    options={countryOptions}
                    placeholder="Country*"
                    rules={{ required: true }}
                  />
                  <FormDropdown
                    defaultOption={countryOptions[0]}
                    disabled={loading}
                    isModalDropdown
                    name="citizenship"
                    options={countryOptions}
                    placeholder="Citizenship*"
                    rules={{ required: true }}
                  />
                </InputGroup>
                <FormInput disabled={loading} name="address" placeholder="Address*" rules={{ required: true }} />
                <InputGroup>
                  <FormInput disabled={loading} name="city" placeholder="City*" rules={{ required: true }} />
                  <FormInput disabled={loading} name="state" placeholder="State*" rules={{ required: true }} />
                  <FormInput disabled={loading} name="zipCode" placeholder="Zip Code*" rules={{ required: true }} />
                </InputGroup>
                <FormInput name="phone" type="tel" placeholder="Phone Number*"
                  disabled={loading}
                  onFocus={handlePhoneFocus}
                  rules={{ required: true, pattern: { value: /^\+?[1-9]\d{1,14}$/, message: 'Invalid phone number format' } }} // E.164 format
                />
              </SectionInputs>
            </Section>
          </Inputs>
          <Footer>
            <SubmitButton disabled={loading} type="submit" >{loading ? 'Verifying...' : 'Complete'}</SubmitButton>
            <FooterNote>We use this information to confirm that you meet the age requirement.</FooterNote>
          </Footer>
        </Form>
      </FormProvider>
    </Modal>
  );
};
