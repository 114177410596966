import { useReactiveVar } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { activeCurrencyVariable } from '_apollo';
import { Button, ClientLogo, Dropdown, LoadingSpinner } from '_components';
import { borderRadius, boxShadow, colors, mainContentMaxWidth, mobileBreakpoint, navigationBarHeight } from '_constants';
import { useAuthentication } from '_hooks';
import { formatCurrency } from '_utilities';

import { Wallet } from '../wallet';
import { useNavigationBarQuery } from './useNavigationBarQuery';

const Container = styled.div`
  box-shadow: ${boxShadow};
  background-color: ${colors.backgroundL14};
  height: ${navigationBarHeight}px;
  width: 100%;
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${mainContentMaxWidth}px;
  padding: 0 25px;

  @media (max-width: ${mobileBreakpoint}px) {
    padding: 0 15px;
  }
`;

const Logo = styled(Link)`
  align-items: center;
  display: flex;
  gap: 8px;
`;

const StyledClientLogo = styled(ClientLogo)`
  height: 100%;
  width: 100%;
  max-width: 150px;
  max-height: 35px;
`;

const CurrencySelector = styled.div`
  display: flex;
  box-shadow: ${boxShadow};
`;

const LoadingSpinnerContainer = styled.div`
  border-radius: ${borderRadius}px 0px 0px ${borderRadius}px;
  min-width: 100px;
  display: grid;
  place-content: center;
  background-color: ${colors.backgroundL20};
`;

const CurrencyDropdown = styled(Dropdown)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: unset;
  background-color: ${colors.backgroundL20};
`;

const WalletButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: unset;
`;

const CurrencyOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  min-width: 100px;
  width: 100%;
`;

const CurrencyIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const GoldCoinsIcon = styled.div`
  height: 15px;
  width: 15px;
  background: ${colors.yellow};
  border-radius: 100%;
  font-weight: 500;
  font-size: 10px;
  color: ${colors.backgroundL5};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SweepsCoinsIcon = styled.div`
  height: 15px;
  width: 15px;
  background: ${colors.green};
  border-radius: 100%;
  font-weight: 500;
  font-size: 10px;
  color: ${colors.backgroundL5};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectedCurrencyOption = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  pointer-events: none;
`;

export const NavigationBar = ({ setShowRedemptionModal }) => {
  const [isOpenWalletModal, setIsOpenWalletModal] = useState(false);
  const activeCurrency = useReactiveVar(activeCurrencyVariable);
  const { loginWithPopup, logout } = useAuth0();
  const { isAuthenticated } = useAuthentication();
  const location = useLocation();
  const { wallets, loading } = useNavigationBarQuery();
  const isGamePage = ['/game', '/poker', '/sportsbook'].includes(location.pathname);

  // Build dropdown options for selecting active currency
  const currencyDropdownOptions = wallets.map((wallet) => ({
    label: `${wallet.playableBalance}${wallet.currency.shortName}`,
    optionRender: () => (
      <CurrencyOption>
        {formatCurrency(wallet.playableBalance)}
        <CurrencyIcon>
          {wallet.currency.code === 'goldCoins' ? (
            <GoldCoinsIcon>{wallet.currency.shortName[0]}</GoldCoinsIcon>
          ) : (
            <SweepsCoinsIcon>{wallet.currency.shortName[0]}</SweepsCoinsIcon>
          )}
          {wallet.currency.shortName}
        </CurrencyIcon>
      </CurrencyOption>
    ),
    selectedOptionRender: () => (
      <SelectedCurrencyOption>
        {isGamePage ? '(In Play)' : formatCurrency(wallet.playableBalance)}
        {wallet.currency.code === 'goldCoins' ? (
          <GoldCoinsIcon>{wallet.currency.shortName[0]}</GoldCoinsIcon>
        ) : (
          <SweepsCoinsIcon>{wallet.currency.shortName[0]}</SweepsCoinsIcon>
        )}
      </SelectedCurrencyOption>
    ),
    value: wallet.currency.code,
  }));

  const handleLogin = async () => {
    try {
      await loginWithPopup({
        authorizationParams: {
          /* eslint-disable-next-line camelcase */
          redirect_uri: window.activeClient.auth0RedirectUri,
        },
      });
    } catch (error) {
      console.error('Login error', error);
      logout();
    }
  };

  const handleCurrencyChange = (selectedCurrencyOption) => {
    activeCurrencyVariable(selectedCurrencyOption.value);
  };

  return (
    <Container>
      <Content>
        <Logo to="/">
          <StyledClientLogo />
        </Logo>

        {isAuthenticated ? (
          <CurrencySelector>
            {loading ?
              <LoadingSpinnerContainer><LoadingSpinner size={18} /></LoadingSpinnerContainer>
              :
              <CurrencyDropdown
                onChange={handleCurrencyChange}
                options={currencyDropdownOptions}
                selectedOption={
                  currencyDropdownOptions.find(({ value }) => value === activeCurrency) || {}
                }
              />}
            <WalletButton onClick={() => setIsOpenWalletModal(true)}>Wallet</WalletButton>
          </CurrencySelector>
        ) : (
          <Button onClick={handleLogin}>Sign In</Button>
        )}

        {isOpenWalletModal && <Wallet
          closeModal={() => setIsOpenWalletModal(false)}
          wallets={wallets}
          setShowRedemptionModal={setShowRedemptionModal}
        />}
      </Content>
    </Container>
  );
};
