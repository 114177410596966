import React, { useMemo } from 'react';
import styled from 'styled-components';

import { showPurchaseModal } from '_apollo';
import { IconTitle } from '_components';
import { borderRadius, boxShadow, colors, transitionDuration, coinPackages } from '_constants';
import Coins10 from '_images/coins10.svg';
import Coins100 from '_images/coins100.svg';
import Coins20 from '_images/coins20.svg';
import Coins200 from '_images/coins200.svg';
import Coins300 from '_images/coins300.svg';
import Coins50 from '_images/coins50.svg';
import BoxIcon from '_images/icons/box.svg';

import { useCoinPacksQuery } from './useCoinPacksQuery';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const CoinPacks = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
`;

const CoinPack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: ${boxShadow};
  border-radius: ${borderRadius}px;
  background-color: ${colors.backgroundL14};
  cursor: pointer;
  align-items: stretch;
  overflow: hidden;
  transition: background-color ${transitionDuration};

  &:hover{
    background-color: ${colors.backgroundL20};
  }
`;

const CoinPackImage = styled.div`
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
`;

const CoinPackContent = styled.div`
  padding: 0 15px 15px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

const AmountTitle = styled(Title)` 
  margin-top: 10px;
`;

const Subtitle = styled.div`
  font-weight: 300;
  font-size: 12px;
`;

const GoldCoinsIcon = styled.div`
  height: 15px;
  width: 15px;
  background: ${colors.yellow};
  border-radius: 100%;
  font-weight: 500;
  font-size: 10px;
  color: ${colors.backgroundL5};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SweepsCoinsIcon = styled.div`
  height: 15px;
  width: 15px;
  background: ${colors.green};
  border-radius: 100%;
  font-weight: 500;
  font-size: 10px;
  color: ${colors.backgroundL5};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const coinImages = {
  10000: Coins10,
  25000: Coins20,
  50000: Coins50,
  100000: Coins100,
  200000: Coins200,
  300000: Coins300,
};

export const CoinPackages = () => {
  const { wallets, loading } = useCoinPacksQuery();

  const goldCoinsWallet = useMemo(() => {
    return wallets.find(({ currency }) => currency.code === 'goldCoins');
  }, [wallets]);

  const sweepsCoinsWallet = useMemo(() => {
    return wallets.find(({ currency }) => currency.code === 'sweepsCoins');
  }, [wallets]);

  return (
    <Container>
      <IconTitle title="Coin Packs" icon={<BoxIcon />} />
      <CoinPacks>
        {coinPackages.map(({ amount, title, bonus, label }) => {
          const CoinImage = coinImages[amount];
          return <CoinPack key={title} onClick={() => showPurchaseModal({ amount, isPurchaseModalVisible: true })}>
            <CoinPackImage >
              <CoinImage height="100%" />
            </CoinPackImage>
            <CoinPackContent>
              <AmountContainer>
                <GoldCoinsIcon>{loading ? '' : goldCoinsWallet?.currency.shortName[0]}</GoldCoinsIcon>
                <Title>{`${title} ${loading ? '' : goldCoinsWallet?.currency.name}`}</Title>
              </AmountContainer>
              <AmountContainer>
                <SweepsCoinsIcon>{loading ? '' : sweepsCoinsWallet?.currency.shortName[0]}</SweepsCoinsIcon>
                <Subtitle>
                  {`${bonus} ${loading ? '' : sweepsCoinsWallet?.currency.name}`}
                </Subtitle>
              </AmountContainer>
              <AmountTitle>{label}</AmountTitle>
            </CoinPackContent>
          </CoinPack>;
        })}
      </CoinPacks>
    </Container>
  );
};