import React, { useState } from 'react';
import styled from 'styled-components';

import { LoadingSpinner } from '_components';
import { borderRadius, boxShadow, colors } from '_constants';
import ChevronDownIcon from '_images/icons/chevronDown.svg';
import ChevronUpIcon from '_images/icons/chevronUp.svg';
import SwipeDownIcon from '_images/icons/swipeDown.svg';
import SwipeUpIcon from '_images/icons/swipeUp.svg';
import { formatCurrency, formatTimestamp } from '_utilities';

import { useProfileGameplayQuery } from './useProfileGameplayQuery';

const containerHeight = 250;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: ${containerHeight}px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Toggle = styled.button`
  background-color: ${colors.backgroundL14};
  border-radius: ${borderRadius}px;
  box-shadow: ${boxShadow};
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  outline: none;
  padding: 10px 15px;
`;

const ToggleContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: ${({ isCollapsed }) => (isCollapsed ? colors.white : colors.primary)};
`;

const ToggleLeft = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
`;

const ToggleRight = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  font-size: 10px;
`;

const GameTitle = styled.div``;

const GameplayContent = styled.div`
  color: ${colors.white};
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const GameplayContentRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.div`
  color: ${colors.backgroundL80};
  font-size: 14px;
`;

const Value = styled.div`
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const GoldCoinsIcon = styled.div`
  height: 15px;
  width: 15px;
  background: ${colors.yellow};
  border-radius: 100%;
  font-size: 10px;
  color: ${colors.backgroundL5};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const SweepsCoinsIcon = styled.div`
  height: 15px;
  width: 15px;
  background: ${colors.green};
  border-radius: 100%;
  font-size: 10px;
  color: ${colors.backgroundL5};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinnerContainer = styled.div`
  height: ${containerHeight}px;
  display: grid;
  place-content: center;
`;

const GameplayItem = ({ transaction }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const {
    id,
    createdAt,
    game,
    amount,
    wallet,
    playableBalance,
  } = transaction;

  const isSweeps = wallet?.currency?.code === 'sweepsCoins';
  const iconText = wallet?.currency?.shortName?.slice(0, 1) || '';

  return (
    <ItemContainer key={id}>
      <Toggle onClick={() => setIsCollapsed(!isCollapsed)}>
        <ToggleContent isCollapsed={isCollapsed}>
          <ToggleLeft>
            {amount >= 0 ? (
              <SwipeUpIcon fill={isCollapsed ? colors.white : colors.primary} height={15} />
            ) : (
              <SwipeDownIcon fill={isCollapsed ? colors.white : colors.primary} height={15} />
            )}
            <GameTitle>{game?.name}</GameTitle>
          </ToggleLeft>
          <ToggleRight>
            <GameTitle>{formatTimestamp(Number(createdAt))}</GameTitle>
            {isCollapsed ? (
              <ChevronDownIcon fill={isCollapsed ? colors.white : colors.primary} width={15} />
            ) : (
              <ChevronUpIcon fill={isCollapsed ? colors.white : colors.primary} width={15} />
            )}
          </ToggleRight>
        </ToggleContent>
      </Toggle>

      {!isCollapsed && (
        <GameplayContent>
          <GameplayContentRow>
            <Label>Payout</Label>
            <Value>
              {formatCurrency(amount)}
              {isSweeps ? (
                <SweepsCoinsIcon>{iconText}</SweepsCoinsIcon>
              ) : (
                <GoldCoinsIcon>{iconText}</GoldCoinsIcon>
              )}
            </Value>
          </GameplayContentRow>
          <GameplayContentRow>
            <Label>Balance After Payout</Label>
            <Value>
              {formatCurrency(playableBalance)}
              {isSweeps ? (
                <SweepsCoinsIcon>{iconText}</SweepsCoinsIcon>
              ) : (
                <GoldCoinsIcon>{iconText}</GoldCoinsIcon>
              )}
            </Value>
          </GameplayContentRow>
        </GameplayContent>
      )}
    </ItemContainer>
  );
};

export const ProfileGameplay = () => {
  const { transactions, loading } = useProfileGameplayQuery();

  if (loading) {
    return <LoadingSpinnerContainer><LoadingSpinner /></LoadingSpinnerContainer>;
  }

  return (
    <Container>
      {transactions.map((transaction) => (
        <GameplayItem key={transaction.id} transaction={transaction} />
      ))}
    </Container>
  );
};
