// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Poppins-Thin.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Poppins-ExtraLight.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Poppins-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Poppins-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Poppins-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./Poppins-SemiBold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./Poppins-Bold.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('woff2');
}
`, "",{"version":3,"sources":["webpack://./src/_fonts/fonts.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,4DAAgD;AACpD;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,4DAAsD;AAC1D;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,4DAAiD;AACrD;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,4DAAmD;AACvD;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,4DAAkD;AACtD;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,4DAAoD;AACxD;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,4DAAgD;AACpD","sourcesContent":["@font-face {\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 100;\n    src: url('./Poppins-Thin.woff2') format('woff2');\n}\n\n@font-face {\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 200;\n    src: url('./Poppins-ExtraLight.woff2') format('woff2');\n}\n\n@font-face {\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 300;\n    src: url('./Poppins-Light.woff2') format('woff2');\n}\n\n@font-face {\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 400;\n    src: url('./Poppins-Regular.woff2') format('woff2');\n}\n\n@font-face {\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 500;\n    src: url('./Poppins-Medium.woff2') format('woff2');\n}\n\n@font-face {\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 600;\n    src: url('./Poppins-SemiBold.woff2') format('woff2');\n}\n\n@font-face {\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 700;\n    src: url('./Poppins-Bold.woff2') format('woff2');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
