import React from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import { AccessibilityStatement } from './accessibilityStatement';
import { CookiePolicy } from './cookiePolicy';
import { KycAmlPolicy } from './kycAmlPolicy';
import { PrivacyPolicy } from './privacyPolicy';
import { ResponsibleGameplayPolicy } from './responsibleGameplayPolicy';
import { SweepstakesRules } from './sweepstakesRules';
import { TermsAndConditions } from './termsAndConditions';

const Container = styled.div`
  padding: 25px;
`;

export const Policies = () => (
  <Container>
    <Routes>
      <Route path='/accessibilityStatement' element={<AccessibilityStatement />} />
      <Route path='/cookiePolicy' element={<CookiePolicy />} />
      <Route path='/kycAmlPolicy' element={<KycAmlPolicy />} />
      <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
      <Route path='/responsibleGameplayPolicy' element={<ResponsibleGameplayPolicy />} />
      <Route path='/sweepstakesRules' element={<SweepstakesRules />} />
      <Route path='/termsAndConditions' element={<TermsAndConditions />} />
    </Routes>
  </Container>
);
