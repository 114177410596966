import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { activeCurrencyVariable } from '_apollo';

const mancalaQuery = gql`
 query mancalaQuery(
    $currencyCode: CurrencyCode!
    $gameId: Int!
    $language: String
    $returnUrl: String!
    $externalId: String!
  ) {
    mancalaGameUrl(
      currencyCode: $currencyCode
      gameId: $gameId
      language: $language
      returnUrl: $returnUrl
      externalId: $externalId
    ) { 
        iFrameUrl,
        error {
          code,
          message,
        }
      }
  }
`;

export function useMancalaQuery ({ gameId, isMancalaGame, language, returnUrl, externalId }) {
  const activeCurrency = useReactiveVar(activeCurrencyVariable);
  const navigate = useNavigate();

  const { data } = useQuery(mancalaQuery, {
    skip: !isMancalaGame,
    variables: {
      currencyCode: activeCurrency,
      gameId,
      language,
      returnUrl,
      externalId,
    },
  });

  if (data?.mancalaGameUrl?.error) navigate('/terminal');

  return {
    mancalaGameUrl: data?.mancalaGameUrl.iFrameUrl || '',
  };
}
