import { gql, useQuery } from '@apollo/client';

const terminalQuery = gql`
  query terminalQuery {
    wallets {
      currency {
        code,
        id,
        name,
        shortName,
      },
      id,
      playableBalance,
    },
  }
`;

export function useTerminalQuery ({ skip }) {
  const { data } = useQuery(terminalQuery, { skip });

  return {
    gamesByCategory: data?.gamesByCategory || [],
    wallets: data?.wallets || [],
  };
}
