import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';

import { activeCurrencyVariable } from '_apollo';

const createTurboSportsbookSessionMutation = gql`
  mutation createTurboSportsbookSession($activeCurrency: String!) {
    createTurboSportsbookSession(activeCurrency: $activeCurrency) {
      iFrameUrl,
    },
  },
`;

export function useCreateTurboSportsbookSession ({ isTurboSportsbook }) {
  const activeCurrency = useReactiveVar(activeCurrencyVariable);

  const [createTurboSession, { data }] = useMutation(
    createTurboSportsbookSessionMutation
  );

  useEffect(() => {
    if (activeCurrency && isTurboSportsbook) {
      createTurboSession({
        variables: {
          activeCurrency,
        },
      });
    }
  }, [activeCurrency, createTurboSession, isTurboSportsbook]);

  return {
    turboSportsbookUrl: data?.createTurboSportsbookSession?.iFrameUrl,
  };
}
