import React, { useState } from 'react';
import styled from 'styled-components';

import { LoadingSpinner } from '_components';
import { borderRadius, boxShadow, colors } from '_constants';
import ChevronDownIcon from '_images/icons/chevronDown.svg';
import ChevronUpIcon from '_images/icons/chevronUp.svg';
import SwipeDownIcon from '_images/icons/swipeDown.svg';
import SwipeUpIcon from '_images/icons/swipeUp.svg';
import { formatCurrency, formatTimestamp, splitCamelCaseAndCapitalize } from '_utilities';

import { usePofileHistoryQuery } from './useProfileHistoryQuery';

const containerHeight = 250;

const TransactionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: ${containerHeight}px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const TransactionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Toggle = styled.button`
  background-color: ${colors.backgroundL14};
  border-radius: ${borderRadius}px;
  box-shadow: ${boxShadow};
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  outline: none;
  padding: 10px 15px;
`;

const ToggleContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: ${({ isCollapsed }) => (isCollapsed ? colors.white : colors.primary)};
`;

const ToggleLeft = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
`;

const TransactionTitle = styled.div``;

const ToggleRight = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  font-size: 10px;
`;

const TransactionContent = styled.div`
  color: ${colors.white};
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const TransactionContentRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.div`
  color: ${colors.backgroundL80};
  font-size: 14px;
`;

const Value = styled.div`
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const GoldCoinsIcon = styled.div`
  height: 15px;
  width: 15px;
  background: ${colors.yellow};
  border-radius: 100%;
  font-size: 10px;
  color: ${colors.backgroundL5};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const SweepsCoinsIcon = styled.div`
  height: 15px;
  width: 15px;
  background: ${colors.green};
  border-radius: 100%;
  font-size: 10px;
  color: ${colors.backgroundL5};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinnerContainer = styled.div`
  height: ${containerHeight}px;
  display: grid;
  place-content: center;
`;

export const Transaction = ({ transaction }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const balanceIncreaseLabels = ['adminDeposit', 'deposit', 'refund', 'bonusDeposit'];

  const isBalanceIncrease = balanceIncreaseLabels.includes(transaction.transactionType);

  const icon = isBalanceIncrease ? (
    <SwipeUpIcon fill={isCollapsed ? colors.white : colors.primary} height={15} />
  ) : (
    <SwipeDownIcon fill={isCollapsed ? colors.white : colors.primary} height={15} />
  );

  const isSweeps = transaction?.wallet?.currency?.code === 'sweepsCoins';
  const iconText = transaction?.wallet?.currency?.shortName?.slice(0, 1) || '';

  return (
    <TransactionContainer>
      <Toggle onClick={() => setIsCollapsed(!isCollapsed)}>
        <ToggleContent isCollapsed={isCollapsed}>
          <ToggleLeft>
            {icon}
            <TransactionTitle>{isBalanceIncrease ? 'Deposit' : 'Redemption'}</TransactionTitle>
          </ToggleLeft>

          <ToggleRight isCollapsed={isCollapsed}>
            <TransactionTitle>{formatTimestamp(Number(transaction.createdAt))}</TransactionTitle>
            {isCollapsed ? (
              <ChevronDownIcon fill={isCollapsed ? colors.white : colors.primary} width={15} />
            ) : (
              <ChevronUpIcon fill={isCollapsed ? colors.white : colors.primary} width={15} />
            )}
          </ToggleRight>
        </ToggleContent>
      </Toggle>

      {!isCollapsed && (
        <TransactionContent>
          <TransactionContentRow>
            <Label>Transaction Type</Label>
            <Value>{splitCamelCaseAndCapitalize(transaction.transactionType)}</Value>
          </TransactionContentRow>
          <TransactionContentRow>
            <Label>Amount</Label>
            <Value>
              {formatCurrency(transaction.amount)}
              {isSweeps ? (
                <SweepsCoinsIcon>{iconText}</SweepsCoinsIcon>
              ) : (
                <GoldCoinsIcon>{iconText}</GoldCoinsIcon>
              )}
            </Value>
          </TransactionContentRow>
          <TransactionContentRow>
            <Label>Balance After Transaction</Label>
            <Value>
              {formatCurrency(transaction.playableBalance)}
              {isSweeps ? (
                <SweepsCoinsIcon>{iconText}</SweepsCoinsIcon>
              ) : (
                <GoldCoinsIcon>{iconText}</GoldCoinsIcon>
              )}
            </Value>
          </TransactionContentRow>
        </TransactionContent>
      )}
    </TransactionContainer>
  );
};

export const ProfileHistory = () => {
  const { transactions, loading } = usePofileHistoryQuery();

  if (loading) {
    return <LoadingSpinnerContainer><LoadingSpinner /></LoadingSpinnerContainer>;
  }

  return (
    <TransactionsContainer>
      {transactions.map((transaction) => <Transaction key={transaction.id} transaction={transaction} />)}
    </TransactionsContainer>
  );
};
