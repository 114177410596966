import React from 'react';
import styled from 'styled-components';

import { Button, ClientLogo, Modal } from '_components';
import { useClaimBonusMutation } from '_hooks';
import { displayAlert } from '_utilities';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  font-weight: 400;
  gap: 15px;
`;

const StyledClientLogo = styled(ClientLogo)`
  height: 100%;
  width: 100%;
  max-height: 65px;
  max-width: 350px;
`;

export const BonusModal = ({ setShowBonusModal }) => {
  const claimBonus = useClaimBonusMutation();

  const onClaimBonus = () => {
    claimBonus();
    setShowBonusModal(false);
    displayAlert({ message: 'Bonus claimed!' });
  };

  return (
    <Modal
      closeModal={() => setShowBonusModal(false)}
      title="Claim Your Bonus"
    >
      <ModalContent>
        <StyledClientLogo />
        Claim your daily bonus!
        <Button onClick={onClaimBonus}>Claim</Button>
      </ModalContent>
    </Modal>
  );
};
