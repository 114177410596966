import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { alertVariable } from '_apollo';
import { Alert } from '_components';
import { removeAlert } from '_utilities';

const Container = styled.div`
  position: fixed;
  top: 75px;
  right: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const AlertController = () => {
  const alerts = useReactiveVar(alertVariable);

  return createPortal(
    <Container>
      {alerts.map(({ id, message, duration, type }) => (
        <Alert key={id} closeAlert={() => removeAlert(id)} duration={duration} type={type} >
          {message}
        </Alert>
      ))}
    </Container>,
    document.getElementById('alertRoot')
  );
};