import { gql, useQuery } from '@apollo/client';

const purchaseModalQuery = gql`
  query purchaseQuery {
    wallets {
      id,
      playableBalance,
      redeemableBalance,
      currency {
        code,
        id,
        name,
        shortName,
      },
    },
  }
`;

export function usePurchaseModalQuery () {
  const { data } = useQuery(purchaseModalQuery);

  const wallets = data?.wallets || [];

  return { wallets };
}
