import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';

import { showPurchaseModal } from '_apollo';
import { FormInput } from '_components';
import { borderRadius, boxShadow, colors, transitionDuration } from '_constants';
import { coinPackages } from '_constants';
import AddIcon from '_images/icons/add.svg';
import ChevronRightIcon from '_images/icons/chevronRight.svg';
import MasterCard from '_images/logos/masterCard.png';
import Visa from '_images/logos/visa.png';

import { usePurchaseModalQuery } from './usePurchaseModalQuery';

const AmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const AmountOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const AmountOption = styled.div`
  background-color: ${({ isActive }) => (isActive ? colors.primary : colors.backgroundL20)};
  border-radius: ${borderRadius}px;
  color: ${colors.white};
  cursor: pointer;
  padding: 10px 20px;
  transition: ${transitionDuration};
  flex: 1;
  box-shadow: ${boxShadow};

  &:hover {
    background-color: ${({ isActive }) => (isActive ? colors.primaryL57 : colors.backgroundL30)};
  }
`;

const PaymentMethodsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Title = styled.div`
  color: ${colors.backgroundL80};
  font-size: 14px;
  font-weight: 600;
`;

const PaymentMethod = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: space-between;
  padding: 25px;
  background-color: ${colors.backgroundL20};
  box-shadow: ${boxShadow};
  border-radius: ${borderRadius}px;
  cursor: pointer;
  transition: ${transitionDuration};
  gap: 15px;

  &:hover {
    background-color: ${colors.backgroundL25};
  }
`;

const CardName = styled.div`
  color: ${colors.white};
  flex: 1;
  font-size: 16px;
  font-weight: 500;
`;

const CardLogos = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;
`;

export const PurchaseModalStepOne = ({
  form,
  savedPaymentMethods,
  setSelectedSavedPaymentMethod,
  setShowPurchaseModalStepOne,
  setShowPurchaseModalStepTwo,
}) => {
  const { amount } = useReactiveVar(showPurchaseModal);

  const [selectedAmount, setSelectedAmount] = useState(amount);
  const { wallets } = usePurchaseModalQuery();

  const goldCoinsWallet = useMemo(() => {
    return wallets.find(({ currency }) => currency.code === 'goldCoins');
  }, [wallets]);

  const sweepsCoinsWallet = useMemo(() => {
    return wallets.find(({ currency }) => currency.code === 'sweepsCoins');
  }, [wallets]);

  useEffect(() => {
    if (selectedAmount === 'custom') {
      form.setValue('goldCoinsAmount', null);
      form.setFocus('goldCoinsAmount');
    } else {
      form.setValue('goldCoinsAmount', selectedAmount);
      form.setValue('sweepsCoinsAmount', selectedAmount / 1000);
    }
  }, [form, selectedAmount]);

  const goldCoinsAmount = form.watch('goldCoinsAmount');

  useEffect(() => {
    form.setValue('sweepsCoinsAmount', goldCoinsAmount / 1000 >= 1 ? goldCoinsAmount / 1000 : null);
  }, [form, goldCoinsAmount]);

  const handleAddNewCard = () => {
    setSelectedSavedPaymentMethod();
    setShowPurchaseModalStepOne(false);
    setShowPurchaseModalStepTwo(true);
  };

  const handleSelectSavedPaymentMethod = ({ savedPaymentMethod }) => () => {
    setSelectedSavedPaymentMethod(savedPaymentMethod);
    setShowPurchaseModalStepOne(false);
    setShowPurchaseModalStepTwo(true);
  };

  return <>
    <AmountContainer>
      <AmountOptions>
        {coinPackages.map(({ amount, label }) => <AmountOption key={label} isActive={selectedAmount === amount} onClick={() => setSelectedAmount(amount)}>{label}</AmountOption>)}
        <AmountOption isActive={selectedAmount === 'custom'} onClick={() => setSelectedAmount('custom')}>Custom</AmountOption>
      </AmountOptions>
      <FormInput name="goldCoinsAmount" placeholder={goldCoinsWallet?.currency.name} rules={{ required: true }} type="number" />
      <FormInput disabled name="sweepsCoinsAmount" placeholder={`Free ${sweepsCoinsWallet?.currency.name}`} rules={{ required: true }} type="number" />
    </AmountContainer>
    <PaymentMethodsContainer>
      <Title>Select Your Payment Method</Title>
      {!!savedPaymentMethods?.length && savedPaymentMethods.map((savedPaymentMethod) => (
        <PaymentMethod
          key={savedPaymentMethod.name}
          onClick={handleSelectSavedPaymentMethod({ savedPaymentMethod })}
        >
          <CardLogos>
            {savedPaymentMethod.name.includes('Visa') && <img src={Visa} alt="Visa" />}
            {savedPaymentMethod.name.includes('MasterCard') && <img src={MasterCard} alt="MasterCard" />}
          </CardLogos>
          <CardName>{savedPaymentMethod.name}</CardName>
          <ChevronRightIcon fill={colors.white} height={15} />
        </PaymentMethod>
      ))}
      <PaymentMethod onClick={handleAddNewCard}>
        <CardLogos>
          <img src={Visa} alt="Visa" />
          <img src={MasterCard} alt="MasterCard" />
        </CardLogos>
        <CardName>Add New Card</CardName>
        <AddIcon fill={colors.white} height={15} />
      </PaymentMethod>
    </PaymentMethodsContainer>
  </>;
};
