/* eslint-disable camelcase */
import { gql, useLazyQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Intercom } from '@intercom/messenger-js-sdk';
import { useEffect } from 'react';

import { colors } from '_constants';

const GET_INTERCOM = gql`
  query GetUserAndIntercom {
    getIntercomHash,
    user {
      id,
      email,
      createdAt,
    }
  }
`;

export function IntercomProvider () {
  const { user } = useAuth0();
  const [fetchIntercomHash, { data }] = useLazyQuery(GET_INTERCOM);

  useEffect(() => {
    if (user)
      fetchIntercomHash();
  }, [user, fetchIntercomHash]);

  useEffect(() => {

    if (!data?.user && !data?.getIntercomHash)
      return;

    const {
      user: { id, email },
      getIntercomHash,
    } = data || {};

    Intercom({
      action_color: colors.primary,
      app_id: process.env.INTERCOM_APP_ID,
      background_color: colors.backgroundL5,
      client: window.activeClient.clientName,
      created_at: user.createdAt,
      email,
      name: user.name,
      user_hash: getIntercomHash,
      user_id: id,
    });
    return () => {
      Intercom('shutdown'); // Logout
    };
  }, [data]);

  return null;
}
