import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const createTerminalSession = gql`
  mutation createTerminalSession {
    createTerminalSession {
      pairingCode,
    },
  }
`;

export function useCreateTerminalSessionMutation () {
  const [mutation, { data, error, loading }] = useMutation(createTerminalSession);

  return [
    useCallback((variables) => mutation({ variables }), [mutation]),
    { ...data?.createTerminalSession || {}, error, loading },
  ];
}
