import useEmblaCarousel from 'embla-carousel-react';
import { WheelGesturesPlugin as wheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { IconTitle } from '_components';
import { borderRadius, boxShadow, colors, mainContentMaxWidth, mobileBreakpoint, transitionDuration } from '_constants';
import { isMobileDevice, toCamelCase } from '_utilities';

const thumbnailSize = isMobileDevice ? 100 : 150;
const thumbnailMargin = 15;

const Container = styled.div`
  padding-bottom: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (min-width: ${mobileBreakpoint}px) {
    overflow: hidden;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Rows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Row = styled.div`
  position: relative;
`;

const InnerContainer = styled.div`
  display: flex;
`;

// Adding container to avoid errors with animation the carrousel aniamtions
const GameContainer = styled.div`
  margin-right: ${thumbnailMargin}px;
`;

const GameLink = styled(Link)`
  flex: 0 0 auto;
  height: ${thumbnailSize}px;
  width: ${thumbnailSize}px;
  cursor: pointer;
  box-shadow: ${boxShadow};
  border-radius: ${borderRadius * 2}px;
  -webkit-user-drag: none;
  user-select: none;
  display: block;
  transition: transform ${transitionDuration};

  &:hover {
    transform: translateY(-5px);
  }
`;

const Image = styled.img`
  border-radius: ${borderRadius * 2}px;
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const ViewAllButton = styled.div`
  cursor: pointer;
  font-size: 14px;
  transition: color 0.3s;

  &:hover {
    color: ${colors.primary};
  }
`;

const CarouselRow = memo(({ games }) => {
  const [emblaRef] = useEmblaCarousel({
    align: 'start',
    dragFree: true,
    loop: true,
  }, [wheelGesturesPlugin()]
  );

  return (
    <Row ref={emblaRef}>
      <InnerContainer>
        {games.map((game, index) => (
          <GameContainer key={index}>
            <GameLink to={`/game/${toCamelCase(game?.provider?.name)}/${game.externalId}/${game.id}`}>
              <Image src={game.imageUrl} loading='lazy' alt={`${game?.provider?.name} ${game.name}`} />
            </GameLink>
          </GameContainer>
        ))}
      </InnerContainer>
    </Row>
  );
});

export const GameCarousel = ({ category, games, onViewAllClick }) => {
  const windowWidth = window.innerWidth;
  // Validates against container width instead of screen width if windowWidth < mainContentMaxWidth
  const containerWidth = windowWidth < mainContentMaxWidth ? windowWidth : mainContentMaxWidth;

  const singleTileWidth = thumbnailSize + thumbnailMargin;
  const totalWidthNeeded = games.length * singleTileWidth;

  // Halve games to see if there is enough to fill two rows
  const half = Math.ceil(games.length / 2);
  const topRowCount = half;
  const bottomRowCount = games.length - half;

  const topRowWidthNeeded = topRowCount * singleTileWidth;
  const bottomRowWidthNeeded = bottomRowCount * singleTileWidth;

  // Two row carousel means both halves must expand beyond container width
  const canRenderTwoRows =
    topRowWidthNeeded > containerWidth && bottomRowWidthNeeded > containerWidth;

  // For one-row carousel, we only need check total width.
  const canRenderOneRow = totalWidthNeeded > containerWidth;

  return (
    <Container>
      <Header>
        <IconTitle icon={category.icon} title={category.title} />
        <ViewAllButton onClick={onViewAllClick}>
          View All
        </ViewAllButton>
      </Header>
      {/* Enough content to render TWO carousel rows */}
      {canRenderTwoRows && (
        <Rows>
          <CarouselRow games={games.slice(0, topRowCount)} />
          <CarouselRow games={games.slice(topRowCount)} />
        </Rows>
      )}

      {/* Enough content to render ONE carousel row */}
      {!canRenderTwoRows && canRenderOneRow && (
        <Rows>
          <CarouselRow games={games} />
        </Rows>
      )}

      {/* Enough content to render ONE STATIC row */}
      {!canRenderTwoRows && !canRenderOneRow && (
        <Rows>
          <Row>
            <InnerContainer>
              {games.map((game, index) => (
                <GameContainer key={index}>
                  <GameLink
                    key={index}
                    to={`/game/${toCamelCase(game?.provider?.name)}/${game.externalId}/${game.id}`}
                  >
                    <Image src={game.imageUrl} loading='lazy' alt={`${game?.provider?.name} ${game.name}`} />
                  </GameLink>
                </GameContainer>
              ))}
            </InnerContainer>
          </Row>
        </Rows>
      )}
    </Container>
  );
};
