import { gql, useQuery } from '@apollo/client';

const terminalCasinoQuery = gql`
  query terminalCasinoQuery {
    gamesByCategory {
      name,
      games {
        externalId,
        id,
        imageUrl,
        name,
        provider {
          id,
          name,
        },
      },
    },
  }
`;

export function useTerminalCasinoQuery () {
  const { data } = useQuery(terminalCasinoQuery);

  return {
    gamesByCategory: data?.gamesByCategory.filter((category) => category.name !== 'Live Dealers') || [],
    wallets: data?.wallets || [],
  };
}
