import { useState } from 'react';

export function useUpdateSavedPaymentMethods ({ handlePaymentMethodError }) {
  const [loading, setLoading] = useState(false);

  const updateSavedPaymentMethods = ({
    formData,
    isRemoving,
    selectedSavedPaymentMethod,
    sessionId,
    type,
  }) => {
    const body = isRemoving ? {
      MerchantID: 'izdgMW8P4Uu5jr95GtuvvA',
      MerchantSessionID: sessionId,
      SavePaymentMethod: false,
      PaymentMethod: {
        Type: selectedSavedPaymentMethod.type,
        Token: selectedSavedPaymentMethod.token,
      },
    } : {
      MerchantID: 'izdgMW8P4Uu5jr95GtuvvA',
      MerchantSessionID: sessionId,
      SavePaymentMethod: formData.savePaymentMethod,
      PaymentMethod: {
        Type: type,
        NameOnAccount: formData.nameOnAccount,
        BillingAddress: {
          AddressLine1: formData.addressLine1,
          City: formData.city,
          StateCode: formData.state,
          PostalCode: formData.zipCode,
          CountryCode: formData.country,
        },
      },
    };

    if (type === 'CC') {
      body.PaymentMethod.CardNumber = formData.cardNumber;
      body.PaymentMethod.ExpirationDate = formData.cardExpirationDate;
      body.PaymentMethod.CVV = formData.cvv;
    } else {
      body.PaymentMethod.AccountNumber = formData.accountNumber;
      body.PaymentMethod.RoutingNumber = formData.routingNumber;
    }

    setLoading(true);
    const gambleIdUrl = process.env.ENVIRONMENT === 'production'
      ? 'https://api.gidx-service.com/v3.0/api/DirectCashier/PaymentMethod'
      : 'https://api.gidx-service.in/v3.0/api/DirectCashier/PaymentMethod';

    // TODO: Use axios
    return fetch(gambleIdUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then(async (response) => {
      const responseJson = await response.json();
      if (responseJson?.ResponseCode !== 0) {
        handlePaymentMethodError();
        return {};
      }
      return {
        type: responseJson.PaymentMethod.Type,
        token: responseJson.PaymentMethod.Token,
      };
    }).catch((error) => {
      console.error('Error:', error);
      handlePaymentMethodError();
      return {};
    }).finally(() => {
      setLoading(false);
    });
  };

  return [updateSavedPaymentMethods, { loading }];
}
