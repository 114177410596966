import { gql, useQuery } from '@apollo/client';

const walletQuery = gql`
  query coinPacksQuery {
    wallets {
      id,
      playableBalance,
      redeemableBalance,
      currency {
        code,
        id,
        name,
        shortName,
      },
    },
  }
`;

export function useCoinPacksQuery () {
  const { data, loading } = useQuery(walletQuery);

  const wallets = data?.wallets || [];

  return { loading, wallets };
}
