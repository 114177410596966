import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useFormContext, Controller } from 'react-hook-form';
import styled, { keyframes } from 'styled-components';

import { borderRadius, boxShadow, colors, transitionDuration } from '_constants';
import { useClickAway } from '_hooks';
import ChevronDownIcon from '_images/icons/chevronDown.svg';
import ChevronUpIcon from '_images/icons/chevronUp.svg';

const errorMessages = {
  required: 'Required',
  pattern: 'Invalid format',
};

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

const DropdownContainer = styled.div`
  position: relative;
  background-color: ${colors.backgroundL20};
  height: 45px;
  padding: 0 15px;
  border-radius: ${borderRadius}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: ${({ error }) => (error ? `1px solid ${colors.red}` : 'none')};
  cursor: ${({ isInputActive }) => isInputActive ? 'text' : 'pointer'};
  box-shadow: ${boxShadow};
`;

const DropdownLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ErrorMessage = styled.span`
  color: ${colors.red};
  font-size: 11px;
`;

const InputLabel = styled.div`
  color: ${colors.backgroundL80};
  font-size: 11px;
  font-weight: 500;
  animation: ${fadeInUp} ${transitionDuration} ease-out;
`;

const SelectedOptionLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const MainInput = styled.input`
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  color: ${colors.white};
  font-size: 14px;
  font-weight: 500;
  padding: 0;

  &::placeholder {
    color: ${colors.backgroundL80};
  }
`;

const Placeholder = styled.div`
  color: ${colors.backgroundL80};
  font-size: 14px;
`;

const MenuContainer = styled.div`
  position: absolute;
  top: ${({ menuTop }) => menuTop}px;
  left: ${({ menuLeft }) => menuLeft}px;
  box-shadow: ${boxShadow};
  background-color: ${colors.backgroundL25};
  border-radius: ${borderRadius}px;
  overflow-y: auto;
  max-height: 200px;
  width: ${({ width }) => width}px;
  margin-top: 5px;
  scrollbar-color: ${colors.white} ${colors.backgroundL30};
`;

const Option = styled.div`
  padding: 10px 15px;
  cursor: pointer;
  color: ${colors.white};
  transition: background-color ${transitionDuration};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;

  ${({ isSelected }) => isSelected && `
    background-color: ${colors.backgroundL30};
  `}

  &:hover {
    background-color: ${colors.backgroundL30};
  }
`;

const NoOptions = styled.div`
  padding: 10px 15px;
  color: ${colors.backgroundL80};
  font-size: 14px;
  text-align: center;
`;

const Menu = ({
  field,
  isModalDropdown,
  menuRef,
  onOptionChange,
  options,
  triggerRef,
  searchTerm,
  valueSelected,
}) => {
  const triggerBoundingRect = triggerRef.current.getBoundingClientRect();
  const menuLeft = triggerBoundingRect.x;
  const menuTop = triggerBoundingRect.y + triggerBoundingRect.height;
  const menuWidth = triggerBoundingRect.width;

  // Filter options based on search term
  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return createPortal(
    <MenuContainer menuLeft={menuLeft} menuTop={menuTop} width={menuWidth} ref={menuRef} >
      {filteredOptions.length > 0 ? (
        filteredOptions.map((option) => (
          <Option isSelected={valueSelected === option.value} key={option.value} onClick={onOptionChange({ field, option })}>
            {option.optionRender ? option.optionRender() : option.label}
          </Option>
        ))
      ) : (
        <NoOptions>No options</NoOptions>
      )}
    </MenuContainer>,
    document.getElementById(isModalDropdown ? 'modalDropdownRoot' : 'dropdownRoot'),
  );
};

export const FormDropdown = ({
  defaultOption,
  name,
  isModalDropdown,
  options,
  placeholder,
  rules,
}) => {
  const { control, formState: { errors }, setValue, watch } = useFormContext();
  const [showMenu, setShowMenu] = useState(false);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [labelSelected, setLabelSelected] = useState('');

  const triggerRef = useRef();
  const menuRef = useRef();
  const inputRef = useRef();
  const fieldValue = watch(name);

  useEffect(() => {
    if (!defaultOption) return;
    setValue(name, defaultOption.value);
    setSelectedDropdownOption(defaultOption);
  }, [defaultOption, name, setValue]);

  useClickAway({
    ref: menuRef,
    onClickAway: (event) => {
      if (event.target === triggerRef.current || triggerRef.current.contains(event.target)) return;
      setShowMenu(false);
    },
  });

  const onOptionChange = ({ field, option }) => () => {
    setSelectedDropdownOption(option);
    setShowMenu(false);
    field.onChange(option.value);
    setSearchTerm('');
    setLabelSelected(option.label);
  };

  const handleDropdownClick = (e) => {
    if (e.target !== inputRef.current) {
      setShowMenu(!showMenu);
    }

    if (!showMenu) {
      setSearchTerm('');
    }
  };

  return (
    <Container>
      <DropdownContainer
        error={errors[name]}
        onClick={handleDropdownClick}
        ref={triggerRef}
        isInputActive={showMenu}
      >
        <DropdownLabelContainer>
          {(fieldValue || showMenu) && <InputLabel>{placeholder}</InputLabel>}
          {showMenu ? (
            <MainInput
              ref={inputRef}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={labelSelected ? labelSelected : 'Search...'}
              autoFocus
            />
          ) : (
            selectedDropdownOption.label ?
              <SelectedOptionLabel>{selectedDropdownOption.label}</SelectedOptionLabel> :
              <Placeholder>{placeholder}</Placeholder>
          )}
        </DropdownLabelContainer>

        {showMenu ? <ChevronUpIcon fill={colors.white} height={6} /> : <ChevronDownIcon fill={colors.white} height={6} />}

        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => showMenu && (
            <Menu
              field={field}
              isModalDropdown={isModalDropdown}
              menuRef={menuRef}
              onOptionChange={onOptionChange}
              options={options}
              triggerRef={triggerRef}
              searchTerm={searchTerm}
              valueSelected={fieldValue}
            />
          )}
        />
      </DropdownContainer>

      {errors[name] && (
        <ErrorMessage>
          {errors[name]?.message || errorMessages[errors[name]?.type] || 'Invalid'}
        </ErrorMessage>
      )}
    </Container>
  );
};