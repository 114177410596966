import { gql, useQuery } from '@apollo/client';

const pairTerminalSessionQuery = gql`
  query pairTerminalSessionQuery {
    user {
      id,
    },
  }
`;

export function usePairTerminalSessionQuery () {
  const { data } = useQuery(pairTerminalSessionQuery);

  return {
    user: data?.user || {},
  };
}
