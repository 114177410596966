export const coinPackages = [
  {
    title: '10,000',
    bonus: '10 Free',
    amount: 10000,
    label: '$10',
  },
  {
    title: '25,000',
    bonus: '25 Free',
    amount: 25000,
    label: '$25',
  },
  {
    title: '50,000',
    bonus: '50 Free',
    amount: 50000,
    label: '$50',
  },
  {
    title: '100,000',
    bonus: '100 Free',
    amount: 100000,
    label: '$100',
  },
  {
    title: '200,000',
    bonus: '200 Free',
    amount: 200000,
    label: '$200',
  },
  {
    title: '300,000',
    bonus: '300 Free',
    amount: 300000,
    label: '$300',
  },
];
