import React from 'react';
import styled from 'styled-components';

import { useEvenbetPokerQuery } from './useEvenbetPokerQuery';

const CenterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100dvh - 136px);
  width: 100%;
`;

const StyledIframe = styled.iframe`
  border: none;
  border-radius: 8px;
  height: 99%;
  width: 99%;
  overflow: hidden;
`;

export function EvenbetPoker () {
  const { evenbetLobbyUrl } = useEvenbetPokerQuery();

  return (
    <CenterWrapper>
      <StyledIframe
        allowFullScreen
        src={evenbetLobbyUrl}
        title="Evenbet Poker"
      />
    </CenterWrapper>
  );
}
