import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';

import { userGpsVariable } from '_apollo';

const createPaymentSession = gql`
  mutation createPaymentSession ($gps: GpsInput, $isDeposit: Boolean) {
    createPaymentSession (gps: $gps, isDeposit: $isDeposit) {
      error {
        code,
        message,
      },
      savedPaymentMethods {
        id,
        name,
        token,
        type,
      },
      sessionId,
      transactionId,
    },
  }
`;

export function useCreatePaymentSessionMutation ({ isDeposit }) {
  const userGps = useReactiveVar(userGpsVariable);
  const [mutation, { data, loading, error: mutationError }] = useMutation(createPaymentSession, {
    variables: {
      gps: userGps,
      isDeposit,
    },
  });

  return [
    useCallback((variables) => mutation({ variables }), [mutation]),
    { ...data?.createPaymentSession || {}, loading, mutationError },
  ];
}
