import { useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { activeCurrencyVariable, terminalAccessTokenVariable } from '_apollo';
import { Button, ClientLogo, Dropdown } from '_components';
import { boxShadow, colors } from '_constants';
import { formatCurrency } from '_utilities';

import { TerminalCasino, TerminalDemo, TerminalGame, TerminalQrCode } from './components';
import { useCreateTerminalSessionMutation } from './useCreateTerminalSessionMutation';
import { useOnPairingUpdateSubscription } from './useOnPairingUpdateSubscription';
import { useTerminalQuery } from './useTerminalQuery';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Content = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vh;
  height: 10vh;
  background-color: ${colors.backgroundL14};
  box-shadow: ${boxShadow};
`;

const StyledClientLogo = styled(ClientLogo)`
  max-width: 35%;
  max-height: 5vh;
`;

const FooterRight = styled.div`
  display: flex;
  gap: 1vh;
`;

const CurrencySelector = styled.div`
  display: flex;
  box-shadow: ${boxShadow};
`;

const CurrencySelectorDropdown = styled(Dropdown)`
  font-size: 1.5vh;
  border-radius: 1vh;
  padding: 1vh 1.5vh;

  svg {
    height: .5vh;
  }
`;

const CurrencyOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1vh;
  min-width: 100px;
  width: 100%;
  font-size: 1.5vh;
`;

const CurrencyIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5vh;
`;

const GoldCoinsIcon = styled.div`
  height: 1.5vh;
  width: 1.5vh;
  background: ${colors.yellow};
  border-radius: 100%;
  font-weight: 500;
  font-size: 1vh;
  color: ${colors.backgroundL5};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SweepsCoinsIcon = styled.div`
  height: 1.5vh;
  width: 1.5vh;
  background: ${colors.green};
  border-radius: 100%;
  font-weight: 500;
  font-size: 1vh;
  color: ${colors.backgroundL5};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectedCurrencyOption = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5vh;
  font-weight: 500;
  pointer-events: none;
`;

const PlayNowText = styled.div`
  font-size: 2vh;
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

const TerminalButton = styled(Button)`
  font-size: 1.5vh;
  border-radius: 1vh;
  padding: 1vh 1.5vh;
`;

export const Terminal = () => {
  const [createTerminalSession, { pairingCode }] = useCreateTerminalSessionMutation();
  const { data, error, loading, restart } = useOnPairingUpdateSubscription({ pairingCode });
  const { wallets } = useTerminalQuery({ skip: !data?.onPairingUpdate.token });
  const location = useLocation();
  const navigate = useNavigate();
  const activeCurrency = useReactiveVar(activeCurrencyVariable);
  const terminalAccessToken = useReactiveVar(terminalAccessTokenVariable);

  useEffect(() => {
    createTerminalSession();
  }, [createTerminalSession]);

  useEffect(() => {
    if (!data?.onPairingUpdate.token) return;
    if (data.onPairingUpdate.token === terminalAccessToken) return;
    terminalAccessTokenVariable(data.onPairingUpdate.token);
    navigate('/terminal');
  }, [data?.onPairingUpdate.token, navigate, terminalAccessToken]);

  const isGamePage = location.pathname.includes('/game');

  // Build dropdown options for selecting active currency
  const currencyDropdownOptions = wallets.map((wallet) => ({
    label: `${wallet.playableBalance}${wallet.currency.shortName}`,
    optionRender: () => (
      <CurrencyOption>
        {formatCurrency(wallet.playableBalance)}
        <CurrencyIcon>
          {wallet.currency.code === 'goldCoins' ? (
            <GoldCoinsIcon>{wallet.currency.shortName[0]}</GoldCoinsIcon>
          ) : (
            <SweepsCoinsIcon>{wallet.currency.shortName[0]}</SweepsCoinsIcon>
          )}
          {wallet.currency.shortName}
        </CurrencyIcon>
      </CurrencyOption>
    ),
    selectedOptionRender: () => (
      <SelectedCurrencyOption>
        {isGamePage ? '(In Play)' : formatCurrency(wallet.playableBalance)}
        {wallet.currency.code === 'goldCoins' ? (
          <GoldCoinsIcon>{wallet.currency.shortName[0]}</GoldCoinsIcon>
        ) : (
          <SweepsCoinsIcon>{wallet.currency.shortName[0]}</SweepsCoinsIcon>
        )}
      </SelectedCurrencyOption>
    ),
    value: wallet.currency.code,
  }));

  const handleCurrencyChange = (selectedCurrencyOption) => {
    activeCurrencyVariable(selectedCurrencyOption.value);
  };

  const handleLogout = () => {
    createTerminalSession();
    restart();
    terminalAccessTokenVariable(null);
    navigate('/terminal/demo');
  };

  return (
    <Container>
      <Content>
        <Routes>
          <Route path="/:providerId/:gameId" element={<TerminalGame />} />
          <Route path="/demo" element={<TerminalDemo pairingCode={pairingCode} />} />
          <Route path="/demo/2" element={<TerminalDemo pairingCode={pairingCode} secondVideo />} />
          <Route path="/" element={<TerminalCasino />} />
          <Route path="/game/:providerName" element={<TerminalGame />} />
          <Route path="/game/:providerName/:externalId/:gameId" element={<TerminalGame />} />
        </Routes>
      </Content>
      <Footer>
        <StyledClientLogo />
        {data?.onPairingUpdate.token ? <FooterRight>
          <CurrencySelector>
            <CurrencySelectorDropdown
              alignTop
              onChange={handleCurrencyChange}
              options={currencyDropdownOptions}
              selectedOption={
                currencyDropdownOptions.find(({ value }) => value === activeCurrency) || {}
              }
            />
          </CurrencySelector>
          {isGamePage ? <TerminalButton onClick={() => navigate('/terminal')}>Back To Lobby</TerminalButton>
            : <TerminalButton onClick={handleLogout}>End Session</TerminalButton>}
        </FooterRight> : <FooterRight>
          <PlayNowText>Scan To Play Now</PlayNowText>
          <TerminalQrCode pairingCode={pairingCode} />
        </FooterRight>}
      </Footer>
    </Container>
  );
};
