import React from 'react';

export const KycAmlPolicy = () => (
  <div>
    <p>1. Company Business Model Rad Software LLC operates and manages the online social sweepstakes casino 'LuckLake.com.' All legal, operational, and policy-related matters for this casino are overseen by Rad Software LLC, with 'LuckLake.com' being an approved DBA of Rad Software LLC.&#160;</p>
    <p>RadSoftware (&ldquo;LuckLake&rdquo; or the &ldquo;Company&rdquo;) is a company incorporated under Wisconsin (USA) Law that was established in 2023 and operates the online casino www.LuckLake.com.</p>
    <p>As part of its operations, LuckLake.com has established compliance measures commensurate with its services and products that are reasonably designed to deter and detect illicit activity on its platform. Such measures include onboarding and compliance screenings of its customers and transaction action-based controls.</p>
    <p>2. Company Policy Statement LuckLake.com is not a financial institution within the meaning of applicable law of Wisconsin and is accordingly not directly subject to the statutes and regulations applicable to certain financial institutions, money transfer, or virtual asset service providers. However, in accordance with the 2016 Regulations for Anti-Money Laundering and Combating the Financing of Terrorism (&ldquo;AML/CFT&rdquo;) applicable for Wisconsin, LuckLake.com expressly prohibits and rejects the use of LuckLake.com products for any form of illicit activity, including money laundering, terrorist financing or trade sanctions violations, consistent with various national anti-money laundering (&ldquo;AML&rdquo;) laws, regulations and norms. LuckLake.com continues to monitor norm setting parameters promulgated by the Financial Action Task Force (&ldquo;FATF&rdquo;) and certain gaming trade groups and will take necessary action as it deems appropriate to reflect changes in law.</p>
    <p>LuckLake&rsquo;s intention is to follow best practices in guarding against LuckLake.com products being used to facilitate such activities. Those best practices include:</p>
    <p>Adoption of a written policy, and procedures and controls, reasonably designed to guard against money laundering, terrorist financing and trade sanctions violations;</p>
    <p>Where appropriate, designation of a compliance officer to oversee the implementation of the policy, procedures and controls;</p>
    <p>Provision of related education and training to relevant personnel; and</p>
    <p>Independent reviews, monitoring and maintenance of the policy, procedures and controls.</p>
    <p>3. Definitions The following defined terms are widely used in the industry:</p>
    <p>Money Laundering: The process of making illegally-gained proceeds appear legal. This process is generally broken down into three steps: placement, layering and integration.</p>
    <p>Placement: The process of placing unlawful proceeds into traditional financial institutions, through purchases or other avenues.</p>
    <p>Layering: The process of separating proceeds of criminal activity from their origin through the use of layers of complex financial transactions, such as converting cash into traveler&rsquo;s checks, money orders, wire transfers, letters of credit, stocks, bonds or purchasing assets.</p>
    <p>Integration: Using apparently legitimate transactions to disguise the illicit proceeds, allowing the laundered funds to be distributed back to the criminal; integrating the now clean money back into normal use.</p>
    <p>Suspicious Activity: Activity conducted by a user or non-user using the institution where there are indications that the persons engaging in the transaction may be doing so for fraudulent or illegal purposes.</p>
    <p>Sanctions: Sanctions are activities conducted by the international community to prohibit or constrain activities of the target of the sanctions.For example, they are used:</p>
    <p>To encourage a change in behaviour for a target country or regime;</p>
    <p>To apply pressure on a target country to comply with set objectives;</p>
    <p>As an enforcement tool when international peace and security has been threatened and diplomatic efforts have failed; or</p>
    <p>To prevent and suppress the financing of terrorists or terrorist acts.</p>
    <p>4. Governance and Oversight LuckLake.com has appointed a Chief Compliance Officer (&ldquo;CCO&rdquo;) that is responsible for coordinating the implementation of the AML Policy and policy program. The Chief Compliance Officer&rsquo;s duties also include developing AML initiatives, work with other stakeholders to revising the AML policy, assessing new regulatory requirements and investigate potentially suspicious or unusual activity. LuckLake.com also provides AML training to all of its employees on a regular basis.</p>
    <p>5. Know Your Customer and Transaction Monitoring LuckLake.com will apply appropriate user due diligence and ongoing monitoring measures required by law. LuckLake.com will endeavour to prevent users from engaging in illicit or otherwise unauthorised activity. LuckLake.com uses a combination of its software development and other service agreements, which are enforced through internal operational features to ensure that it complies with the applicable law.</p>
    <p>5. 1. Know Your Customer A. Customer Due Diligence.</p>
    <p>LuckLake.com has adopted a risk based CDD to enable LuckLake.com to understand the nature and purpose of the user relationship to the LuckLake.com platform in order to develop a customer risk profile. In order to do so, LuckLake.com collects certain documentary and non-documentary information at account-opening commensurate with the nature of the type account and services that LuckLake.com offers. LuckLake.com maintains different CDD for different accounts and services.</p>
    <p>For instance, the CDD requires users go through LuckLake&rsquo;s customer identification program (&ldquo;CIP&rdquo;). The CIP consists of procedures for:</p>
    <p>Collecting baseline (e.g., home address, email address) information at account creation through LuckLake&rsquo;s user onboarding portal;</p>
    <p>Monitoring the risk profile associated with the underlying cryptocurrency wallet used to fund the user&rsquo;s account;</p>
    <p>Maintaining records of the information used to identify the user; and</p>
    <p>Determining if a user appears on any list of known or suspected terrorists or terrorist organisations provided to the financial institutions based on the above information.</p>
    <p>The above steps are operationalised using the following measures:</p>
    <p>Identity and Age Verification. A third-party service provider will support LuckLake&rsquo;s ability to determine the legitimacy of the identification information other KYC materials or information provided and will confirm that the user is permissible. The service provider also will confirm that the user does not appear to be located in a comprehensively-sanctioned or otherwise prohibited jurisdiction and will search global sanctions lists to confirm that the user does not appear thereon using onboarding information such as wallet addresses.</p>
    <p>Customer Information. LuckLake.com will collect details on each user to form a reasonable belief that LuckLake.com knows the identity of its users commensurate with the user&rsquo;s risk profile. For instance, LuckLake.com may collect such details as the wallet address, name, address, country, date of birth, or postal code (collectively, &ldquo;KYC Information&rdquo;). LuckLake.com will collect any of the above KYC Information prior to issuing a LuckLake.com funding address (e.g., QR code) to users. LuckLake.com does not presently allow users who are non-natural persons. LuckLake.com may, at its own description rely on the performance by another institution of some or all of the elements of our CIP.</p>
    <p>Geo-blocking for Prohibited Jurisdictions. LuckLake.com will require contractual client certifications that, through IP address-based geo-blocking, no gaming services will be offered in countries where such activity is not permitted.</p>
    <p>Geo-blocking for Sanctioned Jurisdictions. LuckLake.com also will require contractual client certifications that such users are not subject to United States, European Union, or other global sanctions or watch lists, including individuals or entities associated with the United States&rsquo; comprehensively sanctioned jurisdictions, Iran, Cuba, North Korea, Syria and the Crimean region of Ukraine. LuckLake.com will rely on various risk-based measures to verify these representations including as in the below-described know-your-user (&ldquo;KYC&rdquo;) measures and through IP address-based geo-blocking.</p>
    <p>Contractual Prohibitions on Users Onboarding from Prohibited Jurisdictions. Users are notified at onboarding that LuckLake.com does not offer services in restricted jurisdictions. LuckLake&rsquo;s policy on restricting user activity stems from a combination of its risk, fraud prevention, and AML standards, as well as any assessments associated with the permissibility of its services in certain jurisdictions.</p>
    <p>B. Enhanced Due Diligence and Ongoing Monitoring</p>
    <p>LuckLake.com performs ongoing monitoring on its users in order to detect any behaviours or indicators that might raise suspicions in regard to money laundering and terrorism financing practices. For that purpose, LuckLake.com has implemented a set of red flag indicators that help it determine such behaviours and require further action from LuckLake.com in assessing the customer information.</p>
    <p>Whenever one of those red flags is triggered, the user account will be suspended and LuckLake.com will pursue enhanced due diligence. Enhanced KYC diligence under this policy is deemed to include, but not limit to, the provision of:</p>
    <p>Full legal name; Country of citizenship; Permanent Address (which, for an individual, must be a residential or business street address, and for an entity, must be a principal place of business, local office or other physical location); Identification number (either a taxpayer identification number, or, if unavailable, a passport number and country of issuance, alien identification card number or number and country of issuance of another government-issued document evidencing nationality or residence and bearing a photograph or similar safeguard); Identification Document; and Source of Funds and Source of Wealth.</p>
    <p>LuckLake.com may use third party service provider to verify any of the above information as determined to establish a reasonable basis to know the true identity of the user where the user&rsquo;s activity warrants such action.</p>
    <p>C. Acceptance Policy</p>
    <p>LuckLake.com will not accept a and will block the users that: Do not provide the identification information requested by LuckLake; Provide fake identification documents; Try to use different means to deceive about their location; Are from restricted or prohibited jurisdictions; or Are subjected to United States, European Union, or other global sanctions or watch lists; Are gambling addicted or have mental health issues; Its source of funds are originated or exchanges in restricted jurisdictions; LuckLake.com reserves the right to block and suspend player for any other reasons at its own description</p>
    <p>5.2. Transactions Monitoring</p>
    <p>LuckLake.com is firmly committed to complying with economic and trade sanctions programs imposed by jurisdictions in which the firm conducts business. For that purpose LuckLake.com established a transaction monitoring program with controls and processes to identify and detect any unusual activity in real time and in its ongoing monitoring.</p>
    <p>LuckLake.com will conduct ongoing monitoring on a regular basis using rule-based systems developed in-house and others from third-party vendors to review user history and patterns of activity to detect and report any unusual activity as required and to develop and implement any additional controls or limits in its platform.</p>
    <p>LuckLake.com implemented procedures addressing the following two key components of unusual or suspicious activity management:</p>
    <p>identification of unusual activity through methods of identification may include employee and customer identification, law enforcement inquiries, other referrals, or transaction and surveillance monitoring system reports; and</p>
    <p>alert management that focuses on processes used to investigate, evaluate and document identified unusual or potentially suspicious activity.</p>
    <p>LuckLake.com will use the following processes to achieve both goals:</p>
    <p>Transaction Monitoring for Sanctioned or Prohibited Jurisdictions. LuckLake.com may in its reasonable discretion impose certain due diligence requests at user redemption. LuckLake.com presently conducts a mixture of manual and automated transaction monitoring processes to identify &ldquo;red flag&rdquo; behaviour. Where such red flag behaviour is identified, LuckLake.com may refuse to process any redemption attempts or collect additional information from the recipient. LuckLake.com will further endeavour to limit any attempted user account funding from prohibited jurisdictions (which are identified in the LuckLake.com user facing disclosures and updated from time to time internally) where the associated wallet address indicates that the user or the user&rsquo;s funds are located in such a prohibited jurisdiction. Users will have the ability to rebut any suspension with additional information as part of LuckLake&rsquo;s ongoing transaction monitoring and user due diligence standards.</p>
    <p>Screening for Sanctioned Parties. Prior to issuing a LuckLake.com funding address to a user, LuckLake.com will screen a user&rsquo;s wallet address against applicable sanctions databases. Such screening measures will rely on third party blockchain forensics vendors such as GambleID/TSEVO. LuckLake.com will periodically re-screen wallet addresses against such databases.</p>
    <p>Identification of Unusual Activity. LuckLake.com will monitor account activity for unusual size, volume, pattern or type of transactions, taking into account risk factors and red flags that are appropriate to its business. Monitoring will be conducted running regular reports of unusual, high risk, or suspicious user activity.</p>
    <p>Anti-Mixing Measures. LuckLake.com will utilize software designed to detect other suspicious purchase or redemption patterns. Such instances will be dealt with on a case-by-case basis, depending on the perceived level of risk. In such instances, a user may be required to explain their methodology and purpose for using the platform.</p>
    <p>GambleID/TSEVO Review. Every single coin package purchase or redemption both indirect and direct will run through GambleID/TSEVO and be reviewed for signs of fraud or suspicious behaviour. A user&rsquo;s account will be suspended and reviewed upon alert of potential illicit behaviour. Sufficient proof of wealth may be requested from high-risk accounts. LuckLake.com may refuse to withdraw to certain &ldquo;high-risk&rdquo; addresses as determined in consultation with GambleID/TSEVO risk scoring.</p>
    <p>Redemption Threshold KYC. Additionally, and independently, every account, wherever or with whomever associated, will be suspended until adequate KYC diligence occurs once that account reaches a redemption threshold dependent on the accounts risk characterisation over the life of the account.</p>
    <p>D. Other Ongoing Monitoring Controls</p>
    <p>Additionally to the above mentioned controlling procedures, LuckLake.com has also implemented the following procedures to complement its know your customer and ongoing monitoring procedures:</p>
    <p>Ban Evasion Detection. In addition, LuckLake.com will utilise third-party service provider software designed to detect the use by one user of multiple accounts. This software relies on detection of links between the same devices used to access multiple accounts. Such instances will be dealt with on a case-by-case basis, depending on the perceived level of risk. In such instances, a user may be required to explain their methodology and purpose for using the platform. LuckLake.com accounts are funded by users using local (non-custodial) or hosted (custodial) wallets. In addition to a Chainalysis screening, LuckLake.com monitors user activity within the LuckLake.com platform and any redemptions must meet LuckLake&rsquo;s and its third-party service provider&rsquo;s verification processes. LuckLake.com further prohibits peer-to-peer account transfers within the LuckLake.com platform infrastructure. Any attempts to circumvent this restriction will be treated as a red flag.</p>
    <p>Time Zone Monitoring. LuckLake.com has implemented time zone controls that detects the users device information and crosses it with restricted jurisdiction to understand if they are trying to use geo location software to hide the jurisdictions where they are connecting from.</p>
    <p>Products and Services Review. LuckLake.com will establish additional procedures to avoid facilitating user attempts to exploit the LuckLake.com platform. LuckLake.com has a robust set of user-facing terms that users attest to in order to utilise the LuckLake.com platform. LuckLake.com will establish certain additional safeguards to mitigate against the risk of such misuse. For instance, LuckLake.com may establish policies or procedures for limiting which user assets can be onboarded to the LuckLake.com platform. LuckLake.com lists the assets that are available for use on the LuckLake.com platform. LuckLake.com does not allow the use of anonymity enhancing technologies such as mixers, tumblers, or certain coins and tokens. Where LuckLake.com becomes aware that an anonymity enhancing technology is being used on the LuckLake.com platform, LuckLake.com will disallow such use.</p>
    <p>Vendor Management. LuckLake.com works with reputable third-party service providers as part of its compliance infrastructure. LuckLake.com will periodically assess the strength of its key third party service providers to determine if additional services are needed, the third-party service provider is not performing consistent with its contractual obligations, or if other remedial action is necessary for LuckLake.com to comply with this policy. LuckLake.com may request information from any third-party service provider as part of its vendor review process.</p>
    <p>Compliance Innovation</p>
    <p>6. Education and Training LuckLake, with the assistance of its legal counsel and under the oversight of its CCO, may provide employees AML, anti-terrorist financing and trade sanctions compliance training on a periodic basis, as deemed appropriate.</p>
    <p>7. Reporting LuckLake.com is obliged to report any unusual or suspicious transactions, in accordance with the National Ordinance. Customers that are identified as being on a sanctions list, linked to money laundering or terrorism financing or other criminal activities will be reported as suspicious activity to the regulator.</p>
    <p>7. Contacting Us If you have any questions, please contact us directly by email at support@LuckLake.com.</p>
  </div>
);
