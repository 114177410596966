import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';

import { activeCurrencyVariable } from '_apollo';

const createTurboGamesSession = gql`
  mutation createTurboGamesSession($activeCurrency: String!, $externalId: String!, $gameId: Int!) {
    createTurboGamesSession(activeCurrency: $activeCurrency, externalId: $externalId, gameId: $gameId) {
      iFrameUrl,
    },
  }
`;

export function useCreateTurboGamesSession ({ gameId, isTurboGame, externalId }) {
  const activeCurrency = useReactiveVar(activeCurrencyVariable);

  const [mutateTurboGamesSession, { data }] = useMutation(createTurboGamesSession, {
    variables: { activeCurrency, externalId, gameId },
  });

  useEffect(() => {
    if (activeCurrency && gameId && isTurboGame) {
      mutateTurboGamesSession({ variables: { activeCurrency } });
    }
  }, [activeCurrency, gameId, isTurboGame, mutateTurboGamesSession]);

  return {
    turboGamesUrl: data?.createTurboGamesSession?.iFrameUrl,
  };
}
