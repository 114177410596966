import { useReactiveVar } from '@apollo/client';
import { useState, useEffect, useCallback } from 'react';

import { userGpsVariable } from '_apollo';

export function useGeolocation () {
  const [error, setError] = useState(null);
  const [showLocationGpsModal, setShowLocationGpsModal] = useState(false);
  const locationGps = useReactiveVar(userGpsVariable);

  const getLocation = useCallback(() => {
    if (!navigator.geolocation) {
      setError('Geolocation is not supported by this browser. Please use a different browser.');
      setShowLocationGpsModal(true);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        userGpsVariable({
          altitude: position.coords.altitude || 0,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          radius: position.coords.accuracy, // Accuracy in meters
          speed: position.coords.speed || 0, // Speed in m/s
          timestamp: new Date(position.timestamp).toUTCString(),
        });
        setError(null);
        setShowLocationGpsModal(false);
      },
      (err) => {
        if (err.code === 1) { // Error => User denied Geolocation
          setError('Your location access is currently disabled. Please enable it in your browser settings and try again.');
        }
        setShowLocationGpsModal(true);
      },
      { enableHighAccuracy: false }
    );
  }, []);

  const validateState = useCallback(async () => {
    const result = await navigator.permissions.query({ name: 'geolocation' });
    if (result.state === 'granted') {
      getLocation();
    } else {
      setShowLocationGpsModal(true);
    }
  }, [getLocation]);

  useEffect(() => {
    validateState();
  }, [validateState]);

  return { locationGps, error, getLocation, showLocationGpsModal };
}
