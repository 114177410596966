export const monthOptions = [
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

export const countryOptions = [
  { value: 'US', label: 'United States' },
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AX', label: 'Åland Islands' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AG', label: 'Antigua and Barbuda' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas, The' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'VE', label: 'Bolivarian Republic of Venezuela' },
  { value: 'BO', label: 'Bolivia' },
  { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'BA', label: 'Bosnia and Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BR', label: 'Brazil' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'BN', label: 'Brunei' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'CV', label: 'Cabo Verde' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CC', label: 'Cocos (Keeling) Islands' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'CG', label: 'Congo' },
  { value: 'CD', label: 'Congo (DRC)' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CI', label: 'Côte d\'Ivoire' },
  { value: 'HR', label: 'Croatia' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'TL', label: 'Democratic Republic of Timor-Leste' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estonia' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FK', label: 'Falkland Islands (Islas Malvinas)' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FJ', label: 'Fiji Islands' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GF', label: 'French Guiana' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'TF', label: 'French Southern and Antarctic Lands' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia, The' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Germany' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greece' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard Island and McDonald Islands' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong SAR' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IR', label: 'Iran' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italy' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'SJ', label: 'Jan Mayen' },
  { value: 'JP', label: 'Japan' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KR', label: 'Korea' },
  { value: 'XK', label: 'Kosovo' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'LA', label: 'Laos' },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libya' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MO', label: 'Macao SAR' },
  { value: 'MK', label: 'Macedonia, Former Yugoslav Republic of' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'IM', label: 'Man, Isle of' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexico' },
  { value: 'FM', label: 'Micronesia' },
  { value: 'MD', label: 'Moldova' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'KP', label: 'North Korea' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'NO', label: 'Norway' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PW', label: 'Palau' },
  { value: 'PS', label: 'Palestinian Authority' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PN', label: 'Pitcairn Islands' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RE', label: 'Reunion' },
  { value: 'RO', label: 'Romania' },
  { value: 'RU', label: 'Russia' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'BL', label: 'Saint Barthélemy' },
  { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
  { value: 'KN', label: 'Saint Kitts and Nevis' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'MF', label: 'Saint Martin (French part)' },
  { value: 'PM', label: 'Saint Pierre and Miquelon' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'São Tomé and Príncipe' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SX', label: 'Sint Maarten (Dutch part)' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
  { value: 'SS', label: 'South Sudan' },
  { value: 'ES', label: 'Spain' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SJ', label: 'Svalbard' },
  { value: 'SZ', label: 'Swaziland' },
  { value: 'SE', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'SY', label: 'Syria' },
  { value: 'TW', label: 'Taiwan' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TZ', label: 'Tanzania' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks and Caicos Islands' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UM', label: 'U.S. Minor Outlying Islands' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VA', label: 'Vatican City' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'VI', label: 'Virgin Islands, U.S.' },
  { value: 'VG', label: 'Virgin Islands, British' },
  { value: 'WF', label: 'Wallis and Futuna' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' },
];

export const countryData = [
  { code: 'AF', mobileCode: '+93' },
  { code: 'AX', mobileCode: '+358-18' },
  { code: 'AL', mobileCode: '+355' },
  { code: 'DZ', mobileCode: '+213' },
  { code: 'AS', mobileCode: '+1-684' },
  { code: 'AD', mobileCode: '+376' },
  { code: 'AO', mobileCode: '+244' },
  { code: 'AI', mobileCode: '+1-264' },
  { code: 'AQ', mobileCode: '+' },
  { code: 'AG', mobileCode: '+1-268' },
  { code: 'AR', mobileCode: '+54' },
  { code: 'AM', mobileCode: '+374' },
  { code: 'AW', mobileCode: '+297' },
  { code: 'AU', mobileCode: '+61' },
  { code: 'AT', mobileCode: '+43' },
  { code: 'AZ', mobileCode: '+994' },
  { code: 'BS', mobileCode: '+1-242' },
  { code: 'BH', mobileCode: '+973' },
  { code: 'BD', mobileCode: '+880' },
  { code: 'BB', mobileCode: '+1-246' },
  { code: 'BY', mobileCode: '+375' },
  { code: 'BE', mobileCode: '+32' },
  { code: 'BZ', mobileCode: '+501' },
  { code: 'BJ', mobileCode: '+229' },
  { code: 'BM', mobileCode: '+1-441' },
  { code: 'BT', mobileCode: '+975' },
  { code: 'VE', mobileCode: '+58' },
  { code: 'BO', mobileCode: '+591' },
  { code: 'BQ', mobileCode: '+599' },
  { code: 'BA', mobileCode: '+387' },
  { code: 'BW', mobileCode: '+267' },
  { code: 'BV', mobileCode: '+' },
  { code: 'BR', mobileCode: '+55' },
  { code: 'IO', mobileCode: '+246' },
  { code: 'BN', mobileCode: '+673' },
  { code: 'BG', mobileCode: '+359' },
  { code: 'BF', mobileCode: '+226' },
  { code: 'BI', mobileCode: '+257' },
  { code: 'CV', mobileCode: '+238' },
  { code: 'KH', mobileCode: '+855' },
  { code: 'CM', mobileCode: '+237' },
  { code: 'CA', mobileCode: '+1' },
  { code: 'KY', mobileCode: '+1-345' },
  { code: 'CF', mobileCode: '+236' },
  { code: 'TD', mobileCode: '+235' },
  { code: 'CL', mobileCode: '+56' },
  { code: 'CN', mobileCode: '+86' },
  { code: 'CX', mobileCode: '+61' },
  { code: 'CC', mobileCode: '+61' },
  { code: 'CO', mobileCode: '+57' },
  { code: 'KM', mobileCode: '+269' },
  { code: 'CG', mobileCode: '+242' },
  { code: 'CD', mobileCode: '+243' },
  { code: 'CK', mobileCode: '+682' },
  { code: 'CR', mobileCode: '+506' },
  { code: 'CI', mobileCode: '+225' },
  { code: 'HR', mobileCode: '+385' },
  { code: 'CU', mobileCode: '+53' },
  { code: 'CW', mobileCode: '+599' },
  { code: 'CY', mobileCode: '+357' },
  { code: 'CZ', mobileCode: '+420' },
  { code: 'TL', mobileCode: '+670' },
  { code: 'DK', mobileCode: '+45' },
  { code: 'DJ', mobileCode: '+253' },
  { code: 'DM', mobileCode: '+1-767' },
  { code: 'DO', mobileCode: '+1-809 and 1-829' },
  { code: 'EC', mobileCode: '+593' },
  { code: 'EG', mobileCode: '+20' },
  { code: 'SV', mobileCode: '+503' },
  { code: 'GQ', mobileCode: '+240' },
  { code: 'ER', mobileCode: '+291' },
  { code: 'EE', mobileCode: '+372' },
  { code: 'ET', mobileCode: '+251' },
  { code: 'FK', mobileCode: '+500' },
  { code: 'FO', mobileCode: '+298' },
  { code: 'FJ', mobileCode: '+679' },
  { code: 'FI', mobileCode: '+358' },
  { code: 'FR', mobileCode: '+33' },
  { code: 'GF', mobileCode: '+594' },
  { code: 'PF', mobileCode: '+689' },
  { code: 'TF', mobileCode: '+' },
  { code: 'GA', mobileCode: '+241' },
  { code: 'GM', mobileCode: '+220' },
  { code: 'GE', mobileCode: '+995' },
  { code: 'DE', mobileCode: '+49' },
  { code: 'GH', mobileCode: '+233' },
  { code: 'GI', mobileCode: '+350' },
  { code: 'GR', mobileCode: '+30' },
  { code: 'GL', mobileCode: '+299' },
  { code: 'GD', mobileCode: '+1-473' },
  { code: 'GP', mobileCode: '+590' },
  { code: 'GU', mobileCode: '+1-671' },
  { code: 'GT', mobileCode: '+502' },
  { code: 'GG', mobileCode: '+44-1481' },
  { code: 'GN', mobileCode: '+224' },
  { code: 'GW', mobileCode: '+245' },
  { code: 'GY', mobileCode: '+592' },
  { code: 'HT', mobileCode: '+509' },
  { code: 'HM', mobileCode: '+ ' },
  { code: 'HN', mobileCode: '+504' },
  { code: 'HK', mobileCode: '+852' },
  { code: 'HU', mobileCode: '+36' },
  { code: 'IS', mobileCode: '+354' },
  { code: 'IN', mobileCode: '+91' },
  { code: 'ID', mobileCode: '+62' },
  { code: 'IR', mobileCode: '+98' },
  { code: 'IQ', mobileCode: '+964' },
  { code: 'IE', mobileCode: '+353' },
  { code: 'IL', mobileCode: '+972' },
  { code: 'IT', mobileCode: '+39' },
  { code: 'JM', mobileCode: '+1-876' },
  { code: 'SJ', mobileCode: '+47' },
  { code: 'JP', mobileCode: '+81' },
  { code: 'JE', mobileCode: '+44-1534' },
  { code: 'JO', mobileCode: '+962' },
  { code: 'KZ', mobileCode: '+7' },
  { code: 'KE', mobileCode: '+254' },
  { code: 'KI', mobileCode: '+686' },
  { code: 'KR', mobileCode: '+82' },
  { code: 'XK', mobileCode: '+' },
  { code: 'KW', mobileCode: '+965' },
  { code: 'KG', mobileCode: '+996' },
  { code: 'LA', mobileCode: '+856' },
  { code: 'LV', mobileCode: '+371' },
  { code: 'LB', mobileCode: '+961' },
  { code: 'LS', mobileCode: '+266' },
  { code: 'LR', mobileCode: '+231' },
  { code: 'LY', mobileCode: '+218' },
  { code: 'LI', mobileCode: '+423' },
  { code: 'LT', mobileCode: '+370' },
  { code: 'LU', mobileCode: '+352' },
  { code: 'MO', mobileCode: '+853' },
  { code: 'MK', mobileCode: '+389' },
  { code: 'MG', mobileCode: '+261' },
  { code: 'MW', mobileCode: '+265' },
  { code: 'MY', mobileCode: '+60' },
  { code: 'MV', mobileCode: '+960' },
  { code: 'ML', mobileCode: '+223' },
  { code: 'MT', mobileCode: '+356' },
  { code: 'IM', mobileCode: '+44-1624' },
  { code: 'MH', mobileCode: '+692' },
  { code: 'MQ', mobileCode: '+596' },
  { code: 'MR', mobileCode: '+222' },
  { code: 'MU', mobileCode: '+230' },
  { code: 'YT', mobileCode: '+262' },
  { code: 'MX', mobileCode: '+52' },
  { code: 'FM', mobileCode: '+691' },
  { code: 'MD', mobileCode: '+373' },
  { code: 'MC', mobileCode: '+377' },
  { code: 'MN', mobileCode: '+976' },
  { code: 'ME', mobileCode: '+382' },
  { code: 'MS', mobileCode: '+1-664' },
  { code: 'MA', mobileCode: '+212' },
  { code: 'MZ', mobileCode: '+258' },
  { code: 'MM', mobileCode: '+95' },
  { code: 'NA', mobileCode: '+264' },
  { code: 'NR', mobileCode: '+674' },
  { code: 'NP', mobileCode: '+977' },
  { code: 'NL', mobileCode: '+31' },
  { code: 'NC', mobileCode: '+687' },
  { code: 'NZ', mobileCode: '+64' },
  { code: 'NI', mobileCode: '+505' },
  { code: 'NE', mobileCode: '+227' },
  { code: 'NG', mobileCode: '+234' },
  { code: 'NU', mobileCode: '+683' },
  { code: 'NF', mobileCode: '+672' },
  { code: 'KP', mobileCode: '+850' },
  { code: 'MP', mobileCode: '+1-670' },
  { code: 'NO', mobileCode: '+47' },
  { code: 'OM', mobileCode: '+968' },
  { code: 'PK', mobileCode: '+92' },
  { code: 'PW', mobileCode: '+680' },
  { code: 'PS', mobileCode: '+970' },
  { code: 'PA', mobileCode: '+507' },
  { code: 'PG', mobileCode: '+675' },
  { code: 'PY', mobileCode: '+595' },
  { code: 'PE', mobileCode: '+51' },
  { code: 'PH', mobileCode: '+63' },
  { code: 'PN', mobileCode: '+870' },
  { code: 'PL', mobileCode: '+48' },
  { code: 'PT', mobileCode: '+351' },
  { code: 'PR', mobileCode: '+1-787 and 1-939' },
  { code: 'QA', mobileCode: '+974' },
  { code: 'RE', mobileCode: '+262' },
  { code: 'RO', mobileCode: '+40' },
  { code: 'RU', mobileCode: '+7' },
  { code: 'RW', mobileCode: '+250' },
  { code: 'BL', mobileCode: '+590' },
  { code: 'SH', mobileCode: '+290' },
  { code: 'KN', mobileCode: '+1-869' },
  { code: 'LC', mobileCode: '+1-758' },
  { code: 'MF', mobileCode: '+590' },
  { code: 'PM', mobileCode: '+508' },
  { code: 'VC', mobileCode: '+1-784' },
  { code: 'WS', mobileCode: '+685' },
  { code: 'SM', mobileCode: '+378' },
  { code: 'ST', mobileCode: '+239' },
  { code: 'SA', mobileCode: '+966' },
  { code: 'SN', mobileCode: '+221' },
  { code: 'RS', mobileCode: '+381' },
  { code: 'SC', mobileCode: '+248' },
  { code: 'SL', mobileCode: '+232' },
  { code: 'SG', mobileCode: '+65' },
  { code: 'SX', mobileCode: '+599' },
  { code: 'SK', mobileCode: '+421' },
  { code: 'SI', mobileCode: '+386' },
  { code: 'SB', mobileCode: '+677' },
  { code: 'SO', mobileCode: '+252' },
  { code: 'ZA', mobileCode: '+27' },
  { code: 'GS', mobileCode: '+' },
  { code: 'SS', mobileCode: '+211' },
  { code: 'ES', mobileCode: '+34' },
  { code: 'LK', mobileCode: '+94' },
  { code: 'SD', mobileCode: '+249' },
  { code: 'SR', mobileCode: '+597' },
  { code: 'SJ', mobileCode: '+47' },
  { code: 'SZ', mobileCode: '+268' },
  { code: 'SE', mobileCode: '+46' },
  { code: 'CH', mobileCode: '+41' },
  { code: 'SY', mobileCode: '+963' },
  { code: 'TW', mobileCode: '+886' },
  { code: 'TJ', mobileCode: '+992' },
  { code: 'TZ', mobileCode: '+255' },
  { code: 'TH', mobileCode: '+66' },
  { code: 'TG', mobileCode: '+228' },
  { code: 'TK', mobileCode: '+690' },
  { code: 'TO', mobileCode: '+676' },
  { code: 'TT', mobileCode: '+1-868' },
  { code: 'TN', mobileCode: '+216' },
  { code: 'TR', mobileCode: '+90' },
  { code: 'TM', mobileCode: '+993' },
  { code: 'TC', mobileCode: '+1-649' },
  { code: 'TV', mobileCode: '+688' },
  { code: 'UM', mobileCode: '+1' },
  { code: 'UG', mobileCode: '+256' },
  { code: 'UA', mobileCode: '+380' },
  { code: 'AE', mobileCode: '+971' },
  { code: 'GB', mobileCode: '+44' },
  { code: 'US', mobileCode: '+1' },
  { code: 'UY', mobileCode: '+598' },
  { code: 'UZ', mobileCode: '+998' },
  { code: 'VU', mobileCode: '+678' },
  { code: 'VA', mobileCode: '+379' },
  { code: 'VN', mobileCode: '+84' },
  { code: 'VI', mobileCode: '+1-340' },
  { code: 'VG', mobileCode: '+1-284' },
  { code: 'WF', mobileCode: '+681' },
  { code: 'YE', mobileCode: '+967' },
  { code: 'ZM', mobileCode: '+260' },
  { code: 'ZW', mobileCode: '+263' },
];