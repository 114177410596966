import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState, useRef } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { showPurchaseModal } from '_apollo';
import { colors, mainContentMaxWidth, mobileBreakpoint } from '_constants';
import { useGeolocation } from '_hooks';
import { useAuthentication } from '_hooks';
import { IntercomProvider } from '_providers';

import {
  AlertController,
  BonusModal,
  LocationGpsModal,
  LongRegistrationFormModal,
  NavigationBar,
  NavigationMenu,
  NavigationMenuMobile,
  PurchaseModal,
  RedemptionModal,
  ShortRegistrationFormModal,
} from './components';
import { useLayoutQuery } from './useLayoutQuery';

const Container = styled.div`
  display: flex;
  height: 100dvh;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  position: relative;
`;

const MainContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
  scrollbar-color: ${colors.backgroundL30} ${colors.backgroundL12};
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  flex: 1;

  @media (min-width: ${mobileBreakpoint}px) {
    max-width: ${mainContentMaxWidth}px;
    padding: 25px;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 50px 25px;
  gap: 15px;
`;

const FooterLink = styled(NavLink)`
  font-size: 10px;
`;

export const Layout = () => {
  const [shortRegistrationFormData, setShortRegistrationFormData] = useState({});
  const [showBonusModal, setShowBonusModal] = useState(false);
  const [showLongRegistrationFormModal, setShowLongRegistrationFormModal] = useState(false);
  const [showRedemptionModal, setShowRedemptionModal] = useState(false);
  const [showShortRegistrationFormModal, setShowShortRegistrationFormModal] = useState(false);
  const mainContainerRef = useRef();

  const { isAuthenticated } = useAuthentication();
  const { error: errorLocationGPS, getLocation, showLocationGpsModal } = useGeolocation();
  const { isEligible, loading, user } = useLayoutQuery(isAuthenticated);
  const location = useLocation();

  const { isPurchaseModalVisible } = useReactiveVar(showPurchaseModal);

  useEffect(() => {
    if (isAuthenticated && isEligible) setShowBonusModal(true);
  }, [isAuthenticated, isEligible]);

  useEffect(() => {
    if (isAuthenticated && !user.id && !loading && !showLongRegistrationFormModal) {
      setShowShortRegistrationFormModal(true);
    }
  }, [isAuthenticated, loading, showLongRegistrationFormModal, user]);

  useEffect(() => {
    if (mainContainerRef.current) {
      mainContainerRef.current.scrollTop = 0;
    }
  }, [location.key]);

  return (
    <>
      <Container>
        <Left>
          <NavigationMenu />
        </Left>
        <Right>
          <NavigationBar setShowRedemptionModal={setShowRedemptionModal} />
          <MainContainer ref={mainContainerRef}>
            <MainContent>
              <Outlet />
            </MainContent>
            <Footer>
              <FooterLink to="/policies/termsAndConditions">Terms & Conditions</FooterLink>
              <FooterLink to="/policies/privacyPolicy">Privacy Policy</FooterLink>
              <FooterLink to="/policies/sweepstakesRules">Sweepstakes Rules</FooterLink>
              <FooterLink to="/policies/responsibleGameplayPolicy">Responsible Gameplay Policy</FooterLink>
              <FooterLink to="/policies/cookiePolicy">Cookie Policy</FooterLink>
              <FooterLink to="/policies/accessibilityStatement">Accessibility Statement</FooterLink>
              <FooterLink to="/policies/kycAmlPolicy">AML/ATF/KYC Policies</FooterLink>
            </Footer>
          </MainContainer>
          <NavigationMenuMobile />
        </Right>
      </Container>
      <IntercomProvider />
      <AlertController />
      {showBonusModal && <BonusModal setShowBonusModal={setShowBonusModal} />}
      {showShortRegistrationFormModal && <ShortRegistrationFormModal
        setShortRegistrationFormData={setShortRegistrationFormData}
        setShowLongRegistrationFormModal={setShowLongRegistrationFormModal}
        setShowShortRegistrationFormModal={setShowShortRegistrationFormModal}
      />}
      {showLongRegistrationFormModal && <LongRegistrationFormModal
        setShowLongRegistrationFormModal={setShowLongRegistrationFormModal}
        shortRegistrationFormData={shortRegistrationFormData}
      />}
      {showLocationGpsModal && isAuthenticated && <LocationGpsModal getLocation={getLocation} errorLocationGPS={errorLocationGPS} />}
      {isPurchaseModalVisible && <PurchaseModal />}
      {showRedemptionModal && <RedemptionModal
        setShowRedemptionModal={setShowRedemptionModal}
      />}
    </>
  );
};
