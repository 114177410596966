import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { mobileBreakpoint } from '_constants';
import { useLaunchGame } from '_scenes/game/useLaunchGame';
import { displayAlert } from '_utilities';

const StyledIframe = styled.iframe`
  height: 65dvh;
  width: 100%;

  @media (max-width: ${mobileBreakpoint}px) {
    height: 100%;
  }
`;

export const Sportsbook = () => {
  const navigate = useNavigate();
  const [launchGame, {
    iFrameUrl,
    error: launchGameError,
  }] = useLaunchGame({
    providerName: 'turboSportsbook',
  });

  useEffect(() => {
    launchGame();
  }, [launchGame]);

  useEffect(() => {
    if (launchGameError?.code) {
      displayAlert({ message: launchGameError.message, type: 'error' });
      navigate('/');
    }
  }, [launchGameError, navigate]);

  return <StyledIframe src={iFrameUrl}/>;
};
