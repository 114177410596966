import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { boxShadow, colors, transitionDuration } from '_constants';

const CategoriesContainer = styled.div`
  display: flex;
  background-color: ${colors.backgroundL14};
  box-shadow: ${boxShadow};
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5vh;
`;

const Category = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  user-select: none;
  transition: color ${transitionDuration}s;
  margin-left: 15px;
  margin-right: 15px;
  white-space: nowrap;
  padding: 5px 0;
  color: ${({ isActive }) => (isActive ? colors.primary : colors.white)};
  border-bottom: 2px solid ${({ isActive }) => (isActive ? colors.primary : 'transparent')};
  flex: 1;
  font-size: 1.5vh;
`;

const CategoryLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  user-select: none;
  transition: color ${transitionDuration}s;
  margin-left: 15px;
  margin-right: 15px;
  white-space: nowrap;
  padding: 5px 0;
  text-decoration: none;
  color: ${colors.white};
  flex: 1;
  font-size: 1.5vh;
`;

export const TerminalGameCategories = ({ gamesByCategory, selectedFilter, setSelectedFilter }) => {
  const handleCategoryClick = (categoryName) => () => {
    if (selectedFilter === categoryName) setSelectedFilter('all');
    else setSelectedFilter(categoryName);
  };

  return (
    <CategoriesContainer>
      <Category
        isActive={selectedFilter === 'all'}
        onClick={() => setSelectedFilter('all')}
      >All</Category>
      {gamesByCategory.map((category) => (
        <Category
          key={category.category}
          isActive={selectedFilter === category.category}
          onClick={handleCategoryClick(category.category)}
        >
          {category.title}
        </Category>
      ))}
      <CategoryLink to="/terminal/game/turboSportsbook">Sportsbook</CategoryLink>
    </CategoriesContainer>
  );
};
