import CasinoFriendsFavicon from '_images/casinoFriendsFavicon.svg?url';
import HypeGamesFavicon from '_images/hypeGamesFavicon.png';
import CasinoFriendsLogo from '_images/logos/casinoFriends.svg';
import HypeGamesLogo from '_images/logos/hypeGames.svg';
import LuckLakeLogo from '_images/logos/luckLake.svg';
import RadPokerLogo from '_images/logos/radPoker.svg';
import LuckLakeFavicon from '_images/luckLakeFavicon.png';
import RadPokerFavicon from '_images/radPokerFavicon.svg?url';

const activeClient = process.env.ENVIRONMENT === 'local' ? process.env.CLIENT
  : window.location.hostname.includes('hypegames.us') ? 'hypeGames'
    : window.location.hostname.includes('lucklake.com') ? 'luckLake'
      : window.location.hostname.includes('radpoker.com') ? 'radPoker'
        : window.location.hostname.includes('casinofriends.com') ? 'casinoFriends'
          : '';

const auth0ClientId = activeClient === 'hypeGames' ? process.env.AUTH0_HYPE_GAMES_CLIENT_ID
  : activeClient === 'luckLake' ? process.env.AUTH0_LUCK_LAKE_CLIENT_ID
    : activeClient === 'radPoker' ? process.env.AUTH0_RADPOKER_CLIENT_ID
      : activeClient === 'casinoFriends' ? process.env.AUTH0_CASINO_FRIENDS_CLIENT_ID
        : null;

const auth0RedirectUri = activeClient === 'hypeGames' ? process.env.AUTH0_HYPE_GAMES_REDIRECT_URI
  : activeClient === 'luckLake' ? process.env.AUTH0_LUCK_LAKE_REDIRECT_URI
    : activeClient === 'radPoker' ? process.env.AUTH0_RADPOKER_REDIRECT_URI
      : activeClient === 'casinoFriends' ? process.env.AUTH0_CASINO_FRIENDS_REDIRECT_URI
        : null;

const clientName = activeClient === 'hypeGames' ? 'Hype Games'
  : activeClient === 'luckLake' ? 'Luck Lake'
    : activeClient === 'radPoker' ? 'RadPoker'
      : activeClient === 'casinoFriends' ? 'Casino Friends'
        : null;

const enableGlowAnimation = activeClient === 'hypeGames';

const favicon = activeClient === 'hypeGames' ? HypeGamesFavicon
  : activeClient === 'luckLake' ? LuckLakeFavicon
    : activeClient === 'radPoker' ? RadPokerFavicon
      : activeClient === 'casinoFriends' ? CasinoFriendsFavicon
        : null;

const logo = activeClient === 'hypeGames' ? HypeGamesLogo
  : activeClient === 'luckLake' ? LuckLakeLogo
    : activeClient === 'radPoker' ? RadPokerLogo
      : activeClient === 'casinoFriends' ? CasinoFriendsLogo
        : null;

window.activeClient = {
  auth0ClientId,
  auth0RedirectUri,
  clientName,
  code: activeClient, // TODO: remove this because it allows checking for active client code outside this file, need to figure out colors first
  enableGlowAnimation,
  favicon,
  logo,
};
