import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { activeRequestsVariable } from '_apollo';
import { ClientLogo } from '_components';
import { colors } from '_constants';

const transitionDuration = 800;

const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.backgroundL12};
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity ${transitionDuration}ms;
  
  ${({ fadeOut }) => fadeOut && `
    opacity: 0;
  `}
`;

const glowAnimation = keyframes`
  from {
    box-shadow: 0 0 50px -50px ${colors.primaryL35};
  }
  to {
    box-shadow: 0 0 50px 50px ${colors.primaryL35};
  }
`;

const GlowContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
`;

const Glow = styled.div`
  width: 100px;
  border-radius: 100%;
  ${window.activeClient.enableGlowAnimation
    ? css`animation: ${glowAnimation} 0.75s infinite alternate;`
    : ''}
`;

export const LoadingScreen = () => {

  const [showLoading, setShowLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [minLoadingTimePassed, setMinLoadingTimePassed] = useState(false);

  const activeRequests = useReactiveVar(activeRequestsVariable);

  useEffect(() => {
    const timeouts = [
      setTimeout(() => setMinLoadingTimePassed(true), 1000), // Show loading for at least 1 second
      setTimeout(() => setFadeOut(true), 10000), // Hide loading after 10 seconds
    ];
    return () => timeouts.forEach(clearTimeout);
  }, []);

  useEffect(() => {
    if (activeRequests === 0 && minLoadingTimePassed && !fadeOut) {
      setFadeOut(true);
    }
  }, [activeRequests, minLoadingTimePassed, fadeOut]);

  useEffect(() => {
    if (fadeOut) {
      // After the fade-out transition, remove the loading screen.
      const fadeOutTimer = setTimeout(() => {
        setShowLoading(false);
      }, transitionDuration);
      return () => clearTimeout(fadeOutTimer);
    }
  }, [fadeOut]);

  return showLoading && (
    <LoadingContainer fadeOut={fadeOut}>
      <ClientLogo width={200} />
      <GlowContainer>
        <Glow />
      </GlowContainer>
    </LoadingContainer>
  );
};
