import { gql, useQuery } from '@apollo/client';

export function useEvenbetPokerQuery () {
  const { data, loading } = useQuery(gql`
    query EvenbetPokerQuery {
      evenbetLobbyUrl
    }
  `);

  return {
    evenbetLobbyUrl: data?.evenbetLobbyUrl || '',
    loading,
  };
}
