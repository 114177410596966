import { gql, useQuery } from '@apollo/client';

const terminalGameQuery = gql`
  query terminalGameQuery {
    wallets {
      id
      playableBalance
      currency {
        code
        id
        name
        shortName
      }
    }
  }
`;

export function useTerminalGameQuery () {
  const { data, refetch } = useQuery(terminalGameQuery);

  const wallets = data?.wallets || [];

  return {
    refetch,
    wallets,
  };
}
