import styled, { keyframes } from 'styled-components';

import { colors } from '_constants';

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const loadingSpinnerSize = 80;

export const LoadingSpinner = styled.div`
  width: ${({ size = loadingSpinnerSize }) => `${size}px`};
  height: ${({ size = loadingSpinnerSize }) => `${size}px`};
  border: ${({ size = loadingSpinnerSize, color = colors.white }) => `${(size / 10) <= 3 ? 3 : (size / 10)}px solid ${color}`};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${rotation} 1s linear infinite;
`;
