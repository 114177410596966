import { gql, useQuery } from '@apollo/client';

const navigationMenuQuery = gql`
  query navigationMenuQuery {
    gamesByCategory {
      name,
    },
  }
`;

export function useNavigationMenuQuery () {
  const { data } = useQuery(navigationMenuQuery);

  return {
    categories: data?.gamesByCategory || [],
  };
}
