import React from 'react';
import styled from 'styled-components';

import { Modal, Button } from '_components';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  font-weight: 400;
  gap: 15px;
`;

export const LocationGpsModal = ({ getLocation, errorLocationGPS }) => {

  const locationErrorMessage = 'Allow your location. This is required to verify that you are in a legal jurisdiction.';

  return (
    <Modal
      isDismissible={false}
      title="Location validation"
      width="40%"
    >
      <ModalContent>
        {errorLocationGPS ? errorLocationGPS : locationErrorMessage}
        <Button onClick={() => getLocation()}>Allow Location</Button>
      </ModalContent>
    </Modal>
  );
};
