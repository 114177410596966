import React from 'react';

export const AccessibilityStatement = () => (
  <div>
    <p>Disclaimer Rad Software LLC operates and manages the online social sweepstakes casino 'LuckLake.com.' All legal, operational, and policy-related matters for this casino are overseen by Rad Software LLC, with 'LuckLake.com' being an approved DBA of Rad Software LLC.</p>
    <p>Introduction At LuckLake.com, we are committed to ensuring that our online social sweepstakes casino website is accessible to all users, including individuals with disabilities. We strive to meet the accessibility standards set forth in the Americans with Disabilities Act (ADA) and Section 508 of the Rehabilitation Act, as well as other applicable state and federal accessibility requirements.</p>
    <p>Accessibility Features We aim to make our website accessible to a broad audience by following best practices in web accessibility. Our website includes the following features: Text Alternatives: We provide text alternatives for images and other non-text content to ensure accessibility for screen readers. Keyboard Navigation: All website functions are navigable using a keyboard. Users can access key content and features without relying on a mouse. Contrast and Color: We have ensured sufficient contrast ratios between text and background colors to enhance readability for users with visual impairments. Adjustable Font Sizes: Our website allows users to adjust font sizes for better readability. Compatibility: Our platform is tested for compatibility with popular assistive tools, including screen readers and voice recognition software.</p>
    <p>LuckLake.com is compatible with various assistive technologies, including but not limited to: Screen readers such as JAWS and NVDA. Screen magnifiers. Voice recognition software. We continuously strive to test and improve the compatibility of our website with these and other assistive technologies.</p>
    <p>Feedback and Support We are committed to improving the user experience and accessibility of our site. If you encounter any barriers while using our website, please contact our support team at support@LuckLake.com. We welcome your feedback and will work diligently to address any concerns within 60 days of being notified.</p>
    <p>Ongoing Efforts We regularly test our website for compliance with accessibility standards and continually make improvements based on user feedback and technical advancements. We recognize that accessibility is an ongoing effort, and we remain committed to ensuring all users can enjoy a seamless experience on our platform.</p>
    <p>Thank you for choosing LuckLake.com. We appreciate your understanding and look forward to providing you with an inclusive and enjoyable experience. Contacting Us If you have specific questions, please contact us directly by email at support@LuckLake.com.</p>
  </div>
);
