import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const pairTerminalSession = gql`
  mutation pairTerminalSession ($pairingCode: String!) {
    pairTerminalSession (pairingCode: $pairingCode) {
      token,
    },
  }
`;

export function usePairTerminalSessionMutation () {
  const [mutation, { data, error, loading }] = useMutation(pairTerminalSession);

  return [
    useCallback((variables) => mutation({ variables }), [mutation]),
    { ...data?.pairTerminalSession || {}, error, loading },
  ];
}
