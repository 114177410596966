import { useReactiveVar } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';

import { terminalAccessTokenVariable } from '_apollo/reactiveVariables';

export const useAuthentication = () => {
  const auth0 = useAuth0();
  const terminalAccessToken = useReactiveVar(terminalAccessTokenVariable);
  return terminalAccessToken ? {
    isAuthenticated: true,
    logout: () => terminalAccessTokenVariable(null),
  } : auth0;
};
