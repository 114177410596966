import { gql, useQuery } from '@apollo/client';

const profileBalanceQuery = gql`
  query profileBalanceQuery {
    bonusEligibility {
      id,
      isEligible,
      nextEligibleTimestamp,
    },
    wallets {
      id,
      playableBalance,
      redeemableBalance,
      currency {
        id,
        code,
        name,
      },
    },
  }
`;

export function useProfileBalanceQuery () {
  const { data, loading } = useQuery(profileBalanceQuery);
  const bonusEligibility = data?.bonusEligibility || {};
  const isEligible = bonusEligibility.isEligible;
  const nextEligibleTimestamp = bonusEligibility.nextEligibleTimestamp;
  const wallets = data?.wallets || [];

  return {
    isEligible,
    loading,
    nextEligibleTimestamp,
    wallets,
  };
}
