import { gql, useSubscription } from '@apollo/client';

const onPairingUpdate = gql`
  subscription onPairingUpdate($pairingCode: String!) {
    onPairingUpdate(pairingCode: $pairingCode) {
      token,
    },
  }
`;

export function useOnPairingUpdateSubscription ({ pairingCode }) {
  const { data, error, loading, restart } = useSubscription(onPairingUpdate, {
    variables: { pairingCode },
    skip: !pairingCode,
  });

  return { data, error, loading, restart };
}
