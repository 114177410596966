import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';

import { activeCurrencyVariable } from '_apollo';

const launchGameMutation = gql`
  mutation launchGame(
      $activeCurrency: String!
      $externalId: String
      $gameId: Int
      $language: String
      $providerName: ProviderName!
      $returnUrl: String) {
    launchGame(
      activeCurrency: $activeCurrency
      externalId: $externalId
      gameId: $gameId
      language: $language
      providerName: $providerName
      returnUrl: $returnUrl
    ) {
      iFrameUrl
      error {
        code
        message
      }
    }
  }
`;

export function useLaunchGame ({ gameId, language, returnUrl, providerName, externalId }) {
  const activeCurrency = useReactiveVar(activeCurrencyVariable);

  const [launchGame, { data, error: mutationError }] = useMutation(launchGameMutation, {
    variables: {
      activeCurrency,
      externalId,
      gameId,
      language,
      providerName,
      returnUrl,
    },
  });

  return [
    useCallback((variables) => activeCurrency && launchGame({ variables }), [activeCurrency, launchGame]),
    { ...data?.launchGame || {}, mutationError },
  ];
}
