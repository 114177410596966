import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { PopularGames } from '_components';
import { borderRadius, boxShadow, transitionDuration } from '_constants';
import { toCamelCase } from '_utilities';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

const ContainerGames = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Row = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
  width: 100%;
  gap: 10px;
  font-size: 0;
`;

const Image = styled.img`
  border-radius: ${borderRadius * 2}px;
  object-fit: cover;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  width: 100%;
  height: 100%;
  transition: translate ${transitionDuration};
  border-radius: ${borderRadius * 2}px;
  box-shadow: ${boxShadow};

  &:hover {
    transform: translateY(-5px);
  }
`;

const Title = styled.div`
  margin-top: 25px;
  font-weight: 400;
  text-align: center;
`;

export const GameList = ({ games }) => {
  return (
    <Container>
      <ContainerGames>
        {!games?.length && (
          <Title>
            We haven&apos;t found any results, but you might be interested in these games
          </Title>
        )}

        <Row>
          {games?.map((game) => (
            <StyledLink
              key={game.id}
              to={`/game/${toCamelCase(game?.provider?.name)}/${game.externalId}/${game.id}`}
            >
              <Image
                src={game.imageUrl}
                loading="lazy"
                alt={`${game?.provider?.name} ${game.name}`}
              />
            </StyledLink>
          ))}
        </Row>

        <PopularGames />
      </ContainerGames>
    </Container>
  );
};
