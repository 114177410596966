import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import styled from 'styled-components';

import { Button, FormInput, Modal, FormDropdown } from '_components';
import { colors, monthOptions } from '_constants';
import { useVerifyUserMutation } from '_hooks';
import { displayAlert } from '_utilities';

const Form = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

const Section = styled.div``;

const SectionTitle = styled.div`
  font-size: 14px;
  line-height: 1;
  margin-bottom: 5px;
  font-weight: 500;
  color: ${colors.backgroundL80};
`;

const SubmitButton = styled(Button)`
  color: black;
  font-weight: 600;
  font-size: 14px;
  background-color: ${({ disabled }) => disabled ? colors.backgroundL60 : colors.primary};
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const FooterNote = styled.div`
  color: ${colors.white};
  font-size: 12px;
  font-weight: 500;
  align-self: center;
`;

const ValidatingBackground = styled.div`
  position: absolute;
  background-color: rgba(0,0,0,0.35);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShortRegistrationFormModal = ({
  setShortRegistrationFormData,
  setShowLongRegistrationFormModal,
  setShowShortRegistrationFormModal,
}) => {
  const { logout } = useAuth0();
  const [verifyUser, { loading }] = useVerifyUserMutation();
  const form = useForm();

  const onSubmit = async (data) => {
    const body = {
      dob: `${data.month}/${data.day}/${data.year}`,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
    };

    const response = await verifyUser({ input: body });

    // setIsUserGambleIdVerified(true)
    if (response.data.verifyUser.isVerified) {
      setShowShortRegistrationFormModal(false);
      displayAlert({ message: 'Account verified!' });
    } else {
      setShortRegistrationFormData(data);
      setShowShortRegistrationFormModal(false);
      setShowLongRegistrationFormModal(true);
    }
  };

  const closeModal = () => {
    logout();
    setShowShortRegistrationFormModal(false);
  };

  return (
    <Modal
      closeModal={closeModal}
      title="Account Verification"
    >
      <FormProvider {...form}>
        {loading && <ValidatingBackground>Validating...</ValidatingBackground>}
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <Inputs>
            <InputGroup>
              <FormInput disabled={loading} name="email" placeholder="Email*" rules={{ required: true }} />
            </InputGroup>
            <InputGroup>
              <FormInput disabled={loading} name="firstName" placeholder="First Name*" rules={{ required: true, maxLength: 25 }} />
              <FormInput disabled={loading} name="lastName" placeholder="Last Name*" rules={{ required: true, maxLength: 25 }} />
            </InputGroup>
            <Section>
              <SectionTitle>Date Of Birth</SectionTitle>
              <InputGroup>
                <FormDropdown disabled={loading} isModalDropdown name="month" options={monthOptions} placeholder="Month*" rules={{ required: true }} />
                <FormInput disabled={loading} name="day" placeholder="Day*" rules={{ required: true, min: 1, max: 31 }} type="number" />
                <FormInput disabled={loading} name="year" placeholder="Year*" rules={{ required: true }} type="number" />
              </InputGroup>
            </Section>
          </Inputs>
          <Footer>
            <SubmitButton disabled={loading} type="submit" >{loading ? 'Verifying...' : 'Complete'}</SubmitButton>
            <FooterNote>We use this information to confirm that you meet the age requirement.</FooterNote>
          </Footer>
        </Form>
      </FormProvider>
    </Modal>
  );
};
