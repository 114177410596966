import { gql, useQuery } from '@apollo/client';

const casinoQuery = gql`
  query casinoQuery {
    gamesByCategory {
      name
      games {
        externalId
        id
        imageUrl
        name
        provider {
          id
          name
        }
      }
    }
    providers {
      id
      name
    }
  }
`;

export function useCasinoQuery () {
  const { data, loading } = useQuery(casinoQuery);

  return {
    gamesByCategory: data?.gamesByCategory || [],
    loading,
    providers: data?.providers || [],
  };
}
