import { Auth0Provider } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';

import { ApolloAppProvider } from '_apollo';
import { Layout, LoadingScreen, ProtectedRoute } from '_components';
import '_fonts/fonts.css';
import {
  Casino,
  EvenbetPoker,
  Game,
  PairTerminalSession,
  Policies,
  Profile,
  Sportsbook,
  Terminal,
} from '_scenes';
import { shouldForwardProp } from '_utilities';

import { GlobalStyles } from './globalStyles';

const updateMetaTags = () => {
  if (window.activeClient) {
    document.querySelector('meta[property="og:title"]')?.setAttribute('content', window.activeClient.clientName);
    document.querySelector('meta[property="og:url"]')?.setAttribute('content', window.activeClient.auth0RedirectUri);
  }
};

export const App = () => {

  useEffect(() => {
    const faviconLinks = document.querySelectorAll('link[rel*=\'icon\']');
    faviconLinks.forEach((faviconLink) => {
      faviconLink.href = window.activeClient.favicon;
    });

    document.title = window.activeClient.clientName;
    updateMetaTags();
  }, []);

  return (
    <Auth0Provider
      cacheLocation="localstorage"
      clientId={window.activeClient.auth0ClientId}
      domain={process.env.AUTH0_DOMAIN}
    >
      <ApolloAppProvider>
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <GlobalStyles />
          <Router>
            <Routes>
              {/* Routes that require Layout */}
              <Route element={<Layout />}>
                <Route path="/" element={<Navigate to="/casino" replace />} />
                <Route path="/casino" element={<Casino />} />
                <Route path="/game/:providerName/:externalId/:gameId" element={<ProtectedRoute element={<Game />} />} />
                <Route path="/pairTerminalSession" element={<PairTerminalSession />} />
                <Route path="/poker" element={<ProtectedRoute element={<EvenbetPoker />} />} />
                <Route path="/policies/*" element={<Policies />} />
                <Route path="/profile" element={<ProtectedRoute element={<Profile />}/> } />
                <Route path="/sportsbook" element={<ProtectedRoute element={<Sportsbook/>}/>} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Route>

              {/* Routes that do not require Layout */}
              <Route path="/terminal/*" element={<Terminal />} />
            </Routes>
          </Router>
          <LoadingScreen />
        </StyleSheetManager>
      </ApolloAppProvider>
    </Auth0Provider >
  );
};
