import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { showPurchaseModal } from '_apollo';
import { Modal } from '_components';
import { displayAlert } from '_utilities';

import { PurchaseModalStepOne, PurchaseModalStepTwo } from './components';
import { useCreatePaymentSessionMutation } from './useCreatePaymentSessionMutation';
import { useFinalizePaymentSessionMutation } from './useFinalizePaymentSessionMutation';
import { useRemoveSavedPaymentMethodMutation } from './useRemoveSavedPaymentMethodMutation';
import { useUpdateSavedPaymentMethods } from './useUpdateSavedPaymentMethods';

const purchaseStates = Object.freeze({
  failed: 'The purchase couldn\'t be processed. Please try again later.',
  pending: 'The purchase is being processed.',
  success: 'Successful purchase.',
});

const createPaymentSessionErrorMessages = Object.freeze({
  401: 'Account verification needed.',
  403: 'Coin purchase unavailable.', // User is ineligible to create a payment session.
  404: 'Coin purchase unavailable.', // User not found or session not found
});

const finalizePaymentErrorMessages = Object.freeze({
  404: 'Coin purchase unavailable.',
  500: purchaseStates['failed'],
});

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const PurchaseModal = () => {

  const [showPurchaseModalStepOne, setShowPurchaseModalStepOne] = useState(true);
  const [showPurchaseModalStepTwo, setShowPurchaseModalStepTwo] = useState(false);
  const [selectedSavedPaymentMethod, setSelectedSavedPaymentMethod] = useState();
  const { isAuthenticated } = useAuth0();
  const form = useForm();
  const [createPaymentSession, {
    error: createPaymentSessionError,
    loading: createPaymentSessionLoading,
    savedPaymentMethods,
    sessionId,
    transactionId,
  }] = useCreatePaymentSessionMutation({ isDeposit: true });
  const [finalizePaymentSession, { error: finalizePaymentError, loading: finalizePaymentLoading }] = useFinalizePaymentSessionMutation({ isDeposit: true });
  const [updateSavedPaymentMethods, { loading: updateSavedPaymentMethodsLoading }] = useUpdateSavedPaymentMethods({ handlePaymentMethodError });
  const [removeSavedPaymentMethod] = useRemoveSavedPaymentMethodMutation();

  useEffect(() => {
    if (!isAuthenticated) return;
    createPaymentSession();
  }, [createPaymentSession, isAuthenticated]);

  useEffect(() => {
    if (createPaymentSessionError?.code) {
      displayAlert({ message: createPaymentSessionErrorMessages[createPaymentSessionError?.code] || createPaymentSessionErrorMessages['404'], type: 'error' });
      showPurchaseModal({ isPurchaseModalVisible: false, amount: 10000 });
    }
  }, [createPaymentSessionError ]);

  useEffect(() => {
    if (finalizePaymentError?.code) {
      displayAlert({ message: finalizePaymentErrorMessages[finalizePaymentError?.code] || finalizePaymentErrorMessages['404'], type: 'error' });
      showPurchaseModal({ isPurchaseModalVisible: false, amount: 10000 });
    }
  }, [finalizePaymentError ]);

  const handleSubmit = async (formData) => {
    if (!sessionId || !transactionId) return;

    const { token, type } = selectedSavedPaymentMethod
      ? selectedSavedPaymentMethod
      : await updateSavedPaymentMethods({
        formData,
        isRemoving: false,
        sessionId,
        type: 'CC',
      });

    if (!token || !type) return;

    displayAlert({ message: 'Purchase processing...', type: 'success' });

    const response = await finalizePaymentSession({
      amount: formData.goldCoinsAmount / 1000,
      cvv: formData.cvv,
      isDeposit: true,
      sessionId,
      token,
      transactionId,
      type,
    });

    const { data: { finalizePaymentSession: { paymentState } } } = response;

    if (!paymentState) return;

    displayAlert({ message: purchaseStates[paymentState], type: paymentState === 'pending' ? 'warning' : paymentState });

    showPurchaseModal({ isPurchaseModalVisible: false, amount: 10000 });
  };

  const handleRemoveSavedPaymentMethod = async () => {
    // Remove saved payment method from GambleID
    await updateSavedPaymentMethods({
      isRemoving: true,
      selectedSavedPaymentMethod,
      sessionId,
    });

    // Remove saved payment method from payment session in our database
    await removeSavedPaymentMethod({
      sessionId,
      token: selectedSavedPaymentMethod.token,
    });

    // Retrieve payment session now that the saved payment method has been removed
    createPaymentSession();

    setShowPurchaseModalStepOne(true);
    setShowPurchaseModalStepTwo(false);
  };

  function handlePaymentMethodError () {
    displayAlert({ message: 'Payment method error.', type: 'error' });
    setShowPurchaseModalStepOne(true);
    setShowPurchaseModalStepTwo(false);
  }

  return (
    <Modal
      closeModal={() => showPurchaseModal({ isPurchaseModalVisible: false, amount: 10000 })}
      title="Purchase Coins"
    >
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(handleSubmit)}>
          {showPurchaseModalStepOne && <PurchaseModalStepOne
            form={form}
            savedPaymentMethods={savedPaymentMethods}
            setSelectedSavedPaymentMethod={setSelectedSavedPaymentMethod}
            setShowPurchaseModalStepOne={setShowPurchaseModalStepOne}
            setShowPurchaseModalStepTwo={setShowPurchaseModalStepTwo}
          />}
          {showPurchaseModalStepTwo && <PurchaseModalStepTwo
            form={form}
            handleRemoveSavedPaymentMethod={handleRemoveSavedPaymentMethod}
            loading={finalizePaymentLoading || updateSavedPaymentMethodsLoading || createPaymentSessionLoading}
            selectedSavedPaymentMethod={selectedSavedPaymentMethod}
            setShowPurchaseModalStepOne={setShowPurchaseModalStepOne}
            setShowPurchaseModalStepTwo={setShowPurchaseModalStepTwo}
          />}
        </Form>
      </FormProvider>
    </Modal>
  );
};
