import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import styled from 'styled-components';

import { Button, ClientLogo, Modal } from '_components';
import { mobileBreakpoint } from '_constants';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 16px;

  @media (min-width: ${mobileBreakpoint}px) {
    align-items: center; // Centers button in desktop view. Is streched in mobile
    padding: 20px;
  }
`;

export function ProtectedRoute ({ element }) {
  const { isAuthenticated, loginWithPopup } = useAuth0();

  // Flow keeps user on selected protected route but requires they sign in first.
  const handleLogin = async () => {
    await loginWithPopup({
      authorizationParams: {
        // eslint-disable-next-line
        webApiUrl: process.env.WEB_API_URL, // Used for creating users in local environments
      },
    });
  };

  if (!isAuthenticated) {
    return (
      <Modal title={<ClientLogo width={120}/>} width="50%" isDismissible={false}>
        <ModalContent>
          To play any of the games from our fine providers, please log in or create an account.
          <Button onClick={handleLogin}>Sign In</Button>
        </ModalContent>
      </Modal>
    );
  }

  return element;
}
