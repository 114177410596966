import { useAuth0 } from '@auth0/auth0-react';
import { show } from '@intercom/messenger-js-sdk';
import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
  borderRadius,
  boxShadow,
  colors,
  gamesCategoryOrder,
  mobileBreakpoint,
  navigationBarHeight,
} from '_constants';
import DiamondIcon from '_images/icons/diamond.svg';
import DiceIcon from '_images/icons/dice.svg';
import FlameIcon from '_images/icons/flame.svg';
// import FootballIcon from '_images/icons/football.svg';
import GroupIcon from '_images/icons/group.svg';
import LiveChat from '_images/icons/liveChat.svg';
import LogoutIcon from '_images/icons/logout.svg';
import MenuIcon from '_images/icons/menu.svg';
import PersonIcon from '_images/icons/person.svg';
import PlayingCardIcon from '_images/icons/playingCard.svg';
import RocketIcon from '_images/icons/rocket.svg';
import { toCamelCase } from '_utilities';

import { useNavigationMenuQuery } from './useNavigationMenuQuery';

const Container = styled.nav`
  display: flex;
  flex-direction: column;

  @media (max-width: ${mobileBreakpoint}px) {
    display: none;
  }
`;

const MenuIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${navigationBarHeight}px;
  width: ${navigationBarHeight}px;
  cursor: pointer;
`;

const StyledMenuIcon = styled(MenuIcon)`
  box-shadow: ${boxShadow};
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 20px;
`;

const ItemGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Item = styled(NavLink)`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: ${({ isCollapsed }) => isCollapsed ? 'center' : 'flex-start'};
  padding: ${({ isCollapsed }) => isCollapsed ? '10px' : '10px 65px 10px 15px'};
  transition: background-color 0.3s;
  border-radius: ${borderRadius}px;
  gap: 5px;
  box-shadow: ${boxShadow};
  background-color: ${({ isActive }) => (isActive ? colors.primary : colors.backgroundL20)};
  position: relative;

  ${({ isDisabled }) => isDisabled && `
    cursor: not-allowed;
  `}

  &:hover {
    background-color: ${({ isActive }) => isActive ? colors.primaryL60 : colors.backgroundL30};
  }
`;

const ItemText = styled.div`
  font-size: 14px;
  line-height: 1;
`;

const ItemButton = styled(Item)`
  &.active {
    background-color: ${colors.backgroundL20};
  }
`;

export const NavigationMenu = () => {
  const { isAuthenticated, logout } = useAuth0();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { categories } = useNavigationMenuQuery();
  const location = useLocation();
  const sortedCategories = [...categories].sort((a, b) => {
    return (
      gamesCategoryOrder.indexOf(toCamelCase(a.name)) -
      gamesCategoryOrder.indexOf(toCamelCase(b.name))
    );
  });
  const icons = [
    <FlameIcon fill={colors.white} width={15} />,
    <DiamondIcon fill={colors.white} width={15} />,
    <RocketIcon fill={colors.white} width={15} />,
    <PlayingCardIcon fill={colors.white} width={15} />,
    <GroupIcon fill={colors.white} width={15} />,
  ];

  const toggleIsCollapsed = () => setIsCollapsed(!isCollapsed);
  const filterValue = new URLSearchParams(location.search).get('filter');

  return (
    <Container isCollapsed={isCollapsed}>
      <MenuIconContainer onClick={toggleIsCollapsed}>
        <StyledMenuIcon fill={colors.white} width={25} />
      </MenuIconContainer>
      <Menu>
        <ItemGroup>
          <Item end isCollapsed={isCollapsed} to="/casino?filter=all" isActive={filterValue === 'all'}>
            <DiceIcon fill={colors.white} width={15} />
            {!isCollapsed && <ItemText>Casino</ItemText>}
          </Item>
          {sortedCategories.map((category, idx) =>
            <Item key={category.name} isCollapsed={isCollapsed} to={`/casino?filter=${toCamelCase(category.name)}`} isActive={filterValue === toCamelCase(category.name)}>
              {icons[idx]}
              {!isCollapsed && <ItemText>{category.name}</ItemText>}
            </Item>)}
        </ItemGroup>
        {/* <ItemGroup>
          <Item isCollapsed={isCollapsed} to="/poker">
            <PlayingCardIcon fill={colors.white} width={15} />
            {!isCollapsed && <ItemText>Poker</ItemText>}
          </Item>
          <Item isCollapsed={isCollapsed} isDisabled>
            <FootballIcon fill={colors.white} width={15} />
            {!isCollapsed && <ItemText>Sportsbook</ItemText>}
          </Item>
        </ItemGroup> */}
        <ItemGroup>
          {isAuthenticated ? <>
            <Item isCollapsed={isCollapsed} to="/profile">
              <PersonIcon fill={colors.white} width={15} />
              {!isCollapsed && <ItemText>Profile</ItemText>}
            </Item>
            <ItemButton isCollapsed={isCollapsed} onClick={() => show()} >
              <LiveChat fill={colors.white} width={15} />
              {!isCollapsed && <ItemText>Support</ItemText>}
            </ItemButton>
            <Item isCollapsed={isCollapsed} onClick={logout} to="/logout"> {/* to prop is used for styling not navigation */}
              <LogoutIcon fill={colors.white} width={15} />
              {!isCollapsed && <ItemText>Logout</ItemText>}
            </Item>
          </> : ''}
        </ItemGroup>
      </Menu>
    </Container>
  );
};
