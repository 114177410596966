import React, { memo } from 'react';
import styled from 'styled-components';

import { colors } from '_constants';

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  gap: 10px;

  svg {
    fill: ${colors.white};
    height: 20px;
  }
`;

export const IconTitle = memo(({ className, icon, title }) => {
  return (
    <Title className={className}>
      {icon}
      <span>{title}</span>
    </Title>
  );
});
