import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { Button, LoadingSpinner } from '_components';
import { borderRadius, boxShadow, colors, mobileBreakpoint } from '_constants';
import { useClaimBonusMutation } from '_hooks';
import GiftIcon from '_images/icons/gift.svg';
import PokerChipIcon from '_images/icons/pokerChip.svg';
import { formatCurrency, getCountdownTime } from '_utilities';

import { useProfileBalanceQuery } from './useProfileBalanceQuery';

const containerHeight = 200;

const LoadingSpinnerContainer = styled.div`
  height: ${containerHeight}px;
  display: grid;
  place-content: center;
`;

const BalanceContainer = styled.div`
  height: ${containerHeight}px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: ${boxShadow};
  border-radius: ${borderRadius}px;
  padding: 15px;
  background-color: ${colors.backgroundL14};
`;

const BalanceCard = styled(Card)`
  flex-basis: calc(50% - ${15 / 2}px);
  position: relative;
  overflow: hidden;
  z-index: 0;

  ${({ isHighlight }) => isHighlight && `
    border: 1px solid ${colors.primary};
  `}
`;

const BalanceTitle = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
`;

const BalanceValue = styled.div`
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
`;

const BalanceIcon = styled.div`
  position: absolute;
  right: 15px;
  bottom: -35px;
  z-index: -1;

  @media (max-width: ${mobileBreakpoint}px) {
    right: 5px;
    bottom: -20px;
  }

  & svg {
    width: 100px;
    fill: ${colors.backgroundL20};

    @media (max-width: ${mobileBreakpoint}px) {
      width: 75px;
    }
  }
`;

const ClaimBonusButton = styled(Button)`
  min-height: 40px;
  min-width: 100px;
`;

export const ProfileBalance = () => {
  const [countdown, setCountdown] = useState();
  const { isEligible, loading, nextEligibleTimestamp, wallets } = useProfileBalanceQuery();
  const claimBonus = useClaimBonusMutation();

  useEffect(() => {
    if (!nextEligibleTimestamp) return;

    const intervalId = setInterval(() => {
      setCountdown(getCountdownTime(nextEligibleTimestamp));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [nextEligibleTimestamp]);

  const goldCoinsWallet = useMemo(() => {
    return wallets.find(({ currency }) => currency.code === 'goldCoins');
  }, [wallets]);

  const sweepsCoinsWallet = useMemo(() => {
    return wallets.find(({ currency }) => currency.code === 'sweepsCoins');
  }, [wallets]);

  if (loading) {
    return <LoadingSpinnerContainer><LoadingSpinner /></LoadingSpinnerContainer>;
  }

  return (
    <BalanceContainer>
      <BalanceCard>
        <BalanceTitle>{goldCoinsWallet?.currency.name}</BalanceTitle>
        <BalanceValue>{formatCurrency(goldCoinsWallet?.playableBalance)}</BalanceValue>
        <BalanceIcon><PokerChipIcon /></BalanceIcon>
      </BalanceCard>

      <BalanceCard>
        <BalanceTitle>{sweepsCoinsWallet?.currency.name}</BalanceTitle>
        <BalanceValue>{formatCurrency(sweepsCoinsWallet?.playableBalance)}</BalanceValue>
        <BalanceIcon><PokerChipIcon /></BalanceIcon>
      </BalanceCard>

      <BalanceCard isHighlight>
        <BalanceTitle>Daily Bonus</BalanceTitle>
        <ClaimBonusButton disabled={loading || !isEligible} onClick={() => claimBonus()}>
          {isEligible ? 'Claim' : countdown || <LoadingSpinner size={15} />}
        </ClaimBonusButton>
        <BalanceIcon>
          <GiftIcon />
        </BalanceIcon>
      </BalanceCard>
    </BalanceContainer>
  );
};
