import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { colors, mobileBreakpoint, navigationMenuMobileHeight } from '_constants';
import DiamondIcon from '_images/icons/diamond.svg';
import DiceIcon from '_images/icons/dice.svg';
// import FootballIcon from '_images/icons/football.svg';
import PersonIcon from '_images/icons/person.svg';
// import PlayingCardIcon from '_images/icons/playingCard.svg';
import RocketIcon from '_images/icons/rocket.svg';

const Container = styled.div`
  background: ${colors.backgroundL14};
  height: ${navigationMenuMobileHeight}px;
  position: relative;
  display: flex;
  justify-content: space-around;

  @media (min-width: ${mobileBreakpoint}px) {
    display: none;
  }
`;

const Item = styled(NavLink)`
  align-items: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  gap: 3px;
  font-size: 10px;
  color: ${colors.backgroundL60};
  border-top: 3px solid transparent;
  -webkit-tap-highlight-color: transparent;

  svg {
    fill: ${colors.backgroundL60};
    height: 24px;
    width: 24px;
  }

  ${({ isActive }) => isActive && `
    border-top-color: ${colors.primary};
    color: ${colors.white};

    svg {
      fill: ${colors.white};
    }
  `}
`;

export const NavigationMenuMobile = () => {
  const location = useLocation();
  const filterValue = new URLSearchParams(location.search).get('filter');
  return (
    <Container>
      <Item isActive={filterValue === 'all'} to="/casino?filter=all"><DiceIcon />Casino</Item>
      <Item isActive={filterValue === 'slot'} to="/casino?filter=slot"><DiamondIcon />Slots</Item>
      <Item isActive={filterValue === 'arcade'} to="/casino?filter=arcade"><RocketIcon />Arcade</Item>
      {/* <Item to="/poker"><PlayingCardIcon />Poker</Item>
      <Item to="/sportsbook"><FootballIcon />Sports</Item> */}
      <Item isActive={location.pathname === '/profile'} to="/profile"><PersonIcon />Profile</Item>
    </Container>
  );
};
