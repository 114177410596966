import React from 'react';

export const ResponsibleGameplayPolicy = () => (
  <div>
    <p>Disclaimer Rad Software LLC operates and manages the online social sweepstakes casino 'LuckLake.com.' All legal, operational, and policy-related matters for this casino are overseen by Rad Software LLC, with 'LuckLake.com' being an approved DBA of Rad Software LLC.</p>
    <p>LuckLake.com wants you to enjoy the social gameplay in a safe and responsible environment and we are committed to the protection of our players. We believe it is our shared responsibility with you, our customers, to ensure that you enjoy your experience on our platform while remaining aware of the potential risks that can be associated with online gameplay if you don't remain in control. We encourage you to use the responsible social gameplay tools described below available at your disposal.</p>
    <p>To ensure that you enjoy fun and affordable play, we fully support responsible social gameplay and have put measures in place to assist customers who wish to control their play. We reserve the right to activate these measures unilaterally if, in our sole discretion, we consider them necessary.</p>
    <p>1. Introduction 1.1 This LuckLake.com Responsible Social Gameplay Policy describes the information and resources available to registered players on LuckLake.com.</p>
    <p>1.2 This LuckLake.com Responsible Gaming Policy forms part of the LuckLake.com&rsquo;s Terms and Conditions. Terms which are defined in the Terms and Conditions have the same meaning in this LuckLake.com Responsible Gaming Policy.</p>
    <p>1.3 We may update the LuckLake.com Responsible Gaming Policy at any time. Any amendments will be published on the Platform and such changes will be binding and effective immediately.</p>
    <p>1.4 Whenever we amend this LuckLake.com Responsible Gaming Policy in a way that would limit your current rights or which may be detrimental, we will notify you upon your next visit to the Platform and you will be required to re-confirm your acceptance prior to playing any Games. If you do not agree to the amended LuckLake.com Responsible Gaming Policy, you must stop using the Platform.</p>
    <p>1.5 To ensure a safe and responsible gaming environment, LuckLake.com partners with GambleID and TSEVO to comply with our Know Your Customer (KYC) requirements, verifying player identities and promoting fair play.</p>
    <p>2. LuckLake.com&rsquo;s Responsible Gaming Program 2.1 Our responsible gameplay policy and program are centered around our guiding principles of providing our customers with information and help resources needed to make an informed decision in gameplay and prevent problem gameplay from occurring on our site.</p>
    <p>2.2 We understand that it is a shared responsibility to achieve a fun and affordable gameplay environment and that it is ultimately an individual's choice to play. We do not provide counseling services nor do we police customer behavior. Instead, we focus on educating and supporting informed decisions.</p>
    <p>2.3 We have well-trained staff available to assist you in relation to your gameplay. Our staff are encouraged and empowered to help and assist you to enjoy your experience in a safe and responsible way.</p>
    <p>2.4 Taking a Break: if you want to take a break from gameplay, just send an email to support@LuckLake.com detailing the time period you want: 1 day, 3 days, 7 days, 14 days, 30 days or permanent close. It will not be possible to reactivate your account until the chosen period ends. During this period, you will be unsubscribed from receiving any marketing communications.</p>
    <p>3. Support Organizations 3.1 If your gameplay may have had, or is at risk of having, a negative impact on your mental health, finances or relationships with friends or family, we encourage you to get in touch with the following help and support organizations:</p>
    <p>Counseling Gaming Addicts Anonymous (GAA) is a fellowship of people who support each other in recovering from the problems resulting from excessive game playing. Credit Counseling Financial Counseling Association of America (FCAA) is a professional association of financial counseling agencies that assist consumers with financial counseling services of all kinds, as well as debt management plans for the repayment of unsecured debts. National Foundation for Credit Counseling (NFCC) is one of the oldest networks of non-profit financial counseling agencies. The NFCC helps people to defeat their debt and look forward with confidence.</p>
    <p>Please note that these organizations are independent support services and are NOT in any way affiliated with LuckLake.com. They do NOT provide customer support or dispute resolution services. Should you wish to discuss any matter or complaint related to your account, you can do so by contacting via email to support@LuckLake.com.</p>
    <p>4. Education &amp; Information on Responsible Gameplay 4.1 Principles of Gameplay Randomness: Remember that game round outcomes are completely random. Results cannot be predicted and are independent of past or future outcomes. Return to Player (RTP): This is the average return on the winnings and prizes over the lifetime of a slot-type game. I.e., if a slot type has an 8% advantage, then the average RTP will be 92%. Advantage: All casino-type games are designed with a slight advantage that favors the operator.</p>
    <p>4.2 Common Misconceptions &ldquo;I'm due for a win&rdquo; - You cannot predict when you're going to win. All outcomes are random. &ldquo;I always win with my lucky charm and pre-game ritual&rdquo; - Although they might be fun, charms and rituals don't affect your chances of winning. All outcomes are random. &ldquo;The longer I play, the more chance I'll win&rdquo; - Time spent has no effect on your chances of winning. All outcomes are random. &ldquo;These Games have been rigged&rdquo; - The Random Number Generator (RNG) used in all our games has been independently certified by various third party compliance associations, who confirmed that the RNG uses a well-known algorithm to generate random numbers. The numbers generated by the RNG have passed Marsaglia's &quot;diehard&quot; tests for statistical randomness. These certification companies have found that number sequences are unpredictable, non-repeatable, and uniformly distributed.</p>
    <p>4.3 Tips for Safe Gameplay Avoid gameplay while upset or emotional. Take frequent breaks during your gameplay sessions. Avoid gameplay while intoxicated. Avoid canceling redemptions. Remember that gameplay is only a form of entertainment; it should not be seen as a source of income or an escape from reality. Purchase only with money that you can afford to spend. Set a budget and don't go over it. Set a time limit before playing. Understand how games work before playing and remember that the results are random. Never let gameplay affect your employment, relationships, health, or commitments.</p>
    <p>5. Player Protection Policy 5.1 Protection of the Vulnerable Make sure that the decision to play on our platform is your own personal choice and responsibility.</p>
    <p>5.2 We do not recommend playing on our platform if you: Are being treated or are in recovery for an addiction/dependency Are under the influence of alcohol or any other substance Are currently experiencing financial difficulty or a traumatic life event Do not understand how to play the games Have any mental health concerns, cognitive impairment, or brain injury</p>
    <p>5.3 Protection of Minors We have identity checks in place to mitigate and prevent the risk of underage gameplay on our Platform. If you share your mobile phone, tablet, laptop, or computer with friends or family who are under the legal age to participate in online social gameplay, we recommend that you restrict their access to our Platform by using one of the below services: Net Nanny - filtering software that protects children from inappropriate web content. Cybersitter - filtering software that allows parents to add their own sites to block.</p>
    <p>17. Contacting Us If you have more questions related to this policy, email us directly at support@LuckLake.com.</p>
  </div>
);
