import { gql, useQuery } from '@apollo/client';

const redemptionModalQuery = gql`
  query redeemQuery {
    wallets {
      playableBalance
      redeemableBalance
      currency {
        code
        id
        name
        shortName
      }
    }
  }
`;

export function useRedemptionModalQuery () {
  const { data } = useQuery(redemptionModalQuery);

  const wallets = data?.wallets || [];

  return {
    wallets,
  };

}
