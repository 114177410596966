import { gql, useMutation } from '@apollo/client';

const verifyUser = gql`
  mutation verifyUser ($input: RegisterUserInput!) {
    verifyUser (input: $input) {
      error,
      user {
        auth0UserId,
        email,
        id,
      },
      isVerified,
      wallets {
        currency {
          code,
          id,
          name,
          shortName,
        },
        id,
        playableBalance,
        redeemableBalance,
      },
    },
  }
`;

export function useVerifyUserMutation () {
  const [mutation, { data, loading, error }] = useMutation(verifyUser, {
    refetchQueries: [
      'layoutQuery',
      'navigationBarQuery',
    ],
  });

  return [
    (variables) => mutation({ variables }),
    { ...data?.verifyUser, loading, error },
  ];
}