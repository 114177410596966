import { alertVariable } from '_apollo';

let nextId = 1;
const defaultDuration = 5000;
const defaultType = 'success';

export const displayAlert = ({ duration = defaultDuration, message, type = defaultType }) => {
  const id = nextId;
  nextId += 1;

  // type: 'success' | 'error'
  const alert = { id, message, duration, type, timeoutId: null };

  alert.timeoutId = setTimeout(() => removeAlert(id), duration);

  alertVariable([...alertVariable(), alert]);
};

export const removeAlert = (alertId) => {
  const currentAlerts = alertVariable();
  const alertToRemove = currentAlerts.find((alert) => alert.id === alertId);

  if (alertToRemove && alertToRemove.timeoutId) {
    clearTimeout(alertToRemove.timeoutId);
  }

  alertVariable(currentAlerts.filter((alert) => alert.id !== alertId));
};