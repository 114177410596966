import React, { useCallback, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { borderRadius, boxShadow, colors, mobileBreakpoint, transitionDuration } from '_constants';
import ChevronLeftIcon from '_images/icons/chevronLeft.svg';
import ChevronRightIcon from '_images/icons/chevronRight.svg';

const CategoriesContainer = styled.div`
  display: flex;
  background-color: ${colors.backgroundL14};
  box-shadow: ${boxShadow};
  border-radius: ${borderRadius}px;
  min-width: 0;
  position: relative;
  overflow: hidden;
  max-width: fit-content;
`;

const Categories = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: flex-start;
  position: relative;
  overflow-x: auto;
  scrollbar-width: none;

  @media (max-width: ${mobileBreakpoint}px) {
    gap: 15px;
  }
`;

const CategoryNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
  user-select: none;
  transition: color 0.3s;
  margin-left: 15px;
  margin-right: 15px;
  white-space: nowrap;
  padding: 5px 0;
  color: ${({ isActive }) => (isActive ? colors.primary : colors.white)};
  border-bottom: 2px solid ${({ isActive }) => (isActive ? colors.primary : 'transparent')};

  &:hover {
    color: ${colors.primary};
  }

  @media (max-width: ${mobileBreakpoint}px) {
    text-align: center;
  }
`;

export const CategoryLink = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
  user-select: none;
  transition: color 0.3s;
  margin-left: 15px;
  margin-right: 15px;
  white-space: nowrap;
  padding: 5px 0;
  text-decoration: none;
  color: ${colors.white};

  &:hover {
    color: ${colors.primary};
  }

  @media (max-width: ${mobileBreakpoint}px) {
    text-align: center;
  }
`;

const ScrollArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 30px;
  background-color: ${colors.backgroundL12};

  ${({ left }) =>
    left
      ? `
    box-shadow: 2px 0px 8px -2px ${colors.backgroundL5};
    left: 0;
  `
      : `
    box-shadow: -2px 0px 8px -2px ${colors.backgroundL5};
    right: 0;
  `};
`;

const ScrollArrowLeft = styled(ChevronLeftIcon)`
  fill: ${colors.white};
  height: 10px;
  transition: ${transitionDuration};

  ${ScrollArrowContainer}:hover & {
    fill: ${colors.primary};
  }
`;

const ScrollArrowRight = styled(ChevronRightIcon)`
  fill: ${colors.white};
  height: 10px;
  transition: ${transitionDuration};

  ${ScrollArrowContainer}:hover & {
    fill: ${colors.primary};
  }
`;

export const GameCategories = ({ gamesByCategory }) => {
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const categoriesRef = useRef();

  const checkScroll = useCallback((node) => {
    if (!node) return;
    setShowLeftArrow(node.scrollLeft > 0);
    setShowRightArrow(Math.ceil(node.scrollLeft) + node.clientWidth < node.scrollWidth);
  }, []);

  const categoriesCallbackRef = (node) => {
    checkScroll(node);
    categoriesRef.current = node;
  };

  const scrollByAmount = (scrollAmount) => () =>
    categoriesRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  const filterValue = new URLSearchParams(window.location.search).get('filter');

  return (
    <CategoriesContainer>
      <Categories
        ref={categoriesCallbackRef}
        onScroll={(event) => checkScroll(event.target)}
      >
        <CategoryNavLink isActive={filterValue === 'all'} to="/casino?filter=all">All</CategoryNavLink>
        {gamesByCategory.map((category) => (
          <CategoryNavLink
            key={category.category}
            isActive={filterValue === category.category}
            to={
              filterValue === category.category
                ? '/casino?filter=all'
                : `/casino?filter=${category.category}`
            }
          >
            {category.title}
          </CategoryNavLink>
        ))}
        <CategoryLink to="/poker">Poker</CategoryLink>
        <CategoryLink to="/sportsbook">Sportsbook</CategoryLink>
      </Categories>
      {showLeftArrow && (
        <ScrollArrowContainer left onClick={scrollByAmount(-100)}>
          <ScrollArrowLeft />
        </ScrollArrowContainer>
      )}
      {showRightArrow && (
        <ScrollArrowContainer right onClick={scrollByAmount(100)}>
          <ScrollArrowRight />
        </ScrollArrowContainer>
      )}
    </CategoriesContainer>
  );
};
