import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Modal } from '_components';

import { usePairTerminalSessionMutation } from './usePairTerminalSessionMutation';
import { usePairTerminalSessionQuery } from './usePairTerminalSessionQuery';

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 25px;
`;

export const PairTerminalSession = () => {
  const [showSuccessModal, setShowSuccessModal] = useState();
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const [pairTerminalSession] = usePairTerminalSessionMutation();
  const { user } = usePairTerminalSessionQuery();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const pairingCode = searchParams.get('pairingCode');

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({
        authorizationParams: {
          /* eslint-disable-next-line camelcase */
          redirect_uri: `${window.activeClient.auth0RedirectUri}/pairTerminalSession?pairingCode=${pairingCode}`,
        },
      });
      return;
    }
    if (isAuthenticated && !isLoading && user.id) {
      setShowSuccessModal(true);
      pairTerminalSession({ pairingCode });
    }
  }, [
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    pairingCode,
    pairTerminalSession,
    user,
  ]);

  const handleClick = () => navigate('/casino');

  return showSuccessModal && <Modal title="Ready To Play">
    Slot machine connected! Return to the machine to continue.
    <Row>
      <Button onClick={handleClick}>Continue On Slot Machine</Button>
      <Button onClick={handleClick} secondary>Home Page</Button>
    </Row>
  </Modal>;
};
