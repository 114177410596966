import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { LoadingSpinner } from '_components';
import { useUserEligibilityQuery } from '_hooks';
import { displayAlert } from '_utilities';

import { useCreateTurboGamesSession } from './useCreateTurboGamesSession';
import { useCreateTurboSportsbookSession } from './useCreateTurboSportsbookSession';
import { useCreateVivoSession } from './useCreateVivoSession';
import { useMancalaQuery } from './useMancalaQuery';
import { useTerminalGameQuery } from './useTerminalGameQuery';

const LoadingContainer = styled.div`
  display: grid;
  place-content: center;
  height: 100%;
`;

const IframeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;

const StyledIframe = styled.iframe`
  flex: 1;
  width: 100%;

  ${({ isManualFullscreen }) => isManualFullscreen && `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100dvh;
    z-index: 1;
  `}
`;

export const TerminalGame = () => {
  const iframeRef = useRef(null);
  const { loading: isEligibilityLoading, playEnabled } = useUserEligibilityQuery();

  const [isManualFullscreen, setIsManualFullscreen] = useState(false);
  const { providerName, externalId, gameId } = useParams();
  const navigate = useNavigate();
  const { refetch } = useTerminalGameQuery();

  // update wallet on game exit
  useEffect(() => {
    return refetch;
  }, [refetch]);

  // Mancala
  const { mancalaGameUrl } = useMancalaQuery({
    externalId: externalId,
    gameId: Number(gameId),
    isMancalaGame: providerName === 'mancalaGaming', // TODO: Remove hardcoded routes. Code column on providers?
    language: 'en-GB',
    returnUrl: window.location.origin,
  });

  // Turbo Games
  const { turboGamesUrl } = useCreateTurboGamesSession({
    gameId: Number(gameId),
    externalId,
    isTurboGame: providerName === 'turboGames',
  });

  // Vivo Gaming
  const { vivoGamingUrl } = useCreateVivoSession({
    gameId: Number(gameId),
    isVivoGame: providerName === 'vivoGaming',
  });

  // Turbo Sportsbook
  const { turboSportsbookUrl } = useCreateTurboSportsbookSession({
    isTurboSportsbook: providerName === 'turboSportsbook',
  });

  const iFrameSrcObj = {
    turboGames: turboGamesUrl,
    turboSportsbook: turboSportsbookUrl,
    mancalaGaming: mancalaGameUrl,
    vivoGaming: vivoGamingUrl,
  };

  useEffect(() => {
    if (playEnabled === false){
      displayAlert({ message:'Games are currently unavailable for your account.' });
      navigate('/');
    }
  }, [playEnabled, navigate]);

  useEffect(() => {
    if (!isManualFullscreen) return;
    const handlePopState = () => setIsManualFullscreen(false);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isManualFullscreen]);

  return isEligibilityLoading
    ? <LoadingContainer><LoadingSpinner /></LoadingContainer>
    : <IframeContainer>
      <StyledIframe
        allowFullScreen
        isManualFullscreen={isManualFullscreen}
        ref={iframeRef}
        src={iFrameSrcObj[providerName]}
        title="game"
      />
    </IframeContainer>;
};
