/******************************************************************************
 *                                                                            *
 *                          S C E N E   N O T   I N   U S E                    *
 *                                                                            *
 ******************************************************************************/

import React from 'react';
import { useLocation } from 'react-router-dom';

export function Betsoft () {
  const { state } = useLocation();
  const { token, gameId } = state;
  let activeCurrencyCode = 'LC';
  let bankId = '';
  if (activeCurrencyCode === 'LC') {
    bankId = '12761';
  } else if (activeCurrencyCode === 'C') {
    bankId = '12762';
  } else {
    console.error('Received invalid active currency code');
  }
  return (
    <div style={{ marginTop: '100px' }}>
      <iframe src={`https://lucklake-gp3.betsoftgaming.com/cwstartgamev2.do?token=${token}&bankId=${bankId}&gameId=${gameId}&mode=REAL&lang=en`} title="Betsoft"/>
    </div>
  );
}

// https://lucklake-gp3.betsoftgaming.com/cwstartgamev2.do
// ?token=${token}      // token from RadServices/betsoft/createsesson JSON.token
// &bankId=${bankId}    // 12761 (Real) or 12762 (Fun)
// &gameId=${someGame.providerId}
// &mode=REAL
// &lang=en

