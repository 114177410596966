import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { borderRadius, boxShadow, colors, transitionDuration, transformAnimation } from '_constants';
import CheckIcon from '_images/icons/check.svg';
import CloseIcon from '_images/icons/close.svg';

const translateAnimation = transformAnimation({ transform: 'translateX(100%)' });

const shrinkProgressBar = keyframes`
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
`;

const Container = styled.div`
  position: relative;
  background-color: ${colors.backgroundL20};
  box-shadow: ${boxShadow};
  border-radius: ${borderRadius}px;
  overflow: hidden;
  display: flex;
  cursor: pointer;
  transition: ${transitionDuration};
  ${translateAnimation};

  &:hover {
    background-color: ${colors.backgroundL14};
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    height: 5px;
    width: 100%;
    background-color: ${colors.primary};
    animation: ${({ duration }) => css`${shrinkProgressBar} ${duration}ms linear forwards`};
  }
`;

const IconContainer = styled.div`
  padding: 15px 20px;
  background-color: ${colors.backgroundL14};
  transition: ${transitionDuration};

  ${Container}:hover & {
    background-color: ${colors.backgroundL12};
  }
`;

const Content = styled.div`
  padding: 15px 25px;
  flex: 1;
`;

const CloseIconContainer = styled.div`
  padding: 15px 20px 15px 0;
`;

export const Alert = ({ children, closeAlert, duration, type = 'success' }) => {

  return (
    <Container onClick={closeAlert} duration={duration}>
      <IconContainer>
        { type === 'success' ? <CheckIcon fill={colors.green} width={20} /> : type === 'warning' ? <CheckIcon fill={colors.yellow} width={20} /> : <CloseIcon fill={colors.redL50} width={20} /> }
      </IconContainer>
      <Content>
        {children}
      </Content>
      <CloseIconContainer>
        <CloseIcon fill={colors.white} width={10} />
      </CloseIconContainer>
    </Container>
  );
};
