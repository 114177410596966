import { gql, useQuery } from '@apollo/client';

const layoutQuery = gql`
  query layoutQuery {
    bonusEligibility {
      id,
      isEligible,
    },
    user {
      id,
    },
  }
`;

export function useLayoutQuery (isAuthenticated) {
  const { data, loading } = useQuery(layoutQuery, { skip: !isAuthenticated });
  const bonusEligibility = data?.bonusEligibility || {};
  const isEligible = bonusEligibility.isEligible || false;
  const user = data?.user || {};

  return {
    isEligible,
    loading,
    user,
  };
}
