import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  Dropdown,
  GameCarousel,
  IconTitle,
  LoadingSpinner,
  ProvidersCarousel,
} from '_components';
import {
  borderRadius,
  boxShadow,
  colors,
  gamesCategoryOrder,
  mobileBreakpoint,
} from '_constants';
import DiamondIcon from '_images/icons/diamond.svg';
import FlameIcon from '_images/icons/flame.svg';
import GroupIcon from '_images/icons/group.svg';
import Joystick from '_images/icons/joystick.svg';
import PlayingCardIcon from '_images/icons/playingCard.svg';
import RocketIcon from '_images/icons/rocket.svg';
import SearchIcon from '_images/icons/search.svg';
import { toCamelCase } from '_utilities';

import { GameCategories, GameList } from './components';
import { useCasinoQuery } from './useCasinoQuery';

const LoadingSpinnerContainer = styled.div`
  height: 100%;
  display: grid;
  place-content: center;
`;

const Container = styled.div`
  @media (max-width: ${mobileBreakpoint}px) {
    padding: 10px;
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  position: relative;
  margin-bottom: 25px;
`;

const FiltersRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 250px;
  @media (max-width: ${mobileBreakpoint}px) {
    flex-direction: column-reverse;
    gap: 10px;
  }
`;

const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  box-shadow: ${boxShadow};
  border-radius: ${borderRadius}px;
  width: 240px;
  padding: 10px 15px;
  background-color: ${colors.backgroundL20};
  flex-shrink: 0;

  @media (max-width: ${mobileBreakpoint}px) {
    width: 100%;
  }
`;

const SearchInput = styled.input`
  color: ${colors.white};
  font-weight: 600;
  flex: 1;

  &::placeholder {
    color: ${colors.backgroundL80};
  }
`;

const DropdownsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ProvidersCarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 25px;
`;

export const Casino = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [selectedSort, setSelectedSort] = useState('asc');

  const { gamesByCategory, providers, loading } = useCasinoQuery();
  const [searchParams, setSearchParams] = useSearchParams();

  const allGames = useMemo(() => {
    return [
      ...new Map(
        gamesByCategory
          .flatMap((category) => category.games)
          .map((game) => [game.id, game])
      ).values(),
    ];
  }, [gamesByCategory]);

  const icons = useMemo(
    () => [<FlameIcon />, <DiamondIcon />, <RocketIcon />, <PlayingCardIcon />, <GroupIcon />],
    []
  );

  const filterableProviders = useMemo(() => {
    return providers.map((provider) => ({
      ...provider,
      filterKey: toCamelCase(provider.name),
    }));
  }, [providers]);

  // Query Param block
  useEffect(() => {
    const filterParam = searchParams.get('filter');
    if (filterParam) {
      setSelectedFilter(filterParam);
      setSearchTerm('');
    }
  }, [searchParams, setSearchParams]);

  const orderedGamesByCategory = useMemo(() => {
    return gamesCategoryOrder.map((category, index) => {
      const game = gamesByCategory.find(({ name }) => toCamelCase(name) === category);
      return {
        category,
        games: game?.games || [],
        icon: icons[index],
        title: game?.name || '',
      };
    });
  }, [gamesByCategory, icons]);

  const providerDropdownOptions = useMemo(
    () => [
      { label: 'All Providers', value: 'all' },
      ...filterableProviders.map((provider) => ({
        label: provider.name,
        value: provider.filterKey,
      })),
    ],
    [filterableProviders]
  );

  const sortDropdownOptions = useMemo(
    () => [
      { label: 'A-Z', value: 'asc' },
      { label: 'Z-A', value: 'desc' },
    ],
    []
  );

  const filterGames = useCallback(() => {
    if (searchTerm.trim()) {
      return [...allGames]
        .filter((game) =>
          game.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) =>
          selectedSort === 'asc'
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name)
        );
    }

    const isProvider = filterableProviders.some((p) => p.filterKey === selectedFilter);
    const baseFiltered = isProvider
      ? allGames.filter((game) => toCamelCase(game.provider.name) === selectedFilter)
      : selectedFilter === 'all'
        ? allGames
        : orderedGamesByCategory.find(
          (category) => category.category === selectedFilter
        )?.games || [];

    return [...baseFiltered].sort((a, b) =>
      selectedSort === 'asc'
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name)
    );
  }, [selectedFilter, selectedSort, searchTerm, allGames, orderedGamesByCategory, filterableProviders]);

  const [filteredGames, setFilteredGames] = useState([]);
  const isFilterActive = useMemo(
    () => selectedFilter !== 'all' || searchTerm.trim() !== '',
    [selectedFilter, searchTerm]
  );

  useEffect(() => {
    setFilteredGames(filterGames());
  }, [filterGames]);

  const onSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
    setSelectedFilter('all');
  };

  const handleProviderChange = (option) => {
    setSearchTerm('');
    searchParams.set('filter', option.value);
    setSearchParams(searchParams);
  };

  const handleSortChange = (option) => {
    setSelectedSort(option.value);
  };

  if (loading) {
    return <LoadingSpinnerContainer>
      <LoadingSpinner />
    </LoadingSpinnerContainer>;
  }

  return (
    <Container>
      <ControlsContainer>
        <FiltersRow>
          <GameCategories
            gamesByCategory={orderedGamesByCategory}
          />

          <SearchInputContainer>
            <SearchInput
              placeholder="Search"
              value={searchTerm}
              onChange={onSearchTermChange}
            />
            <SearchIcon fill="white" height={20} />
          </SearchInputContainer>
        </FiltersRow>

        <DropdownsContainer>
          <Dropdown
            options={providerDropdownOptions}
            selectedOption={
              providerDropdownOptions.find(
                (providerDropdownOption) => providerDropdownOption.value === selectedFilter
              ) || providerDropdownOptions[0]
            }
            onChange={handleProviderChange}
          />
          {isFilterActive && (
            <Dropdown
              options={sortDropdownOptions}
              selectedOption={
                sortDropdownOptions.find((sortDropdownOption) => sortDropdownOption.value === selectedSort)
                || sortDropdownOptions[0]}
              onChange={handleSortChange}
            />
          )}
        </DropdownsContainer>
      </ControlsContainer>

      {!isFilterActive ? (
        <>
          {orderedGamesByCategory.map((orderedGamesByCategoryItem) => (
            <GameCarousel
              key={orderedGamesByCategoryItem.category}
              category={orderedGamesByCategoryItem}
              games={orderedGamesByCategoryItem.games}
              onViewAllClick={() => setSelectedFilter(orderedGamesByCategoryItem.category)}
            />
          ))}
        </>
      ) : (
        <GameList games={filteredGames} />
      )}

      <ProvidersCarouselContainer>
        <IconTitle icon={<Joystick />} title="Providers" />
        <ProvidersCarousel
          providers={filterableProviders}
          setSelectedFilter={(filter) => {
            setSelectedFilter(filter);
            setSearchTerm('');
          }}
        />
      </ProvidersCarouselContainer>
    </Container>
  );
};
