import useEmblaCarousel from 'embla-carousel-react';
import { WheelGesturesPlugin as wheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  borderRadius,
  boxShadow,
  colors,
  mainContentMaxWidth,
  mobileBreakpoint,
  transitionDuration,
} from '_constants';
import MancalaGamingLogo from '_images/logos/mancalaGaming.svg';
import RivalGames from '_images/logos/rivalGames.svg';
import TurboGamesLogo from '_images/logos/turboGames.svg';
import VivoGamingLogo from '_images/logos/vivoGaming.svg';

const providerImages = {
  'Mancala Gaming': <MancalaGamingLogo />,
  'Rival Games': <RivalGames />,
  'Turbo Games': <TurboGamesLogo />,
  'Vivo Gaming': <VivoGamingLogo />,
};

const thumbnailSize = 200;
const thumbnailMargin = 15;

const Container = styled.div`
  width: 100%;

  @media (min-width: ${mobileBreakpoint}px) {
    overflow: hidden;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  gap: ${thumbnailMargin}px;
`;

const ProviderLink = styled(Link)`
  border-radius: ${borderRadius}px;
  box-shadow: ${boxShadow};
  background-color: ${colors.backgroundL20};
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${transitionDuration};
  text-decoration: none;

  svg {
    max-height: 100%;
    width: 125px;
  }

  &:hover {
    background-color: ${colors.backgroundL30};
  }
`;

const RowContainer = styled.div``;

export const ProvidersCarousel = memo(({ providers }) => {
  const windowWidth = window.innerWidth;
  const containerWidth = windowWidth < mainContentMaxWidth ? windowWidth : mainContentMaxWidth;
  const totalWidthNeeded = providers.length * (thumbnailSize + thumbnailMargin);
  const isCarousel = totalWidthNeeded > containerWidth;

  const [emblaRef] = useEmblaCarousel(
    { align: 'start', dragFree: true, loop: true },
    [wheelGesturesPlugin()]
  );

  const rowContent = (
    <InnerContainer>
      {providers.map((provider) => (
        <ProviderLink
          key={provider.id}
          to={`/casino?filter=${provider.filterKey}`}
        >
          {providerImages[provider.name]}
        </ProviderLink>
      ))}
    </InnerContainer>
  );

  return (
    <Container>
      {isCarousel ? (
        <RowContainer ref={emblaRef}>
          {rowContent}
        </RowContainer>
      ) : (
        <RowContainer>
          {rowContent}
        </RowContainer>
      )}
    </Container>
  );
});
