import React from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { borderRadius, boxShadow, transitionDuration } from '_constants';
import { toCamelCase } from '_utilities';

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const Container = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  gap: 25px;
  font-size: 0;
`;

const Game = styled(Link)`
  width: 100%;
  height: 100%;
  transition: transform ${transitionDuration};
  border-radius: ${borderRadius * 2}px;
  box-shadow: ${boxShadow};
  animation: ${fadeInUp} ${transitionDuration} ease-out forwards;
  animation-delay: ${({ index }) => index * .05}s;
  opacity: 0;

  &:hover {
    transform: translateY(-5px);
  }
`;

const Image = styled.img`
  border-radius: ${borderRadius * 2}px;
  object-fit: cover;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export const TerminalGameList = ({ games }) => {
  return (
    <Container>
      {games?.map((game, index) => (
        <Game
          index={index}
          key={game.id}
          to={`/terminal/game/${toCamelCase(game?.provider?.name)}/${game.externalId}/${game.id}`}
        >
          <Image
            src={game.imageUrl}
            loading="lazy"
            alt={`${game?.provider?.name} ${game.name}`}
          />
        </Game>
      ))}
    </Container>
  );
};
