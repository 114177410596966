import { gql, useQuery } from '@apollo/client';

const profileHistoryQuery = gql`
  query profileHistoryQuery($transactionTypes: [String]) {
    transactions(transactionTypes: $transactionTypes) {
      amount,
      transactionType,
      playableBalance,
      createdAt,      
      wallet {
        id,
        playableBalance,
        currency {
          id,
          code,
          shortName,
        },
      },
    },
  },
`;

export function usePofileHistoryQuery () {
  const { data, loading } = useQuery(profileHistoryQuery, {
    variables: {
      transactionTypes: [
        'adminDeposit',
        'adminWithdrawal',
        'deposit',
        'withdrawal',
        'refund',
        'bonusDeposit',
      ],
    },
  });

  return {
    loading,
    transactions: data?.transactions,
  };
}
