import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  Observable,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { useAuth0 } from '@auth0/auth0-react';
import { createClient } from 'graphql-ws';
import React, { useMemo } from 'react';

import { activeRequestsVariable, terminalAccessTokenVariable } from '.';

export const ApolloAppProvider = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  // Auth link for HTTP requests.
  const authLink = useMemo(() => setContext(async (_, { headers }) => {
    const terminalAccessToken = terminalAccessTokenVariable();
    if (terminalAccessToken) {
      return {
        headers: {
          ...headers,
          'terminal-access-token': terminalAccessToken,
        },
      };
    }
    if (!isAuthenticated) return { headers };
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.AUTH0_AUDIENCE,
      });

      return {
        headers: {
          ...headers,
          'access-token': accessToken,
        },
      };
    } catch (error) {
      console.error('Error fetching Auth0 token:', error);
      return { headers };
    }
  }), [getAccessTokenSilently, isAuthenticated]);

  // Loading link for tracking active requests.
  const loadingLink = useMemo(() => new ApolloLink((operation, forward) => {
    activeRequestsVariable(activeRequestsVariable() + 1);

    return new Observable((observer) => {
      const subscription = forward(operation).subscribe({
        complete: () => observer.complete(),
        error: (error) => observer.error(error),
        next: (result) => observer.next(result),
      });

      return () => {
        activeRequestsVariable(activeRequestsVariable() - 1);
        subscription.unsubscribe();
      };
    });
  }), []);

  // HTTP link for queries and mutations.
  const httpLink = useMemo(() => new HttpLink({
    uri: ({ operationName }) => `${process.env.WEB_API_URL}/${operationName}`,
  }), []);

  // WebSocket link for subscriptions.
  const wsLink = new GraphQLWsLink(createClient({
    url: process.env.WEBSOCKET_API_URL,
    connectionParams: async () => {
      if (isAuthenticated) {
        try {
          const accessToken = await getAccessTokenSilently({
            audience: process.env.AUTH0_AUDIENCE,
          });
          return {
            'access-token': accessToken,
          };
        } catch (error) {
          console.error('Error fetching Auth0 token for WS:', error);
          return {};
        }
      }
      return {};
    },
  }));

  // Split link: subscriptions go to wsLink, rest go to HTTP.
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(loadingLink.concat(httpLink))
  );

  const client = useMemo(() => {
    return new ApolloClient({
      link: splitLink,
      cache: new InMemoryCache(),
    });
  }, [splitLink]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
