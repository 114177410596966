import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const finalizePaymentSession = gql`
  mutation finalizePaymentSession (
    $amount: Float,
    $cvv: String,
    $isDeposit: Boolean,
    $sessionId: String,
    $token: String,
    $transactionId: String,
    $type: String,
  ) {
    finalizePaymentSession (
      amount: $amount,
      cvv: $cvv,
      isDeposit: $isDeposit,
      sessionId: $sessionId,
      token: $token,
      transactionId: $transactionId,
      type: $type,
    ) {
      error {
        code,
        message,
      },
      paymentState,
      wallets {
        id,
        playableBalance,
        redeemableBalance,
      },
    },
  }
`;

export function useFinalizePaymentSessionMutation ({ isDeposit }) {
  const [mutation, { data, error: mutationError, loading }] = useMutation(finalizePaymentSession, {
    variables: { isDeposit },
    refetchQueries: [
      'layoutQuery',
      'navigationBarQuery',
    ],
  });

  return [
    useCallback((variables) => mutation({ variables }), [mutation]),
    { ...data?.finalizePaymentSession || {}, loading, mutationError },
  ];
}
