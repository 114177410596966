import React from 'react';
import styled from 'styled-components';

import { Button, FormInput, FormDropdown, LoadingSpinner } from '_components';
import { colors, countryOptions, transitionDuration, borderRadius, boxShadow } from '_constants';
import ChevronLeftIcon from '_images/icons/chevronLeft.svg';
import CloseIcon from '_images/icons/close.svg';
import MasterCard from '_images/logos/masterCard.png';
import Visa from '_images/logos/visa.png';
import { formatAmount } from '_utilities';

const BackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  transition: color ${transitionDuration};
  cursor: pointer;
  align-self: flex-start;

  &:hover {
    color: ${colors.primary};
  }
`;

const BackButtonIcon = styled(ChevronLeftIcon)`
  fill: ${colors.white};
  height: 15px;
  transition: fill ${transitionDuration};

  ${BackButton}:hover & {
    fill: ${colors.primary};
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const TotalContainer = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${colors.backgroundL80};
  display: flex;
  gap: 10px;
`;

const TotalAmount = styled.div`
  color: ${colors.primary};
  font-weight: 700;
`;

const Title = styled.div`
  color: ${colors.backgroundL80};
  font-size: 14px;
  font-weight: 600;
`;

const InputRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  cursor: pointer;
  color: ${colors.backgroundL80};
`;

const PaymentMethod = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: space-between;
  padding: 25px;
  background-color: ${colors.backgroundL20};
  box-shadow: ${boxShadow};
  border-radius: ${borderRadius}px;
  gap: 15px;
`;

const CardLogos = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;
`;

const CardLabel = styled.div`
  color: ${colors.white};
  flex: 1;
  font-size: 16px;
  font-weight: 500;
`;

const RemovePaymentMethodButton = styled(CloseIcon)`
  fill: ${colors.white};
  height: 15px;
  transition: fill ${transitionDuration};
  cursor: pointer;

  &:hover {
    fill: ${colors.red};
  }
`;

export const PurchaseModalStepTwo = ({
  form,
  handleRemoveSavedPaymentMethod,
  loading,
  selectedSavedPaymentMethod,
  setShowPurchaseModalStepOne,
  setShowPurchaseModalStepTwo,
}) => {
  const purchaseAmount = form.watch('goldCoinsAmount') / 1000;

  const handleChangeExpDate = (event) => {
    let value = event.target.value
      .replace(/\D/g, '') // Remove non-numeric characters
      .slice(0, 4); // Limit to 4 digits

    if (value.length >= 2) {
      value = value.slice(0, 2) + '/' + value.slice(2);
    }

    form.setValue('cardExpirationDate', value);
  };

  // Handle backspace and delete
  const handleKeyDown = (event) => {
    // If backspace/delete is pressed and the cursor is right after the slash
    if ((event.key === 'Backspace' || event.key === 'Delete') &&
        event.target.selectionStart === 3 &&
        event.target.selectionEnd === 3) {
      // Move the cursor before the slash
      event.preventDefault();
      const input = event.target;
      input.setSelectionRange(2, 2);
    }
  };

  const handleBackButtonClick = () => {
    setShowPurchaseModalStepOne(true);
    setShowPurchaseModalStepTwo(false);
  };

  return (
    <>
      <BackButton onClick={handleBackButtonClick}><BackButtonIcon />Back</BackButton>
      <TotalContainer>Total <TotalAmount>{formatAmount(purchaseAmount)}</TotalAmount></TotalContainer>
      <Section>
        <Title>Credit Card Details</Title>
        {selectedSavedPaymentMethod ? <>
          <PaymentMethod>
            <CardLogos>
              {selectedSavedPaymentMethod.name.includes('Visa') && <img src={Visa} alt="Visa" />}
              {selectedSavedPaymentMethod.name.includes('MasterCard') && <img src={MasterCard} alt="MasterCard" />}
            </CardLogos>
            <CardLabel>{selectedSavedPaymentMethod.name}</CardLabel>
            <RemovePaymentMethodButton onClick={handleRemoveSavedPaymentMethod} />
          </PaymentMethod>
          <FormInput type="number" name="cvv" placeholder="CVV" rules={{ required: true, minLength: 3, maxLength: 3 }} />
        </> : <>
          <FormInput name="nameOnAccount" placeholder="Cardholder Name*" rules={{ required: true }} />
          <FormInput name="cardNumber" placeholder="Card Number*" rules={{ required: true, minLength: 10, maxLength: 20 }} type="number" />
          <InputRow>
            <FormInput
              name="cardExpirationDate"
              placeholder="MM/YY*"
              rules={{ required: true, pattern: { value: /^(0[1-9]|1[0-2])\/\d{2}$/, message: 'Invalid Format (MM/YY)' } }}
              onChange={handleChangeExpDate}
              onKeyDown={handleKeyDown}
            />
            <FormInput type="number" name="cvv" placeholder="CVV*" rules={{ required: true, minLength: 3, maxLength: 3 }} />
          </InputRow>
        </>}
      </Section>
      {!!selectedSavedPaymentMethod || <>
        <Section>
          <Title>Billing Address</Title>
          <FormDropdown isModalDropdown name="country" placeholder="Country*" rules={{ required: true }} options={countryOptions} />
          <FormInput name="addressLine1" placeholder="Address*" rules={{ required: true }} />
          <FormInput name="city" placeholder="City*" rules={{ required: true }} />
          <InputRow>
            <FormInput name="state" placeholder="State*" rules={{ required: true }} />
            <FormInput name="zipCode" placeholder="Zip Code*" rules={{ required: true }} />
          </InputRow>
        </Section>
        <CheckboxLabel>
          <input type="checkbox" {...form.register('savePaymentMethod')} />
          Save this credit card for future transactions
        </CheckboxLabel>
      </>}
      <Button type="submit" disabled={purchaseAmount <= 0 || loading}>{loading ? <LoadingSpinner size={20}/> : 'Complete Payment'}</Button>
    </>
  );
};
