import { createGlobalStyle } from 'styled-components';

import { colors, mobileBreakpoint } from '_constants';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    min-height: 100dvh;
    height: 100%;
    overflow-x: hidden;
    color: ${colors.white};
    background-color: ${colors.backgroundL12};
    font-family: 'Poppins', sans-serif;
    font-style: normal;
  }

  @media (min-width: ${mobileBreakpoint}px) {
    body {
      padding-bottom: 0;
    }
  }

  a {
    color: unset;
    text-decoration: unset;
  }

  button {
    font-family: unset;
    border: unset;
  }

  input,
  select,
  textarea {
    background: unset;
    border: unset;
    outline: unset;
    padding: unset;
    margin: unset;
    font-family: unset;
  }

  svg {
    overflow: visible;
  }

  iframe {
    border: unset;
  }
`;
