import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import DiamondIcon from '_images/icons/diamond.svg';
import FlameIcon from '_images/icons/flame.svg';
import GroupIcon from '_images/icons/group.svg';
import PlayingCardIcon from '_images/icons/playingCard.svg';
import RocketIcon from '_images/icons/rocket.svg';
import { toCamelCase } from '_utilities';

import { TerminalGameCategories, TerminalGameList } from './components';
import { useTerminalCasinoQuery } from './useTerminalCasinoQuery';

const gamesCategoryOrder = ['popular', 'slots', 'arcade'];

const Content = styled.div`
  overflow: scroll;
  padding: 25px;
  flex: 1;
  scrollbar-width: none;
`;

export const TerminalCasino = () => {
  const [selectedFilter, setSelectedFilter] = useState('all');
  const { gamesByCategory } = useTerminalCasinoQuery();

  const allGames = useMemo(() => {
    return [
      ...new Map(
        gamesByCategory
          .flatMap((category) => category.games)
          .map((game) => [game.id, game])
      ).values(),
    ];
  }, [gamesByCategory]);

  const icons = useMemo(() => [<FlameIcon />, <DiamondIcon />, <RocketIcon />, <PlayingCardIcon />, <GroupIcon />], []);

  const orderedGamesByCategory = useMemo(() => {
    return gamesCategoryOrder.map((category, index) => {
      const game = gamesByCategory.find(({ name }) => toCamelCase(name) === category);
      return {
        category,
        title: game?.name || '',
        games: game?.games || [],
        icon: icons[index],
      };
    });
  }, [gamesByCategory, icons]);

  const filteredGames = useMemo(() => {
    return selectedFilter === 'all' ? allGames
      : orderedGamesByCategory.find((category) => category.category === selectedFilter)?.games || [];
  }, [allGames, orderedGamesByCategory, selectedFilter]);

  return (
    <>
      <TerminalGameCategories
        gamesByCategory={orderedGamesByCategory}
        selectedFilter={selectedFilter}
        setSelectedFilter={(filter) => {
          setSelectedFilter(filter);
        }}
      />
      <Content>
        <TerminalGameList games={filteredGames} />
      </Content>
    </>
  );
};
