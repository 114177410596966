import { gql, useQuery } from '@apollo/client';

const gameQuery = gql`
  query gameQuery {
    wallets {
      id
      playableBalance
      currency {
        code
        id
        name
        shortName
      }
    }
  }
`;

export function useGameQuery () {
  const { data, refetch } = useQuery(gameQuery);

  const wallets = data?.wallets || [];

  return {
    refetch,
    wallets,
  };
}
