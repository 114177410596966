import { gql, useQuery } from '@apollo/client';

const profileQuery = gql`
  query profileQuery {
    user {
      id,
      email,
    }
  }
`;

export function useProfileQuery () {
  const { data } = useQuery(profileQuery);

  return {
    userEmail: data?.user?.email || null,
  };
}
