import { gql, useQuery } from '@apollo/client';

const profileGameplayQuery = gql`
  query profileGameplayQuery($transactionTypes: [String]) {
    transactions(transactionTypes: $transactionTypes) {
      amount,
      createdAt,
      id,
      playableBalance,
      transactionType,
      wallet {
        id,
        playableBalance,
        currency {
          id,
          code,
          shortName,
        },
      },
      game {
        id,
        name,
      },
    },
  },
`;

export function useProfileGameplayQuery () {
  const { data, loading } = useQuery(profileGameplayQuery, {
    variables: { transactionTypes: ['payout'] },
  });

  return {
    loading,
    transactions: data?.transactions,
  };
}
