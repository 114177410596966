export function getCountdownTime (targetTimestamp) {
  if (!targetTimestamp) return;
  const now = Date.now();
  let distance = targetTimestamp - now;

  if (distance < 0) {
    return '00:00:00';
  }

  const hours = String(Math.floor(distance / (1000 * 60 * 60))).padStart(2, '0');
  const mins = String(Math.floor((distance / (1000 * 60)) % 60)).padStart(2, '0');
  const secs = String(Math.floor((distance / 1000) % 60)).padStart(2, '0');

  return `${hours}:${mins}:${secs}`;
}

export function formatTimestamp (timestampMs) {
  const date = new Date(timestampMs);
  const options = { hour: 'numeric', minute: 'numeric', hour12: true };

  const time = date.toLocaleTimeString('en-US', options);
  const formattedDate = `${time} ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

  return formattedDate;
}
