import React, { useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { GameCarousel } from '_components';
import FlameIcon from '_images/icons/flame.svg';

import { usePopularGamesQuery } from './usePopularGamesQuery';

export const PopularGames = memo(() => {
  const { gamesByCategory } = usePopularGamesQuery();
  const navigate = useNavigate();
  const popularGames = useMemo(
    () => ({
      games:
        gamesByCategory.find(({ name }) => name === 'Popular')?.games || [],
      icon: <FlameIcon />,
      path: '/casino/popular',
      title: 'Popular',
    }),
    [gamesByCategory]
  );

  return (
    <GameCarousel
      category={popularGames}
      games={popularGames.games}
      onViewAllClick={() => navigate('/casino?filter=popular')}
    />
  );
});
