import React, { useState } from 'react';
import styled from 'styled-components';

import { Modal } from '_components';
import { boxShadow, colors } from '_constants';

import { TerminalQrCode } from '../terminalQrCode';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const JackpotContainer = styled.div`
  position: absolute;
  top: 1vh;
  right: 1vh;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 0.25vh;
`;

const JackpotBorder = styled.div`
  position: absolute;
  top: 0.5vh;
  left: 0.5vh;
  right: 0.5vh;
  bottom: 0.5vh;
  border: 0.25vh solid ${colors.primary};
  border-radius: 2vh;
`;

const JackpotLabel = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
`;

const JackpotValue = styled.div`
  line-height: 1;
  font-weight: bold;
`;

const Jackpot = styled.div`
  position: relative;
  display: flex;
  gap: 2vh;
  background-color: ${colors.backgroundL5};
  padding: 1vh 2vh;
  border-radius: 2.5vh;

  ${({ grand }) => grand && `
    ${JackpotBorder} {
      border-color: ${colors.redL50};
    }
    ${JackpotLabel} {
      color: ${colors.redL50};
      font-size: 3vh;
    }
    ${JackpotValue} {
      font-size: 3vh;
    }
  `}

  ${({ major }) => major && `
    ${JackpotBorder} {
      border-color: ${colors.greenL50};
    }
    ${JackpotLabel} {
      color: ${colors.greenL50};
      font-size: 2.5vh;
    }
    ${JackpotValue} {
      font-size: 2.5vh;
    }
  `}

  ${({ minor }) => minor && `
    ${JackpotBorder} {
      border-color: ${colors.blueL50};
    }
    ${JackpotLabel} {
      color: ${colors.blueL50};
      font-size: 2vh;
    }
    ${JackpotValue} {
      font-size: 2vh;
    }
  `}
`;

const Video = styled.video`
  height: 0;
  flex: 1;
  object-fit: cover;
`;

const FooterBanner = styled.div`
  background-color: ${colors.primary};
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.backgroundL5};
  font-size: 2vh;
  font-weight: bold;
`;

const QrCodeModal = styled(Modal)`
  background-color: ${colors.primary};
  align-items: center;
  border-radius: 1vh;
  flex: unset;
  max-width: none;
  max-height: none;
`;

const QrCodeModalText = styled.div`
  font-size: 3vh;
  margin-bottom: 2vh;
  font-weight: 700;
  background-color: ${colors.backgroundL14};
  border-radius: 1vh;
  padding: 3vh 5vh 7vh;
  box-shadow: ${boxShadow};
`;

const QrCodeRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -7vh;
  margin-bottom: 1vh;
`;

const StyledTerminalQrCode = styled(TerminalQrCode)`
  height: 18vh;
  padding: 2vh;
  border-radius: 1vh;
  box-shadow: ${boxShadow};
`;

export const TerminalDemo = ({ pairingCode, secondVideo }) => {
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);

  return <>
    <Container onClick={() => setShowQrCodeModal(true)}>
      <JackpotContainer>
        <Jackpot grand>
          <JackpotBorder />
          <JackpotLabel>Grand</JackpotLabel>
          <JackpotValue>10,000</JackpotValue>
        </Jackpot>
        <Jackpot major>
          <JackpotBorder />
          <JackpotLabel>Major</JackpotLabel>
          <JackpotValue>6,000</JackpotValue>
        </Jackpot>
        <Jackpot minor>
          <JackpotBorder />
          <JackpotLabel>Minor</JackpotLabel>
          <JackpotValue>1,000</JackpotValue>
        </Jackpot>
      </JackpotContainer>
      {secondVideo ? <Video src="https://rad-casino.s3.us-east-1.amazonaws.com/terminalDemoVideos/powerOfZeus-2.webm" autoPlay loop muted />
        : <Video src="https://rad-casino.s3.us-east-1.amazonaws.com/terminalDemoVideos/asgardLegends-2.webm" autoPlay loop muted />}
      <FooterBanner>Play Free - Win Free</FooterBanner>
    </Container>
    {showQrCodeModal && <QrCodeModal closeModal={() => setShowQrCodeModal(false)} disableHeader>
      <QrCodeModalText>Scan To Play</QrCodeModalText>
      <QrCodeRow><StyledTerminalQrCode pairingCode={pairingCode} /></QrCodeRow>
    </QrCodeModal>}
  </>;
};
