import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { borderRadius, boxShadow, colors, transitionDuration } from '_constants';
import { useClickAway } from '_hooks';
import ChevronDownIcon from '_images/icons/chevronDown.svg';
import ChevronUpIcon from '_images/icons/chevronUp.svg';

const Container = styled.div`
  position: relative;
  display: flex;
`;

const Trigger = styled.div`
  background-color: ${colors.backgroundL20};
  user-select: none;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: ${borderRadius}px;
  font-size: 14px;
  box-shadow: ${boxShadow};
`;

const Menu = styled.div`
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  box-shadow: ${boxShadow};
  background-color: ${colors.backgroundL25};
  z-index: 1;
  border-radius: ${borderRadius}px;
  overflow: hidden;

  ${({ alignTop }) => alignTop ? `
    bottom: 100%;
  ` : `
    top: 100%;
  `}
`;

const Option = styled.div`
  padding: 10px 15px;
  cursor: pointer;
  color: ${colors.white};
  transition: background-color ${transitionDuration};
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: 14px;
  
  &:hover {
    background-color: ${colors.backgroundL30};
  }
`;

export const Dropdown = ({
  alignTop,
  className,
  onChange,
  options = [],
  selectedOption,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState({});
  const triggerRef = useRef();
  const menuRef = useRef();

  useEffect(() => {
    setSelectedDropdownOption(selectedOption);
  }, [selectedOption]);

  useClickAway({
    ref: menuRef,
    onClickAway: (event) => {
      if (event.target === triggerRef.current) return;
      setShowMenu(false);
    },
  });

  const onOptionChange = (option) => () => {
    setSelectedDropdownOption(option);
    setShowMenu(false);
    onChange(option);
  };

  return (
    <Container>
      <Trigger className={className} onClick={() => setShowMenu(!showMenu)} ref={triggerRef}>
        {selectedDropdownOption.selectedOptionRender ? selectedDropdownOption.selectedOptionRender() : selectedDropdownOption.label}
        {showMenu ? <ChevronUpIcon fill={colors.white} height={6} /> : <ChevronDownIcon fill={colors.white} height={6} />}
      </Trigger>
      {showMenu && <Menu alignTop={alignTop} ref={menuRef} options={options}>
        {options.map((option) => (
          <Option key={option.label} onClick={onOptionChange(option)}>
            {option.optionRender ? option.optionRender() : option.label}
          </Option>
        ))}
      </Menu>}
    </Container>
  );
};
