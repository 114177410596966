import React from 'react';
import styled from 'styled-components';

import { borderRadius, boxShadow, colors } from '_constants';

const StyledButton = styled.button`
  color: ${colors.white};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  padding: 10px 20px;
  border-radius: ${borderRadius}px;
  background-color: ${colors.primary};
  transition: background-color 0.3s;
  box-shadow: ${boxShadow};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-weight: 500;

  &:hover {
    background-color: ${colors.primaryL57};
  }

  ${({ secondary }) => secondary && `
    background-color: ${colors.backgroundL20};

    &:hover {
      background-color: ${colors.backgroundL30};
    }
  `}
`;

export const Button = ({
  children,
  className,
  disabled,
  onClick,
  secondary,
}) => {
  return (
    <StyledButton
      className={className}
      disabled={disabled}
      onClick={disabled ? () => {} : onClick}
      secondary={secondary}
    >
      {children}
    </StyledButton>
  );
};
