import React from 'react';

export const CookiePolicy = () => (
  <div>
    <p>Disclaimer Rad Software LLC operates and manages the online social sweepstakes casino 'LuckLake.com.' All legal, operational, and policy-related matters for this casino are overseen by Rad Software LLC, with 'LuckLake.com' being an approved DBA of Rad Software LLC.</p>
    <p>What are cookies? Cookies are a tool to help your browser remember your information on the site, making it easier to give you the content you want. At the core, they are just small bits of text and numbers that are downloaded to your device.</p>
    <p>What do we use the cookies for? Cookies are a vital part of the everyday functioning of a lot of online content. Cookies help your browser to remember information, and identify you as a user, so that we can keep you logged in. Cookies also help prevent fraud, for example by detecting fraudulent purchases from the same computer, and just to enhance your overall experience by showing you content that is suited to you.</p>
    <p>We do not, nor do companies we work with, sell personal data collected from cookies to other companies. When data is transferred to a third party, it is done in accordance with our Privacy Policy.</p>
    <p>Protection of Minors LuckLake.com is intended for users aged 18 and older, and we do not knowingly use cookies to collect data from or target minors under 18. Our cookie practices are designed to enhance the experience of eligible adult users only, in line with our Terms and Conditions and age verification processes.</p>
    <p>What cookies does LuckLake.com use? The following cookie categories depict the different cookies utilized in our website. Please note that the names of the various cookies, pixels and different technologies used may change over time.</p>
    <p>The 2 sorts of cookies that we use: Strictly necessary cookies: Anonymous cookies that permit guests to visit and explore around our site, utilize its features and functions. Preferences and data collected is used for security, fraud and client support purposes. Due to security and regulatory requirements related to online payments some performance and functional cookies are included in this category. The information collected will not be used for advertising and targeting purposes.</p>
    <p>Advertising cookies: Anonymous cookies that assist us with improving our site by gathering data about how visitors use and behave in our site. They help us remember our visitors and understand which content is most relevant to them. By storing and sharing this information with advertisers and other organisations we try to provide relevant advertising and the best experience based on visitor preferences.</p>
    <p>What are the choices regarding cookies? You can change your settings any time by visiting the Cookie Settings page, as all modern browsers allow users to manage their cookies and browsing history. A user can access the browser settings in desktop, mobile or tablet and decide to clear cookies and even browser history. Based on the user's decisions, browsing history, website cookies and preferences can be cleared and deleted. You can read more about the above mentioned choices and settings for the most used and popular browsers in the links below: Internet Explorer Firefox Google Chrome Safari</p>
    <p>Even more information about cookies and settings you can visit: www.allaboutcookies.org</p>
    <p>Changes to this Cookie Policy We may update this Cookie Policy from time to time to reflect changes in our practices, technology, or legal requirements. Any updates will be posted on the LuckLake.com website at www.LuckLake.com, and, where required by law, we will notify you via email or a prominent notice on our platform before the changes take effect. We encourage you to review this policy periodically to stay informed about how we use cookies.</p>
    <p>Contact Us For any more questions related to this policy, please email us at support@LuckLake.com.</p>
  </div>
);
